.flex {
  display: flex;
}

.xs-flex {
  @media only screen and (min-width: 375px) {
    display: flex;
  }
}

.flex-ai-c {
  align-items: center;
}

.lg-flex {
  @media only screen and (min-width: 1024px) {
    display: flex;
  }
}

.lg-flex-ai-c {
  @media only screen and (min-width: 1024px) {
    align-items: center;
  }
}

.ta-c {
  text-align: center;
}

.lg-m-l-10 {
  @media only screen and (min-width: 1024px) {
    margin-left: 10px !important;
  }
}

.xsDesktop-m-l-40 {
  @media only screen and (min-width: 1280px) {
    margin-left: 40px !important;
  }
}

.circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #32B3F0;

  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: #FFFFFF;

  transition: 0.25s background-color;
  will-change: background-color;

  .link-blue:hover &,
  &:hover {
    background-color: darken(#32B3F0, 10%);
  }
}

.vertical-divider {
  height: 40px;
  width: 1px;
  margin: 0 20px;
  background-color: #E8E7E7;
}

.m-r-15 {
  margin-right: 15px;
}

.xs-mobile-m-b-10 {
  @media only screen and (max-width: 374px) {
    margin-bottom: 10px;
  }
}

.content-p-01 {
  font-weight: 400;
  font-size: 14px;
  color: #707070;
  line-height: 1.875;

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
  }

  p {
    margin: 0 0 10px;
  }
}

.text-md-right {
  @media only screen and (min-width: 768px) {
    text-align: right;
  }
}

.lg-pull-right {
  @media only screen and (min-width: 1200px) {
    float: right;
  }
}