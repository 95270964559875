@charset "UTF-8";

@font-face {
  font-family: "treneoicons";
  src:url("../../fonts/treneoicons/treneoicons.eot");
  src:url("../../fonts/treneoicons/treneoicons.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/treneoicons/treneoicons.woff") format("woff"),
    url("../../fonts/treneoicons/treneoicons.ttf") format("truetype"),
    url("../../fonts/treneoicons/treneoicons.svg#treneoicons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "treneoicons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "treneoicons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon {
  vertical-align: middle;
  display: inline-block;
}

.icon-arrowleft:before {
  content: "\66";
}
.icon-arrowright:before {
  content: "\67";
}
.icon-articles:before {
  content: "\69";
}
.icon-back:before {
  content: "\6a";
}
.icon-basket:before {
  content: "\6b";
}
.icon-book:before {
  content: "\6c";
}
.icon-calendar:before {
  content: "\6d";
}
.icon-checked:before {
  content: "\6e";
}
.icon-library:before {
  content: "\6f";
}
.icon-finances:before {
  content: "\70";
}
.icon-envelope:before {
  content: "\73";
}
.icon-enter:before {
  content: "\74";
}
.icon-edit:before {
  content: "\75";
}
.icon-dropdown:before {
  content: "\76";
}
.icon-diary:before {
  content: "\77";
}
.icon-delete:before {
  content: "\78";
}
.icon-dashboard:before {
  content: "\79";
}
.icon-cursor:before {
  content: "\7a";
}
.icon-close:before {
  content: "\41";
}
.icon-link:before {
  content: "\42";
}
.icon-location:before {
  content: "\43";
}
.icon-logout:before {
  content: "\44";
}
.icon-measurement:before {
  content: "\45";
}
.icon-messenger:before {
  content: "\46";
}
.icon-more:before {
  content: "\47";
}
.icon-myacademy:before {
  content: "\48";
}
.icon-phone:before {
  content: "\49";
}
.icon-play:before {
  content: "\4a";
}
.icon-print:before {
  content: "\4b";
}
.icon-search:before {
  content: "\4c";
}
.icon-settings:before {
  content: "\4d";
}
.icon-star:before {
  content: "\4e";
}
.icon-videos:before {
  content: "\4f";
}
.icon-video:before {
  content: "\50";
}
.icon-trainings:before {
  content: "\51";
}
.icon-trainers:before {
  content: "\52";
}
.icon-timer2:before {
  content: "\53";
}
.icon-timer:before {
  content: "\54";
}
.icon-statistics:before {
  content: "\55";
}
.icon-user:before {
  content: "\56";
}
.icon-warning:before {
  content: "\61";
}
.icon-arrowdown:before {
  content: "\65";
}
.icon-arrowup:before {
  content: "\68";
}
.icon-add:before {
  content: "\62";
}
.icon-activity:before {
  content: "\63";
}
.icon-academy:before {
  content: "\64";
}
.icon-error:before {
  content: "\72";
}
.icon-file:before {
  content: "\71";
}
.icon-eye:before {
  content: "\57";
}
.icon-bulb:before {
  content: "\58";
}
.icon-image:before {
  content: "\59";
}
.icon-copy:before {
  content: "\5a";
}
.icon-placeholder:before {
  content: "\30";
}
.icon-blocks:before {
  content: "\31";
}
.icon-percentage:before {
  content: "\32";
}
.icon-swimming:before {
  content: "\33";
}
.icon-hockey:before {
  content: "\34";
}
.icon-running:before {
  content: "\35";
}
.icon-aerobic:before {
  content: "\36";
}
.icon-american-football:before {
  content: "\37";
}
.icon-athletics:before {
  content: "\38";
}
.icon-badminton:before {
  content: "\39";
}
.icon-baseball:before {
  content: "\21";
}
.icon-basketball:before {
  content: "\22";
}
.icon-biathlon:before {
  content: "\23";
}
.icon-boxing:before {
  content: "\24";
}
.icon-canoeing:before {
  content: "\25";
}
.icon-circuit-training:before {
  content: "\26";
}
.icon-climbing:before {
  content: "\27";
}
.icon-core:before {
  content: "\28";
}
.icon-crossfit:before {
  content: "\29";
}
.icon-curling:before {
  content: "\2a";
}
.icon-cycling:before {
  content: "\2b";
}
.icon-dancing:before {
  content: "\2c";
}
.icon-downhill-skiing:before {
  content: "\2d";
}
.icon-elliptical-trainer:before {
  content: "\2e";
}
.icon-floorball:before {
  content: "\2f";
}
.icon-football:before {
  content: "\3a";
}
.icon-frisbee:before {
  content: "\3b";
}
.icon-functional-training:before {
  content: "\3c";
}
.icon-golf:before {
  content: "\3d";
}
.icon-gymnastics:before {
  content: "\3e";
}
.icon-handball:before {
  content: "\3f";
}
.icon-horse-riding:before {
  content: "\40";
}
.icon-inline-skating:before {
  content: "\5b";
}
.icon-kitesurfing:before {
  content: "\5d";
}
.icon-lacrosse:before {
  content: "\5e";
}
.icon-martial-arts:before {
  content: "\5f";
}
.icon-mtb:before {
  content: "\60";
}
.icon-nordic-walking:before {
  content: "\7b";
}
.icon-other-activity:before {
  content: "\7c";
}
.icon-paddleboarding:before {
  content: "\7d";
}
.icon-physiotherapy:before {
  content: "\7e";
}
.icon-pilates:before {
  content: "\5c";
}
.icon-ping-pong:before {
  content: "\e000";
}
.icon-rehabilitation:before {
  content: "\e001";
}
.icon-rowing:before {
  content: "\e002";
}
.icon-rugby:before {
  content: "\e003";
}
.icon-table-tennis:before {
  content: "\e00d";
}
.icon-team-training:before {
  content: "\e00e";
}
.icon-tennis:before {
  content: "\e00f";
}
.icon-thai-box:before {
  content: "\e010";
}
.icon-trampoline:before {
  content: "\e011";
}
.icon-question-mark:before {
  content: "\e085"
}
.icon-volleyball:before {
  content: "\e012";
}
.icon-wakeboarding:before {
  content: "\e013";
}
.icon-walking:before {
  content: "\e014";
}
.icon-windsurfing:before {
  content: "\e015";
}
.icon-working-out:before {
  content: "\e016";
}
.icon-yoga:before {
  content: "\e017";
}
.icon-zumba:before {
  content: "\e018";
}
