.article-trainer {
  margin-bottom: -70px;
}
.article-trainer__avatar {
  width: 58px;
  height: 58px;
  margin: 0 auto 10px;
  border-radius: 50%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.article-trainer__name {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #404040;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  small {
    display: block;
    font-size: 13px;
    color: #909090;

    @media only screen and (min-width: 768px) {
      font-size: 14px;
    }
  }
}