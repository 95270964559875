/*********************
  Component Login form
 ********************/

/* default login form (dropdown) */
.c-login-form {

  //form title
  .form-title {
    display: none;
    font-size: 26px;
  }

  //fields
  .form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    margin-bottom: 15px;
    height: 38px;
    border-radius: 3px;
    border: 1px solid #dbe1e5;
    box-shadow: 0 1px 2px rgba(155, 155, 155, 0.1);
  }

  //social login
  .social-login {
    border-top: 1px solid #535456;
    margin-top: 23px;
    padding-top: 16px;
  }

  .social-login .item {
    color: #fff;
    padding: 6px 0 6px 30px;
    position: relative;
    font-size: 13px;
    line-height: 1;
    width: auto;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;

    &:first-of-type {
      margin-right: 25px;
    }

    //icon
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 25px;
      height: 25px;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: 750px 750px;
      background-image: url('../../images/sprite.png');
    }

    //google plus icon
    &.gplus:before {
      background-position: -109px 0;
    }

    //facebook icon
    &.fb:before {
      background-position: -136px 0;
    }

  }
}

/* login form modal */
.modal-body .c-login-form {

  //form title
  .form-title {
    display: block;
    font-size: 26px;
  }

  //lost password button
  a.lost-password {
    padding-left: 0;
    color: #000;
  }

  //social login
  .social-login {
    border-top: none;
    text-align: center;
  }

  .social-login .item {
    color: #000;

    //google plus icon
    &.gplus:before {
      background-position: -109px -26px;
    }

    //facebook icon
    &.fb:before {
      background-position: -136px -26px;
    }
  }

  //horizontal line
  .separator {
    width: 100%;
    height: 1px;
    background-color: #d3dade;
    margin: 25px 0;
    text-align: center;

    span {
      display: inline-block;
      vertical-align: top;
      background-color: #f0f3f6;
      margin-top: -9px;
      font-size: 13px;
      color: #bec1c3;
      text-transform: uppercase;
      padding: 0 9px;
    }
  }

}