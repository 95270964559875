.footer-nav {
  margin: 0 0 20px;

  @media only screen and (min-width: 1024px) {
    margin: 0;
  }
}

.footer-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  @media only screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
}

.footer-nav__item {
  display: block;
  margin: 0 0 10px;
  padding: 0;

  @media only screen and (min-width: 1024px) {
    margin: 0 5px;
  }

  @media only screen and (min-width: 1280px) {
    position: relative;
    margin: 0 12px;
  }

  &::before {
    display: none;
  }

  &:not(:last-of-type)::after {
    @media only screen and (min-width: 1280px) {
      content: '';
      position: absolute;
      top: 1px;
      right: -12px;
      width: 1px;
      height: 23px;
      background-color: #E8E7E7;
    }
  }
}

.footer-nav__link {
  font-weight: 700;
  font-size: 13px;
  color: #1D1D1D;

  &:hover {
    color: #55B948;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 14px;
  }
}

