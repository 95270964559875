.link-blue {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  color: #32B3F0;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  &:hover {
    color: darken(#32B3F0, 10%);
  }
}

.link-blue-icon {
  display: inline-flex;
  align-items: center;

  .circle-icon {
    margin-left: 10px;
  }
}

.link-blue-title {
  text-decoration: underline;

  .link-blue:hover &,
  &:hover {
    text-decoration: none;
  }
}

.btn-solid-green {
  display: inline-block;
  padding: 0 15px;
  min-width: 108px;
  border: 1px solid #4AC102;
  border-radius: 28.5px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  background-color: transparent;

  font-weight: 700;
  font-size: 14px;
  color: #4AC102;

  transition: 0.25s background-color, 0.25s color;
  will-change: background-color, color;

  @media only screen and (min-width: 768px) {
    padding: 0 24px;
    line-height: 48px;
  }

  &:hover,
  &:focus {
    background-color: #4AC102;
    color: #fff;
  }

  .blue-layout & {
    border-color: @blue;
    color: @blue;

    &:hover {
      color: #fff;
      background-color: @blue;
    }
  }
}

.btn-solid-yellow,
.btn-solid-danger {
  display: inline-block;
  padding: 0 15px;
  min-width: 108px;
  border-radius: 28.5px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  font-weight: 700;
  font-size: 14px;
}

.btn-solid-yellow {
  border: 1px solid @color-10;
  background-color: @color-10;
  color: #ffffff;

  &:hover,
  &:focus {
    background-color: #ffffff;
    color: @color-10 !important;
  }
}

.btn-solid-danger {
  border: 1px solid @color-11;
  background-color: @color-11;
  color: #ffffff;

  &:hover,
  &:focus {
    background-color: #ffffff;
    color: @color-11 !important;
  }
}

.btn-filled {
  display: inline-block;
  padding: 0 15px;
  min-width: 108px;
  border-radius: 28.5px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  background-color: @green;
  border: 0;

  transition: 0.25s background-color;
  will-change: background-color;

  @media only screen and (min-width: 768px) {
    padding: 0 24px;
    line-height: 48px;
  }

  &:hover,
  &:focus {
    color: #fff;
    background-color: darken(#4AC102, 10%);
  }

  .blue-layout & {
    background-color: @blue;
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      background-color: darken(@blue, 10%);
    }
  }
}

.btn-filled-dark {
  color: #fff;
  background-color: darken(#4AC102, 15%);

  &:hover,
  &:focus {
    color: #fff;
    background-color: darken(#4AC102, 25%);
  }

  .blue-layout & {
    background-color: darken(@blue, 20%);

    &:focus,
    &:hover {
      background-color: darken(@blue, 25%);
    }
  }
}

.btn-filled-right-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 1px;
  padding-right: 6px !important;

  img {
    margin-left: 10px;
  }
}

.btn-filled-sm {
  padding: 0 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
}

.btn-custom-group {
  margin: -10px -10px 10px;
  > * {
    margin: 10px;
  }

  &--negative {
    @media only screen and (min-width: 1280px) {
      margin-top: -40px;
    }
  }
}

.fb-share {
  display: inline-flex;
  align-items: center;
  height: 50px;
  padding: 0 20px 0 28px;
  border-radius: 29px;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  color: #fff !important;
  background-color: #4C67AD;
  transition: 0.25s background-color;
  will-change: background-color;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  &:hover {
    color: #fff;
    background-color: darken(#4C67AD, 10%);
  }

  img {
    margin-right: 8px;
  }
}
.btn-simple {
  display: inline-block;
  padding: 0 15px;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  color: #1D1D1D;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
    padding: 0 24px;
    line-height: 48px;
  }
}