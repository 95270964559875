.pagination-container {
  margin: 10px 0 30px;
}

.pagination-container-lg {
  margin: 20px 0 40px;

  @media only screen and (min-width: 768px) {
    margin: 50px 0 70px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -5px;

  &--jc-end {
    @media only screen and (min-width: 768px) {
      justify-content: flex-end;
    }
  }
}

.pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin: 5px;
  text-decoration: none;
  border-radius: 50%;
  background-color: #fff;

  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #1D1D1D;

  img {
    width: 11px;
  }

  &--nav:not(.pagination-item--disabled) {
    transition: 0.25s opacity;
    will-change: opacity;

    &:hover {
      opacity: .7;
    }
  }

  &--prev {
    transform: rotate(90deg);
  }

  &--next {
    transform: rotate(-90deg);
  }

  &--disabled {
    pointer-events: none;
    background-color: #E8E7E7;
  }

  &--active {
    color: #fff;
    background-color: @green;

    &:hover {
      color: #fff;
      background-color: darken(@green, 10%);
    }
  }

  .blue-layout &--active {
    color: #fff;
    background-color: #31b3ef;

    &:hover {
      color: #fff;
      background-color: darken(#31b3ef, 5%);
    }
  }
}