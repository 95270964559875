@media only screen and (max-width: 1500px) {
  .activities-overview {
    .panel-body {
      .nav-tabs {
        >li {
          >a {
            padding: 0 5px 12px 5px;
            margin: 0 4px;
          }
        }
      }
    }
    .panel-footer {
      .statistics {
        .item {
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1450px) {
  .trainer-thumbs {
    .trainer-thumb {
      width: 14.2857%;
      &:nth-of-type(7n+7) {
        border-right: 0;
      }
      &:nth-of-type(8n+8) {
        border-right: 1px solid #e4e6e7;
      }
    }
    &.four {
      .trainer-thumb {
        &:nth-of-type(7n+7) {
          border-right: 1px solid #e4e6e7;
        }
      }
    }
  }
  .panel-library {
    .image-wrapper {
      //max-height: 90px;
    }
  }
  #map {
    padding-bottom: 65%;
  }
  .activities-overview {
    .panel-body {
      .bars {
        .bar-container {
          width: 40px;
          height: 150px;
          span {
            font-size: 13px;
          }
        }
      }
    }
  }
  .chart {
    .cols {
      .col {
        span {
          width: 200%;
          left: -50%;
        }
      }
    }
  }
  .calendar-statistics {
    .box {
      padding-left: 15px;
      .bars {
        margin-left: 20px;
      }
      .user-info {
        margin-left: 10px;
        padding-left: 15px;
        max-width: 100px;
      }
    }
  }
}
@media screen and (min-width: 1366px) {
  .col-bg-4 {
    width: 33.33%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
  }
  .col-bg-3 {
    width: 25%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
  }
}
@media screen and (min-width: 1600px) {
  .col-hg-3 {
    width: 25%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
  }
}
@media screen and (min-width: 1281px) {
  .video {
    width: 830px !important;
  }
  .navbar-default {
    .navbar-toggle {
      display: none;
    }
  }
}
@media only screen and (max-width: 1280px) {
  .row-offcanvas {
    position: relative;
    min-height: 100%;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
  }

  .row-offcanvas-right {
    right: 0;
  }

  .row-offcanvas-left {
    left: 0;
  }

  .row-offcanvas-left
  .sidebar-offcanvas {
    left: -200px; /* 6 columns */
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
  }

  .row-offcanvas-left.active {
    left: 200px; /* 6 columns */
    .sidebar-offcanvas {
      z-index: 10;
      left: 0px;
      -webkit-transition: all .25s ease-out;
      -o-transition: all .25s ease-out;
      transition: all .25s ease-out;
    }
  }

  .sidebar-offcanvas {
    position: fixed;
    top: 65px;
    width: 200px; /* 6 columns */
  }
  .col-offset-200{
    padding-left: 0;
  }
}
@media only screen and (max-width: 1366px) {
  .trainer-thumbs {
    .trainer-thumb {
      width: 16.66%;
      &:nth-of-type(6n+6) {
        border-right: 0;
      }
      &:nth-of-type(5n+5) {
        border-right: 1px solid #e4e6e7;
      }
      &:nth-of-type(7n+7) {
        border-right: 1px solid #e4e6e7;
      }
    }
    &.four {
      .trainer-thumb {
        &:nth-of-type(6n+6) {
          border-right: 1px solid #e4e6e7;
        }
      }
    }
  }
  .btn-chart {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 1230px) {
  .trainer-thumbs {
    .trainer-thumb {
      width: 20%;
      &:nth-of-type(5n+5) {
        border-right: 0;
      }
      &:nth-of-type(6n+6) {
        border-right: 1px solid #e4e6e7;
      }
    }
    &.four {
      .trainer-thumb {
        &:nth-of-type(5n+5) {
          border-right: 1px solid #e4e6e7;
        }
      }
    }
  }
  .trainer-profile {
    .trainer-avatar {
      width: 180px;
      height: 180px;
      .image-wrapper {
        width: 180px;
        height: 180px;
      }
    }
  }
  .trainer-programme {
    .flex-container {
      .item {
        &:first-child {
          width: 45%;
        }
        &:last-child {
          width: 55%;
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .calendar-statistics {
    .box {
      text-align: center;
      &.user {
        text-align: left;
        margin-bottom: 10px;
      }
    }
  }
  .trainings {
    .alert {
      padding: 18px 30px 18px 18px;
    }
    .alert-dismissible {
      .close {
        top: 5px;
        right: 10px;
        margin-top: 0
      }
    }
  }
  .trainer-landing {
    .trainer-programme {
      .programme-tariffs {
        .tariff {
          min-height: 326px !important;
          .price {
            font-size: 36px;
          }
          .saving {
            font-size: 12px;
          }
        }
      }
      .flex-container {
        display: block;
        font-size: 0;
        .item {
          .inline-block;
        }
      }
      .programme-info {
        font-size: 16px;
      }
      &.v3 {
        .programme-tariffs {
          .tariff {
            min-height: 350px !important;
          }
          &.plus-tariff {
            .tariff {
              min-height: 90px !important;
            }
          }
        }
      }
    }
  }
  .navbar-default {
    .navbar-toggle {
      &:hover, &:focus {
        background-color: transparent !important;
      }
      &:focus {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
  .show-more-container {
    &.pad-filter {
      .show-more-text {
        .inline-block;
        margin-top: 8px;
        &.filter-standalone {
          display: block;
        }
        .icon-arrowdown::before {
          top: 2px;
        }
        .icon-arrowdown.opened::before {
          top: -5px;
        }
      }
      .hidden-actions {
        display: none;
        float: none !important;
        margin-top: 20px;
        text-align: left;
        .fstToggleBtn {
          min-width: 155px;
        }
        .search-input {
          width: 230px;
          .form-control {
            width: 190px;
          }
        }
      }
    }
  }
  .panel-library {
    .image-wrapper {
      max-height: 200px;
    }
  }
  .sidebar-offcanvas {
    z-index: 0;
  }
  .chart-controls {
    .chart-view-text {
      padding: 8px 5px 0 5px;
    }
  }
  .create-training {
    .training-type-select {
      .item {
        input[type="radio"]{
          &+label {
            width: 150px;
          }
        }
        &.individual {
          .fstElement {
            width: 130px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .show-more-container.pad-filter .show-more-text .icon-arrowdown::before {
    top: 0;
  }
}
@media only screen and (max-width: 991px) {
  .trainer-thumbs {
    .trainer-thumb {
      width: 25%;
      &:nth-of-type(4n+4) {
        border-right: 0;
      }
      &:nth-of-type(5n+5) {
        border-right: 1px solid #e4e6e7;
      }
    }
  }
  .panel-library {
    .video {
      width: 100%;
    }
  }
  .panel-chart {
    .panel-title {
      margin-bottom: 20px;
    }
  }
  .chart-change-view, .chart-change-view2 {
    text-align: center;
    .btn-toolbar {
      float: none !important;
      .inline-block;
    }
  }
  .chart-change-view2 {
    margin-bottom: 25px;
  }
  .chart-controls {
    margin-bottom: 25px;
  }
  .video-full {
    &.exercise-video {
      .embed-responsive {
        //margin-bottom: 20px;
      }
    }
  }
  .exercise-image {
    &:last-child {
      margin-bottom: 20px;
    }
  }
  .create-training {
    .training-type-select {
      font-size: 0;
      margin: 0 -5px;
      .item {
        font-size: 14px;
        width: 33.33%;
        padding: 0 5px;
        input[type="radio"]{
          &+label {
            width: 100%;
          }
        }
        &.individual {
          .fstElement {
            width: ~"-moz-calc(100% - 27px)";
            width: ~"-webkit-calc(100% - 27px)";
            width: ~"-o-calc(100% - 27px)";
            width: ~"calc(100% - 27px)";
            top: 40px;
          }
        }
        &+.item {
          margin-left: 0;
        }
      }
    }
  }
  .if-empty-calendar, .if-empty-e-learning {
    width: 100%;
    padding: 200px 20px 20px 20px;
    margin-top: 0;
    &::before {
      top: 30px;
    }
  }

  .bottom-line {
    padding-bottom: 48px;
  }

}
@media only screen and (max-width: 830px) {
  .trainer-programme {
    .flex-container {
      .item {
        width: 100%;
        &:first-child {
          width: 100%;
        }
        &:last-child {
          width: 100%;
        }
      }
    }
  }
  .trainer-landing {
    .name {
      h1 {
        margin: 8px 0 0 0;
      }
    }
    .image-wrapper {
      // margin-left: 27px;
    }
    .trainer-programme {
      margin-top: 30px;
      .programme-tariffs {
        .tariff {
          min-height: 270px !important;
          padding: 0 !important;
          .months {
            padding-top: 1px;
            position: relative;
            top: auto;
            margin-top: -140px;
            width: 100%;
            // width: ~"-moz-calc(100% + 30px)";
            // width: ~"-webkit-calc(100% + 30px)";
            // width: ~"-o-calc(100% + 30px)";
            // width: ~"calc(100% + 30px)";
          }
          .info {
            padding: 0 10px;
          }
        }
      }
      .plus {
        .programme-info {
          margin-bottom: 0;
        }
      }
      .programme-info {
        margin-bottom: 140px;
      }
      &.v3 {
        .programme-tariffs {
          .tariff {
            .months {
              margin-top: 0;
            }
          }
        }
        .programme-info {
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media (min-width: 1250px) {
  .modal-big {
    width: 1200px !important;
  }
}
/*
@media (min-width: 1200px) {
  .trainer-landing {
    .container {
      width: 1000px;

    }
  }
}
@media (min-width: 1410px) {
  .trainer-landing {
    .container {
      width: 1170px;
    }
  }
}
 */
@media only screen and (max-width: 808px) {
  .map-trainer-info {
    width: 90%;
    margin: 10px;
    .box-shadow(0,0,0,transparent);
    .image {
      display: none;
    }
    .info {
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      p {
        display: none;
      }
      h2 {
        margin: 5px 0;
      }
      span {
        margin-bottom: 10px !important;
      }
    }
    .panel-footer {
      display: none;
    }
    .panel-body {
      padding: 0;
    }
  }
}
@media screen and (min-width: 768px) {
  .navbar-toggle {
    display: block;
  }
  .modal-big {
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .user-statistics.choose-activity {
    height: 230px;
    margin-bottom: 20px;
    .choose-activity-circle {
      width: 80px;
      height: 80px;
    }
    .choose-activity-icon {
      top: 17px;
      font-size: 35px;
    }
  }
  .g-flex-container {
    display: block;
    flex-wrap: inherit;
  }
  .modal-upload-exercise {
    .modal-body {
      .scroll-box {
        &.scroll-box-panels {
          height: 320px;
        }
      }
      .package-detail {
        height: auto;
        .if-empty {
          margin-top: 0;
        }
      }
    }
  }
  .calendar-statistics {
    .box {
      height: auto;
      padding-left: 10px;
      padding-right: 10px;
      .main-text {
        display: block;
        margin-bottom: 10px;
        margin-top: 0;
      }
      .image-wrapper {
        width: 70px;
        height: 70px;
        top: -15px;
        left: 50%;
        margin-left: -35px;
      }
      .user-info {
        display: block;
        border-left: 0;
        padding-left: 10px;
        margin-left: 0;
        max-width: unset;
      }
      &.user {
        text-align: center;
        padding-left: 0;
        margin-left: 0;
        padding-top: 70px;
      }
      .bars {
        margin-left: 0;
      }
      &.user {
        .main-text {
          max-width: unset;
        }
      }
    }
  }
  .videos-upload {
    .item {
      .name, .url, .time {
        width: 100%;
      }
      .name, .url {
        .form-control {
          margin-bottom: 10px;
          width: 100%;
        }
      }
      &.head {
        display: none;
      }
    }
  }
  .format-select-container {
    .btn-switch {
      margin-bottom: 10px;
      &.pdf, &.two-images {
        margin-bottom: 0;
      }
    }
  }
  .paginator {
    &.xs-no-padding {
      .btn {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .xs-center-btn-group {
    text-align: center;
    .btn-group {
      float: none !important;
      display: inline-block;
      margin-top: 20px;
    }
  }
  .modal-delete {
    .modal-body {
      .bin-icon {
        display: none;
      }
      p {
        width: 100%;
        margin: 0 0 30px 0;
        text-align: center;
      }
    }
  }
  .modal-choose-activity {
    .btn {
      margin-bottom: 10px;
    }
    .modal-body {
      padding-bottom: 15px;
    }
  }
  .margin-0-xs {
    margin: 0 !important;
  }
  .create-training {
    .training-type-select {
      .item {
        width: 100%;
      }
    }
    .section {
      .exercise-input {
        margin-bottom: 15px;
      }
      .choose-from-library {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
  body {
    font-size: 16px;
  }
  .btn {
    padding-top: 12px;
    padding-bottom: 12px;
    &.icon {
      padding-top: 11px;
      padding-bottom: 11px;
    }
    &.btn-blocks {
      padding-bottom: 10px;
    }
  }
  .navbar-nav {
    margin: 0;
    float: right;
    .open {
      .dropdown-menu {
        position: absolute;
      }
    }
  }
  .nav > li {
    display: inline-block;
    margin-top: -1px;
  }
  .nav-tabs {
    > li {
      width: 100%;
      margin-bottom: 5px;
      > a {
        .border-radius(4px);
      }
      &+li {
        margin-left: 0;
      }
    }
  }
  .navbar-header {
    float: left;
  }
  .navbar-default {
    .navbar-nav {
      .dropdown {
        > a {
          width: 110px;
        }
        > .dropdown-menu {
          position: fixed;
          left: 0px;
          top: 65px;
          width: 100%;
          > li {
            > a {
              padding: 16px 0;
            }
          }
        }
      }
    }
    .navbar-right-flex {
      display: flex;
      .menu-notifications {
        > a {
          text-align: center;
          padding-left: 5px !important;
          padding-right: 5px !important;

          @media (max-width: 374px) {
            width: 45px;
          }
          @media (min-width: 375px) {
            width: 60px;
          }
        }
        > .dropdown-menu {

        }
      }
      .dropdown:not(.menu-notifications) {
        > a {
          @media (max-width: 374px) {
            width: 80px;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }
  .set-price {
    .fstToggleBtn {
      min-width: 49px;
    }
  }
  .activities-overview {
    .panel-body {
      .nav-tabs {
        padding: 0 10px;
        display: flex;
        >li {
          display: inline-block;
          width: auto;
          flex-grow: 1;
          >a {
            padding: 0 0 12px 0;
            margin: 0;
          }
        }
      }
    }
  }
  .sidebar-offcanvas {
    z-index: 0;
    width: 100%;
    .list-group {
      .list-group-item {
        font-size: 15px;
        padding: 16px 15px 14px 15px;
        .icon {
          font-size: 18px;
        }
      }
    }
    .add-links {
      margin-bottom: 50px;
    }
  }
  .row-offcanvas-left {
    .sidebar-offcanvas {
      left: -100%;
    }
  }
  .row-offcanvas-left.active {
    left: 100%; /* 6 columns */
  }
  .single-line {
    text-align: left !important;
    .search-input {
      width: 100%;
      margin-bottom: 15px;
      .form-control {
        width: 100%;
      }
    }
    .fstElement {
      width: 100% !important;
      margin: 0 0 10px 0;
    }
  }
  .text-left-xs {
    text-align: left;
  }
  .pull-left-xs {
    float: left !important;
  }
  .main-headline {
    padding: 0;
    h1 {
      margin: 10px 0;
    }
    .edit-input {
      margin: 15px 0;
      width: 100%;
    }
    .headline-icon {
      display: none;
    }
  }
  .article-detail {
    width: 100%;
    img {
      position: relative;
      z-index: 1;
      max-width: 100%;
      height: auto !important;
    }
    iframe {
      max-width: 100%;
    }
  }
  .article-date {
    margin-bottom: -75px;
    position: relative;
    z-index: 2;
  }
  .full-width-xs {
    width: 100%;
  }
  .margin-only-bottom-xs {
    margin: 0 0 15px 0 !important;
  }
  .trainer-list-item {
    .panel-footer {
      padding: 10px;
      .action-buttons {
        .right, .left {
          width: 100%;
        }
        .right {
          &::before {
            display: none;
          }
        }
      }
    }
    .info {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      .btn-toolbar {
        margin-left: 0;
      }
      p {
        font-size: 15px;
      }
    }
    .image {
      display: block;
      margin: auto;
      margin-bottom: 15px;
    }
  }
  .panel {
    &.finances {
      .panel-heading {
        .item {
          border-right: 0;
          margin-right: 0;
          display: block;
          margin-left: 48px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  .create-training {
    .section {
      .exercise {
        .inputs {
          width: ~"-moz-calc(100% - 55px)";
          width: ~"-webkit-calc(100% - 55px)";
          width: ~"-o-calc(100% - 55px)";
          width: ~"calc(100% - 55px)";
        }
      }
      .link {
        margin: 8px 0 14px 8px;
      }
      .btn-toolbar {
        margin: 25px 0 0 0;
      }
      .rest {
        label {
          margin: 12px 5px 0 5px;
        }
      }
    }
    .rest {
      clear: both;
      .increase-input {
        .form-control {
          width: 38px !important;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
.account {
  .section {
    &.image {
      h4 {
        margin-top: 0;
      }
      .image-wrapper {
        margin: auto;
      }
    }
  }
}
.center-60 {
  width: 100%;
}
.flash-messages {
  width: 280px;
}
.helper-box {
  padding: 55px 15px 45px 15px;
  p {
    width: 100%;
  }
}
.show-more-container {
  .show-more-text {
    .inline-block;
    &.filter-standalone {
      display: block;
      margin-top: 4px !important;
      margin-bottom: 3px;
    }
  }
  .hidden-actions {
    display: none;
  }
  &.pad-filter {
    .show-more-text {
      margin-top: 16px;
    }
    .hidden-actions {
      .search-input {
        width: 100%;
        .form-control {
          width: 100%;
        }
      }
    }
  }
}
.group-xs-3 {
  &.btn-group {
    .box-shadow(0,0,0,transparent);
    width: 100%;
    .btn {
      width: 33%;
    }
  }
}
  .group-xs-2 {
    &.btn-group {
      .box-shadow(0,0,0,transparent);
      width: 100%;
      .btn {
        width: 50%;
      }
    }
  }
  .group-xs-4 {
    &.btn-group {
      .box-shadow(0,0,0,transparent);
      width: 100%;
      .btn {
        width: 25%;
      }
    }
  }
  .group-xs-auto {
    &.btn-group {
      .box-shadow(0,0,0,transparent);
      width: 100%;
      .btn {
        width: auto;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  .header-text {
    padding-top: 0;
  }
  .header-text-container {
    float: none;
    margin-bottom: 15px;
  }
  #statistics-content {
    padding-top: 0;
  }
  .chart {
    .cols {
      .col {
        span {
          font-size: 12px;
        }
      }
    }
    &.year {
      .rows {
        .chrow {
          span {
            font-size: 12px;
            left: 0;
          }
        }
      }
    }
  }
  .trainings {
    .diary {
      .list-group-item {
        &:hover {
          .btn-toolbar {
            display: none !important;
          }
        }
        .btn-toolbar {
          display: none !important;
        }
      }
    }
  }

  #calendar {
    margin-bottom: 0;
  }
  .fc-scroller {
    height: auto !important;
  }

  .fc-list-view {

  }

  .fc-day-header {
    font-size: 9px;
  }
  .fc-basic-view .fc-body .fc-row {
    min-height: 40px;
  }
  .fc-day-number {
    width: 100%;
    height: 15px;
    font-size: 10px;
    line-height: 18px;
    margin: auto;
    display: block;
    border-radius: 0;
  }
  .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
    float: none;
  }
  .fc-today .fc-day-number {
    background-color: transparent;
    position: relative;
    color: @primary;
    &::before {
      .pseudo;
      left: 0;
      top: 0;
      width: 100%;
      height: 40px;
      background-color: lighten(@green, 50%);
      z-index: -1;
    }
  }
  .fc-event {
    font-size: 0;
    width: 6px;
    height: 6px !important;
    padding: 0 !important;
    &::before {
      display: none;
    }
    &.type-diary {
      width: 6px;
      height: 6px !important;
      top: 9px;
    }
  }
  .fc-event-container {
    padding: 0 !important;
    font-size: 0;
    pointer-events: none;
  }
  .calendar-legend {
    display: none;
  }
  .fc-day.focus {
    background-color: #f4f4f4;
  }

  .calendar-switch {
    display: block;
    height: 46px;
    margin-bottom: 25px;
  }



  .exercise-image {
    + .exercise-image {
      margin-top: 12px;
    }
  }
  .alert {
    padding: 15px;
    &.alert-dismissible {
      .close {
        top: -11px;
        right: -5px;
      }
    }
    .icon-container {
      display: block;
      margin: auto;
      text-align: center;
      margin-bottom: 5px;
    }
    .text-container {
      width: 100%;
      text-align: center;
    }
  }

  .quick-add__button {
    width: 56px;
    height: 56px;
    right: 20px;
    bottom: 75px;

    @media (min-width: 768px) {
      bottom: 20px;
    }
  }

  .quick-add__options {
    right: 44px;
    bottom: 93px;
  }

  .sport-statistics__box {
    width: 100%;
    border-right: 0 !important;
    border-bottom: 1px solid #f0f3f6 !important;
    &:last-child {
      border-bottom: 0 !important;
    }
  }

  .sport-statistics__empty {
    padding: 20px;
  }

  .activity-toolbar {
    display: none;
  }

  .yearly-statistics__value {
    font-size: 30px;
  }

  .yearly-statistics__box {
    padding: 30px 22px 22px 22px;
  }

  .bottom-line {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

}
@media only screen and (max-width: 630px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
  .trainer-thumbs {
    .trainer-thumb {
      width: 50%;
      &:nth-of-type(2n+2) {
        border-right: 0;
      }
    }
    &.four {
      .trainer-thumb {
        &:nth-of-type(2n+2) {
          border-right: 1px solid #e4e6e7;
        }
        &:nth-of-type(4n+4) {
          border-right: 0;
        }
      }
    }
  }
  .list-group-item {
    padding: 12px 45px 12px 15px;
    .icon {
      &.marker {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -4px;
      }
    }
  }
  .list-group {
    &.diary {
      .list-group-item {
        padding: 12px 15px;
      }
    }
  }
  .br-theme-bootstrap-stars {
    .br-widget {
      a {
        font-size: 29px;
      }
      .br-current-rating {
        margin: 1px 0 0 5px;
      }
    }
  }
  #map {
    padding-bottom: 80%;
  }
}
@media only screen and (max-width: 530px) {
  .trainer-thumbs {
    &.four {
      .trainer-thumb {
        width: 50%;
        &:nth-of-type(2n+2) {
          border-right: 0;
        }
      }
    }
  }
  .chart {
    width: 600px;
  }
  #statistics-content {
    width: 100%;
    overflow-x: scroll;
  }
  .fc-toolbar {
    .fc-left {
      float: none;
      display: block;
      text-align: center;
      margin-bottom: 17px;
      h2 {
        float: none;
      }
    }
    .fc-right {
      float: none;
      display: block;
      text-align: center;
      button {
        float: none;
      }
    }
    &.fc-header-toolbar {
      padding-bottom: 0;
      padding-top: 13px;
    }
  }
}
@media only screen and (max-width: 430px) {

  .fc-wk {
    width: auto;
  }

  .trainer-programme {
    width: 100%;
  }
  .xs-wrapper {
    width: 100%;
    overflow: scroll;
  }
  .panel {
    &.finances {
      .panel-heading {
        .item {
          margin-left: 0;
          padding-right: 0;
          &:first-child {
            .icon {
              display: none;
            }
          }
        }
      }
    }
  }
  .messages {
    .conversation {
      .message {
        width: 100%;
      }
      .image-container {
        margin-bottom: 20px;
      }
    }
  }
  .br-theme-bootstrap-stars {
    .br-widget {
      height: 25px;
      margin: 10px 0 25px  0;
      a {
        font-size: 17px;
      }
      .br-current-rating {
        margin: -2px 0 0 5px;
        font-size: 16px;
      }
    }
  }
  #map {
    padding-bottom: 100%;
  }
  .center-400 {
    width: 100%;
  }
  .registration {
    h2 {
      font-size: 22px;
    }
  }
  .chart-controls {
    .chart-view-text {
      padding: 10px 10px 0 10px;
    }
    .btn {
      &.icon {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
  .activities-overview {
    .panel-footer {
      .statistics {
        .item {
          font-size: 13px;
          span {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
