//
//@font-face {font-family:"Poppins-Light";
//  src: url("../fonts/poppins/Poppins-Light.eot"); /* IE9*/
//  src: url("../fonts/poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/poppins/Poppins-Light.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/poppins/Poppins-Light.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/poppins/Poppins-Light.svg#Poppins-Light") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"Poppins-Regular";
//  src: url("../fonts/poppins/Poppins-Regular.eot"); /* IE9*/
//  src: url("../fonts/poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/poppins/Poppins-Regular.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/poppins/Poppins-Regular.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/poppins/Poppins-Regular.svg#Poppins-Regular") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"Poppins-Medium";
//  src: url("../fonts/poppins/Poppins-Medium.eot"); /* IE9*/
//  src: url("../fonts/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/poppins/Poppins-Medium.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/poppins/Poppins-Medium.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/poppins/Poppins-Medium.svg#Poppins-Medium") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"Poppins-SemiBold";
//  src: url("../fonts/poppins/Poppins-SemiBold.eot"); /* IE9*/
//  src: url("../fonts/poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/poppins/Poppins-SemiBold.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/poppins/Poppins-SemiBold.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"Poppins-Bold";
//  src: url("../fonts/poppins/Poppins-Bold.eot"); /* IE9*/
//  src: url("../fonts/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/poppins/Poppins-Bold.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/poppins/Poppins-Bold.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/poppins/Poppins-Bold.svg#Poppins-Bold") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"SourceSansPro-Light";
//  src: url("../fonts/sourcesanspro/SourceSansPro-Light.eot"); /* IE9*/
//  src: url("../fonts/sourcesanspro/SourceSansPro-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/sourcesanspro/SourceSansPro-Light.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/sourcesanspro/SourceSansPro-Light.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/sourcesanspro/SourceSansPro-Light.svg#SourceSansPro-Light") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"SourceSansPro-Regular";
//  src: url("../fonts/sourcesanspro/SourceSansPro-Regular.eot"); /* IE9*/
//  src: url("../fonts/sourcesanspro/SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/sourcesanspro/SourceSansPro-Regular.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/sourcesanspro/SourceSansPro-Regular.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/sourcesanspro/SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"SourceSansPro-Semibold";
//  src: url("../fonts/sourcesanspro/SourceSansPro-Semibold.eot"); /* IE9*/
//  src: url("../fonts/sourcesanspro/SourceSansPro-Semibold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/sourcesanspro/SourceSansPro-Semibold.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/sourcesanspro/SourceSansPro-Semibold.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/sourcesanspro/SourceSansPro-Semibold.svg#SourceSansPro-Semibold") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"SourceSansPro-SemiBoldItalic";
//  src: url("../fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot"); /* IE9*/
//  src: url("../fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.svg#SourceSansPro-SemiBoldItalic") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"SourceSansPro-Bold";
//  src: url("../fonts/sourcesanspro/SourceSansPro-Bold.eot"); /* IE9*/
//  src: url("../fonts/sourcesanspro/SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/sourcesanspro/SourceSansPro-Bold.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/sourcesanspro/SourceSansPro-Bold.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/sourcesanspro/SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"SourceSansPro-BoldItalic";
//  src: url("../fonts/sourcesanspro/SourceSansPro-BoldItalic.eot"); /* IE9*/
//  src: url("../fonts/sourcesanspro/SourceSansPro-BoldItalic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/sourcesanspro/SourceSansPro-BoldItalic.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/sourcesanspro/SourceSansPro-BoldItalic.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/sourcesanspro/SourceSansPro-BoldItalic.svg#SourceSansPro-BoldItalic") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"SourceSansPro-Italic";
//  src: url("../fonts/sourcesanspro/SourceSansPro-It.eot"); /* IE9*/
//  src: url("../fonts/sourcesanspro/SourceSansPro-It.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/sourcesanspro/SourceSansPro-It.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/sourcesanspro/SourceSansPro-It.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/sourcesanspro/SourceSansPro-It.svg#SourceSansPro-It") format("svg"); /* iOS 4.1- */
//}
//@font-face {font-family:"SourceSansPro-LightItalic";
//  src: url("../fonts/sourcesanspro/SourceSansPro-LightIt.eot"); /* IE9*/
//  src: url("../fonts/sourcesanspro/SourceSansPro-LightIt.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//  url("../fonts/sourcesanspro/SourceSansPro-LightIt.woff") format("woff"), /* chrome、firefox */
//  url("../fonts/sourcesanspro/SourceSansPro-LightIt.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//  url("../fonts/sourcesanspro/SourceSansPro-LightIt.svg#SourceSansPro-LightIt") format("svg"); /* iOS 4.1- */
//}

.border-radius (@radius: 10px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}
.border-radius-extended (@radius1, @radius2, @radius3, @radius4) {
  -webkit-border-top-left-radius: @radius1;
  -webkit-border-top-right-radius: @radius2;
  -webkit-border-bottom-right-radius: @radius3;
  -webkit-border-bottom-left-radius: @radius4;
  -moz-border-radius-topleft: @radius1;
  -moz-border-radius-topright: @radius2;
  -moz-border-radius-bottomright: @radius3;
  -moz-border-radius-bottomleft: @radius4;
  border-top-left-radius: @radius1;
  border-top-right-radius: @radius2;
  border-bottom-right-radius: @radius3;
  border-bottom-left-radius: @radius4;
}
.box-shadow (@x: 5px, @y: 5px, @blur: 10px, @color: rgba(0,0,0,0.5)) {
  -webkit-box-shadow: @arguments;
  -moz-box-shadow: @arguments;
  box-shadow: @arguments;
}
.transition (@duration: 500ms) {
  -webkit-transition: all @duration ease;
  -moz-transition: all @duration ease;
  -ms-transition: all @duration ease;
  -o-transition: all @duration ease;
  transition: all @duration ease;
}
.inline-block {
  display: inline-block;
  vertical-align: top;
}
.light {
  // font-family: SourceSansPro-Light;
  font-weight: 200;
}
.regular {
  // font-family: SourceSansPro-Regular;
  font-weight: 400;
}
.semibold {
  // font-family: SourceSansPro-Semibold;
  font-weight: 600;
}
.semibolditalic {
  // font-family: SourceSansPro-SemiBoldItalic;
  font-weight: 600;
  font-style: oblique;
}
.bold {
  // font-family: SourceSansPro-Bold;
  font-weight: 700;
}
.bolditalic {
  // font-family: SourceSansPro-BoldItalic;
  font-weight: 700;
  font-style: oblique;
}
.italic {
  // font-family: SourceSansPro-Italic;
  font-style: oblique;
}
.lightitalic {
  // font-family: SourceSansPro-LightItalic;
  font-style: oblique;
  font-weight: 200;
}
.light-p {
  // font-family: Poppins-Light;
  font-weight: 200;
}
.regular-p {
  // font-family: Poppins-Regular;
  font-weight: 400;
}
.medium-p {
  // font-family: Poppins-Medium;
  font-weight: 400;
}
.semibold-p {
  // font-family: Poppins-SemiBold;
  font-weight: 600;
}
.bold-p {
  // font-family: Poppins-Bold;
  font-weight: 700;
}
.icon (@position) {
  background-image: url('../../images/sprite.png');
  background-position: @position;
  background-repeat: no-repeat;
  background-size: 750px 750px;
}
.pseudo {
  content: "";
  position: absolute;
  display: block;
}
.image (@path) {
  background-image: url(@path);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}
@primary: #3f4143;
@green: #49c100;
@grey: #8d8e90;
@red: #f42727;
@orange: #ff7043;
@blue: #31b3ef;

.rotate (@value) {
  -ms-transform: rotate(@value);
  -webkit-transform: rotate(@value);
  transform: rotate(@value);
}
