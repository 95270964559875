.footer {
  padding: 30px 0;
  border-top: 1px solid #E8E7E7;
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    padding: 50px 0;
  }

  @media only screen and (min-width: 1280px) {
    padding-left: 245px;
  }

  .container {
    width: 100%;
    max-width: 1200px;

    @media only screen and (min-width: 768px) {
      padding: 0 60px;
    }

    @media only screen and (min-width: 1280px) {
      padding: 0 10px;
      margin-left: 0;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.footer__copyright {
  margin: 0 0 20px;
  text-align: center;
  font-size: 13px;
  color: #494949;

  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 1024px) {
    margin: 0;
    text-align: left;
  }

  p {
    margin: 0;
  }
}

.footer__logo {
  display: block;
  max-width: 141px;
  margin: auto;
  transition: 0.25s opacity;
  will-change: opacity;

  @media only screen and (min-width: 1024px) {
    margin: 0;
  }

  &:hover {
    opacity: 0.5;
  }

  img {
    width: 100%;
  }
}
