#forgotten-pass-modal {
  &.modal {
    display: none;
  }

  &.modal.is-open {
    display: block;
  }

  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    padding: 15px;
    background-color: rgba(29, 29, 29, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal__container {
    position: relative;
    background-color: #fff;
    max-width: 550px;
    max-height: calc(100vh - 100px);
    border-radius: 0;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }

  .modal__body {
    padding: 20px;

    @media only screen and (min-width: 768px) {
      padding: 20px 30px;
    }
  }

  .modal__header {
    padding: 20px 40px 20px 20px;
    background-color: @green;

    @media only screen and (min-width: 768px) {
      padding: 20px 40px 20px 30px;
    }
  }

  .modal__closer {
    position: absolute;
    z-index: 5;
    right: 15px;
    top: 13px;
    padding: 5px;
    font-size: 20px;
    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    transition: color 0.25s;

    @media only screen and (min-width: 768px) {
      top: 10px;
      right: 20px;
    }

    &:hover {
      color: rgb(29, 29, 29);
    }
  }

  .modal__closer::before {
    content: "\2715";
  }

  .content h3 {
    margin: 0;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.3;
    color: #fff;

    @media only screen and (min-width: 768px) {
      font-size: 24px;
    }
  }

  .content p {
    margin: 0;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.4;
    color: #909090;
  }

  .form-control {
    transition-duration: .25s;
    transition-property: border-color;
    will-change: border-color;
    display: block;
    width: 100%;
    padding: 0 15px;
    border: none;
    border-radius: 3px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 43px;
    color: #1D1D1D;
    background-color: rgba(232, 231, 231, 0.7);

    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .form-submit {
    transition-duration: .25s;
    transition-property: background-color;
    will-change: background-color;
    display: block;
    width: 100%;
    border-radius: 28px;
    border: none;
    box-shadow: none;
    line-height: 48px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    background-color: #4AC102;
    color: #fff;
    cursor: pointer;
  }

  @keyframes mmfadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes mmfadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes mmslideIn {
    from {
      transform: translateY(15%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes mmslideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-10%);
    }
  }

  .micromodal-slide {
    display: none;
  }

  .micromodal-slide.is-open {
    display: block;
  }

  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    //will-change: transform;
  }
}