.content-box {
  padding: 20px;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.03);
  text-align: center;
  background-color: #FFFFFF;

  @media only screen and (min-width: 768px) {
    padding: 30px;
  }

  &--avatar {
    margin-top: 70px;
  }

  &:not(.content-box-reviews) + .content-box {
    border-top: 2px solid #E8E7E7;
  }

  &:last-of-type {
    margin-bottom: 20px;
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    color: #404040;

    @media only screen and (min-width: 768px) {
      font-size: 24px;
    }
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.3;
    color: #404040;

    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  }

  .btn-solid-green,
  .btn-filled {
    min-width: 236px;
  }
}

.sign-up-to-buy-text {
  margin-top: 6px;

  a {
    text-decoration: none;
    color: #707070;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 200% */

    &:hover {
      opacity: 0.75;
    }

    span {
      text-decoration: underline;
    }
  }
}

.content-box--article {
  margin-bottom: 30px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 60px;
  }
}

.content-box__content {
  margin: 0 0 25px;

  > *:last-child {
    margin-bottom: 0;
  }
}

.content-box__avatar {
  margin: -75px auto 20px;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
}