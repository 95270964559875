.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
}

.breadcrumbs__item {
  padding: 0;
  margin: 0;

  &::before {
    display: none;
  }

  &:not(:last-of-type) {
    position: relative;
    margin-right: 20px;
  }
  &:not(:last-of-type)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translate(50%,-50%);
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
    background-image: url("/images/arrow-right.svg");
  }
}

.breadcrumbs__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  color: #4AC102;

  .breadcrumbs__item:last-of-type & {
    text-decoration: none;
    font-weight: 200;
    color: #909090;
  }
}