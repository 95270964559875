@import "mixins";
@import (inline) "vendor/treneoicons.css";

//variables
@color-green: #49c100;
@color-gray: #9d9999;
@color-blue: #31b3ef;


* {
    -webkit-overflow-scrolling: touch;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0
}


html, body {
    font-family: "Poppins", sans-serif;
}

html {
    height: 100%;

}

body {
    .regular;
    background: #F5F5F5;
    color: @primary;
    font-size: 15px;
    text-align: left;
    line-height: 1.6;
    padding-top: 85px;
    min-height: 100%;
    height: auto;
    position: relative;
    @media only screen and (min-width: 1280px) {
        padding-top: 65px;
    }
}

.filter-groups {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (min-width: 768px) {
        flex-wrap: nowrap;
    }

    .group-filter-lang {
        @media (min-width: 768px) {
            margin-left: 20px;
        }
    }
}

main {
    overflow-x: hidden;
    height: 100%;
}

.underline-text {
    text-decoration: underline;
}

/*-------------GENERAL-------------*/

h1, h2, h3, h4, h5, h6 {
    .regular-p;
    line-height: 1.4;
    margin: 0 0 20px 0;

    &.light {
        .light-p;
    }
}

h1 {
    font-size: 26px;
}

h2 {
    font-size: 23px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 15px;
}

h6 {
    font-size: 14px;
}

.text-green {
    color: @green;
}

.main-headline {
    padding: 20px 0;
    background-color: #fff;
    margin: 0 0 25px 0;

    h1 {
        .regular-p;
        display: inline-flex;
        align-items: center;
        font-family: 'Rubik', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.25;
        color: #1D1D1D;
        text-align: left;
        margin: 0;

        @media only screen and (min-width: 768px) {
            font-size: 30px;
        }

        span {
            .lightitalic;
            color: #9fa1a4;
            margin-left: 12px;
        }

        .h1-question {
            margin-left: 15px;
        }

        .trainer {
            margin: 0 0 0 10px;
            .inline-block;
            font-size: 14px;
            .italic;

            .icon {
                color: #e4e6e7;
                margin-right: 8px;
            }
        }
    }

    .headline-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: @green;
    }

    .edit-input {
        .inline-block;
        width: 30%;
        font-size: 20px;
    }
}

.main-headline-detail {
    margin-bottom: 15px;

    + div .breadcrumbs {
        @media only screen and (max-width: 767px) {
            padding-top: 50px;
            justify-content: center;
        }
    }
}

.main-headline .main-headline-detail-wrapper {

    @media only screen and (min-width: 768px) {
        display: inline-flex;
        align-items: center;
    }

    .image-wrapper {
        flex: 0 0 58px;
        width: 58px;
        height: 58px;
        margin-right: 20px;

        @media only screen and (max-width: 767px) {
            margin: 0 auto !important;
        }
    }

    .trainer .image-wrapper {
        @media only screen and (max-width: 767px) {
            flex: 0 0 58px;
            width: 58px;
            height: 58px;
        }
    }

    h1 {
        @media only screen and (max-width: 767px) {
            margin: 20px 0;
            text-align: center;
        }
    }

    h1 small {
        display: inline-block;
        margin-left: 5px;
    }

    .trainer {
        @media only screen and (max-width: 767px) {
            display: block;
            margin: 0 0 -52px;
            text-align: center;
        }
    }
}

.main-headline .main-headline-detail-wrapper-mobile-centered {
    @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
        margin: 20px 0 0;

        h1 {
            margin-top: 0 !important;
        }

        h1,
        h1 small {
            display: block !important;
        }

        .image-wrapper {
            flex: 0 0 58px !important;
            margin-bottom: -40px !important;
        }
    }
}

.main-headline-article {
    position: relative;
    margin: 0 0 90px;
    padding: 20px 0 0 !important;
    text-align: center;

    .date {
        margin: 0 0 15px;
        font-weight: 600;
        font-size: 14px;
        color: #909090;
    }

    h1 {
        max-width: 600px;
        margin: 0 auto 20px !important;
        text-align: center;
    }
}

.main-headline-article-back {
    margin-bottom: 20px;

    @media only screen and (min-width: 1280px) {
        position: absolute;
        top: 30px;
        left: 40px;
        margin-bottom: 0;
    }

    @media only screen and (min-width: 1600px) {
        left: calc((100vw - 1550px) / 2);
    }
}

.main-headline-lg-m-b {
    margin-bottom: 35px;

    @media only screen and (min-width: 768px) {
        margin-bottom: 70px;
    }
}

.main-headline-none-m-b {
    margin-bottom: 0;
}

.main-headline-program {
    h1 {
        display: block;
    }

    h1 small {
        display: block;
        margin: 0;
    }

    .trainer {
        display: flex;
        align-items: center;
        margin-left: 25px;

        > a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #3f4143;

            @media only screen and (max-width: 767px) {
                display: block;
                margin: 0 0 -52px;
                text-align: center;
            }
        }
    }

    .trainer .image-wrapper {
        width: 44px;
        height: 44px;
        flex: 0 0 44px;
        border-radius: 50%;
        margin: 0 8px 0 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .trainer h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
    }
}

.main-headline-program.main-headline-detail {

    .container {
        @media only screen and (min-width: 768px) {
            display: flex;
            align-items: center;
        }
    }

    .main-headline-detail-wrapper {
        @media only screen and (min-width: 768px) {
            flex: 1;
        }
    }
}

.sub-headline {
    color: #50535a;
    .regular-p;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 20px;

    .icon {
        font-size: 30px;
        color: #e1e3e4;
        line-height: 0;
        margin-right: 10px;
        margin-top: -12px;
    }
}

p {
    margin-bottom: 25px;
}

a {
    color: @green;
    text-decoration: underline;

    &:hover, &:focus {
        text-decoration: none;
        color: @green;
        outline: none;
    }

    &.no-decoration {
        text-decoration: none;
    }

    &.orange-link {
        color: @orange;
    }
}

b, strong {
    .bold;
    font-weight: normal;
}

ol {
    counter-reset: item;
    margin: 0 0 35px 0;

    li {
        position: relative;
        padding: 8px 0 8px 14px;
        margin: 0 0 0 20px;
        list-style-type: none;
        counter-increment: item;
        font-size: 15px;
        line-height: 1.4;

        &::before {
            width: 25px;
            height: 25px;
            content: counter(item)+".";
            color: @green;
            text-align: center;
            display: block;
            position: absolute;
            line-height: 25px;
            left: -20px;
            top: 7px;
            font-size: 16px;
            .bold;
        }
    }
}

ul {
    margin: 0 0 35px 0;

    li {
        position: relative;
        padding: 2px 0 2px 14px;
        margin: 0 0 0 20px;
        list-style-type: none;

        &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            .border-radius(100%);
            background-color: @green;
            left: -11px;
            top: 12px;
        }
    }
}

.svg-icon {
    position: absolute;
}

.g-flex-container {
    display: flex;
    flex-wrap: wrap;
}

.form-control {
    height: 43px;
    .border-radius(3px);
    border: 1px solid #dbe1e5;
    .box-shadow (0px, 1px, 2px, rgba(155, 155, 155, 0.1));

    &:focus {
        border-color: #dbe1e5;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
        .box-shadow (0px, 1px, 2px, rgba(155, 155, 155, 0.1));
    }

    .modal & {
        height: 43px;
    }
}

textarea {
    &.form-control {
        max-width: 100%;
        min-height: 80px;
        padding: 12px;
    }

    &.large {
        min-height: 300px;
    }
}

.search-input {
    max-width: 100%;
    width: 280px;

    .form-control {
        width: 222px;
        height: 43px;
        padding: 14px 17px 13px;
        float: none;
        border-right: 0;
        margin-right: 0;
        display: inline-block;
        border: none;
        border-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
        background-color: rgba(#E8E7E7, 0.5);

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 1;
        color: #909090;

        @media only screen and (min-width: 768px) {
            width: 240px;
            font-size: 16px;
        }
    }

    .btn {
        background-color: #4AC102;
        border: 1px solid #4AC102;
        border-left: 0;
        height: 43px;

        &.icon {
            padding: 7px 10px;
            font-size: 15px;

            .icon-search:before {
                color: #fff;
            }
        }
    }
}

.search-input-in-conversation {
    @media only screen and (max-width: 1199px) {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.row-0 {
    margin-right: 0;
    margin-left: 0;

    > [class^="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
}

.row-5 {
    margin-right: -5px;
    margin-left: -5px;

    > [class^="col-"] {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.row-10 {
    margin-right: -10px;
    margin-left: -10px;

    > [class^="col-"] {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.row-20 {
    margin-right: -20px;
    margin-left: -20px;

    > [class^="col-"] {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.btn {
    line-height: 1.42857143 !important;
    .semibold;
    font-size: 14px;
    padding: 10px 18px;
    text-decoration: none;
    .border-radius(2px);
    .transition;

    &:focus, &:active {
        outline: none;
    }

    &:active:focus {
        outline: none;
    }

    &:active {
        border-color: #dbe1e5;
    }

    &.icon {
        padding: 7px 14px;

        .icon {
            margin: 0;
            font-size: 18px;
        }
    }

    .btn-toolbar &.icon {
        padding: 9px 14px;
    }

    .icon {
        margin: 0 18px 0 0;
        line-height: 1;
    }

    &.dropdown-toggle {
        padding: 8px 18px 8px 22px;

        .caret {
            margin-left: 10px;
        }
    }

    &.active {
        &:focus {
            outline: none;
        }
    }
}

.btn[disabled] {
    opacity: 1;
    .box-shadow (0px, 1px, 2px, rgba(155, 155, 155, 0.1));
}

.btn-disabled {
    background-color: #8e8f91 !important;
    color: #fff;

    &:hover {
        color: #fff;
    }
}

.open {
    > .dropdown-toggle {
        &.btn-default:focus {
            border-color: #dbe1e5;
        }
    }
}

.btn-default {
    border-radius: 5px;
    border: 1px solid #efefef;
    background-color: #fffeff; /* Old browsers */
    // background: -moz-linear-gradient(top,  #fffeff 0%, #f6f8f9 100%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(top,  #fffeff 0%,#f6f8f9 100%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(to bottom,  #fffeff 0%,#f6f8f9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fffeff', endColorstr='#f6f8f9',GradientType=0 ); /* IE6-9 */
    .box-shadow (0px, 1px, 2px, rgba(155, 155, 155, 0.1));
    .transition;
    color: #333 !important;
    background-size: 100% 200%;
    background-position: 0 0;

    &:hover {
        background-position: 0 98%;
        border-color: #dbe1e5;
        background-color: #dbe1e5;
    }

    &:active:focus, &:focus {
        border-color: #dbe1e5;
    }
}

.btn-primary {
    border-radius: 5px;
    background-color: #337ab7;
    border: 1px solid #337ab7;
    color: #fff !important;
    .transition;

    &:hover, &:focus, &:active {
        background-color: darken(#337ab7, 5%);
        border: 1px solid darken(#337ab7, 5%);
    }
}

.btn-success {
    border-radius: 5px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#43af01+0,336d01+100 */
    background-color: rgb(67, 175, 1); /* Old browsers */
    // background: -moz-linear-gradient(top,  rgba(67,175,1,1) 0%, rgba(51,109,1,1) 100%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(top,  rgba(67,175,1,1) 0%,rgba(51,109,1,1) 100%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(to bottom,  rgba(67,175,1,1) 0%,rgba(51,109,1,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#43af01', endColorstr='#336d01',GradientType=0 ); /* IE6-9 */
    border: 1px solid rgb(67, 175, 1);
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    background-size: 100% 200%;
    background-position: 0 0;
    color: #fff !important;

    &:hover, &:focus, &:active {
        background-position: 0 100%;
        background-color: darken(#3ea101, 5%);
        border: 1px solid darken(#3ea101, 5%);
    }
}

.btn-orange {
    border-radius: 5px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff7043+0,d65f37+100 */
    background-color: #ff7043; /* Old browsers */
    // background: -moz-linear-gradient(top,  #ff7043 0%, #d65f37 100%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(top,  #ff7043 0%,#d65f37 100%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(to bottom,  #ff7043 0%,#d65f37 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7043', endColorstr='#d65f37',GradientType=0 ); /* IE6-9 */
    border: 1px solid #ff7043;
    color: #fff !important;
    background-size: 100% 200%;
    background-position: 0 0;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

    &:hover {
        background-position: 0 100%;
        border: 1px solid darken(#ff7043, 5%);
        background-color: darken(#ff7043, 5%);
        color: #fff;
    }

    &:active, &:focus {
        background-color: #f96d41;
        color: #fff;
    }
}

.btn-blue {
    border-radius: 5px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#31b3ef+0,1da2de+100 */
    background-color: #31b3ef; /* Old browsers */
    //background: -moz-linear-gradient(top, #31b3ef 0%, #1da2de 100%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(top, #31b3ef 0%,#1da2de 100%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(to bottom, #31b3ef 0%,#1da2de 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31b3ef', endColorstr='#1da2de',GradientType=0 ); /* IE6-9 */
    border: 1px solid #31b3ef;
    color: #fff !important;
    background-size: 100% 200%;
    background-position: 0 0;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

    &:hover {
        background-position: 0 100%;
        border: 1px solid darken(@blue, 5%);
        background-color: darken(@blue, 5%);
        color: #fff;
    }

    &:active, &:focus {
        background-color: @blue;
        color: #fff;
    }
}

.btn-info {
    border-radius: 5px;
    background-color: #8c8d90;
    border: 1px solid #8c8d90;
    color: #fff !important;

    &:hover, &:focus, &:active {
        background-color: darken(#8c8d90, 10%);
        border: 1px solid darken(#8c8d90, 10%);
        .transition;
    }

    &:active:focus {
        background-color: darken(#8c8d90, 10%);
        border: 1px solid darken(#8c8d90, 10%);
    }
}

.btn-warning {
    border-radius: 5px;
    background-color: #ffb300;
    border: 1px solid #ffb300;
    color: #fff !important;

    &:hover, &:focus, &:active {
        background-color: darken(#ffb300, 10%);
        border: 1px solid darken(#ffb300, 10%);
        .transition;
    }
}

.btn-danger {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ef4747+0,eb2113+100 */
    border-radius: 5px;
    background-color: #ef4747; /* Old browsers */
    // background: -moz-linear-gradient(top, #ef4747 0%, #eb2113 100%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(top, #ef4747 0%,#eb2113 100%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(to bottom, #ef4747 0%,#eb2113 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef4747', endColorstr='#eb2113',GradientType=0 ); /* IE6-9 */
    border: 1px solid #ef4747;
    color: #fff !important;

    &:hover, &:focus {
        //background-color: darken(#f42727,25%);
        //background-position: 0 0;
        .transition;
        border: 1px solid #db4141;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#db4141+0,d61313+100 */
        background-color: #db4141; /* Old browsers */
        // background: -moz-linear-gradient(top, #db4141 0%, #d61313 100%); /* FF3.6-15 */
        // background: -webkit-linear-gradient(top, #db4141 0%,#d61313 100%); /* Chrome10-25,Safari5.1-6 */
        // background: linear-gradient(to bottom, #db4141 0%,#d61313 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#db4141', endColorstr='#d61313',GradientType=0 ); /* IE6-9 */
    }

    &:active {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#bc3838+0,bf1111+100 */
        background-color: #bc3838; /* Old browsers */
        // background: -moz-linear-gradient(top, #bc3838 0%, #bf1111 100%); /* FF3.6-15 */
        // background: -webkit-linear-gradient(top, #bc3838 0%,#bf1111 100%); /* Chrome10-25,Safari5.1-6 */
        // background: linear-gradient(to bottom, #bc3838 0%,#bf1111 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bc3838', endColorstr='#bf1111',GradientType=0 ); /* IE6-9 */
    }

    .icon-delete {
        margin: -4px 8px 0 -8px;
    }
}

.btn-order {
    border-radius: 5px;
    background-color: #fdfefe;
    border: 1px solid #dadcdd;
    color: @primary !important;
    .semibold;

    &:hover {
        -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    }

    &:active {
        -webkit-box-shadow: inset 0px 2px 10px 0px rgba(0, 0, 0, 0.21);
        -moz-box-shadow: inset 0px 2px 10px 0px rgba(0, 0, 0, 0.21);
        box-shadow: inset 0px 2px 10px 0px rgba(0, 0, 0, 0.21);
        .transition;
    }
}

.btn-chart {
    border-radius: 5px;
    background-color: #e5e8eb;
    border: 1px solid #e5e8eb;
    color: #3f4143;

    &:hover, &:focus, &:active {
        background-color: #caccce;
        border: 1px solid #caccce;
        .transition;
    }

    &.active {
        color: @green;
        border-color: @green;
        background-color: #fff;
        .box-shadow(0, 0, 0, transparent);
    }
}

.btn-plain {
    vertical-align: top;
    float: left;
    line-height: 0;
    color: @primary !important;
    font-size: 23px;
    padding: 18px 15px 16px 2px;

    .icon {
        line-height: 0;
    }

    &:hover, &:focus {
        color: @primary !important;
    }
}

#tags button {
    img {
        margin-left: 0;
        visibility: hidden;
    }
}

#tags button.active {
    img {
        margin-left: 10px;
        visibility: visible;
    }
}

.btn-plain-general {
    line-height: 0;
    color: @grey !important;
    font-size: 23px;
    padding: 15px;
    background-color: transparent;
    border: 0;

    .icon {
        line-height: 0;
    }

    &:hover {
        color: #6e6f70 !important;
        outline: 0;
    }

    &:focus {
        color: @grey !important;
        outline: 0;
    }
}

.btn-circle {
    width: 50px;
    height: 50px;
    .inline-block;
    .border-radius(100%);
    .transition;
    color: #fff !important;
    text-align: center;
    font-size: 18px;
    padding-top: 11px;

    &.green {
        background-color: #41a901;

        &:hover {
            background-color: #398f04;
        }
    }

    &.red {
        background-color: #f71111;

        &:hover {
            background-color: #d81010;
        }
    }
}

.btn-blocks {
    padding: 10px 18px 8px;

    .icon {
        font-size: 19px;
    }
}

.paginator {
    margin-bottom: 20px;

    .btn {
        min-width: 55px;
        padding: 11.5px 17px;
        font-size: 14px;
        font-weight: 600;
        border: none;
        border-radius: 3px;
        color: #909090;
        background-color: #fff;
        box-shadow: none;
        margin-left: 0 !important;

        &:active {
            .transition(0ms);
        }

        &:hover {
            background-color: rgba(#909090, .1);
        }

        &.active {
            background-color: @green;
            color: #fff;
        }
    }

    .btn + .btn + .btn {
        margin-left: 1px !important;
    }

    .btn-group {
        @media only screen and (min-width: 1280px) {
            // box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.03);
        }
    }
}

.paginator.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.dropdown-menu {
    margin: 0;
    .border-radius(1px);
    border: 1px solid #dbe1e5;
    padding: 0;

    > li {
        padding: 0;
        margin: 0;

        &::before {
            display: none;
        }

        > a {
            padding: 8px 14px;
            text-decoration: none;
            border-bottom: 1px solid #dbe1e5;
        }

        &:last-child {
            a {
                border-bottom: 0;
            }
        }
    }
}

.switcher {
    margin-top: 5px;
    width: 100%;

    .btn {
        font-size: 14px;
        border: 1px solid #dbe1e5;
        background-color: #fff;
        width: 50%;

        &.active {
            background-color: @green;
            color: #fff;
        }
    }
}

.embed-responsive {
    //margin-bottom: 40px;
}

.required {
    color: @red;
}

.select-group {
    .inline-block;
    margin-right: 20px;

    &.single {
        margin-right: 0;
    }
}

.mr-5 {
    margin-right: 5px;
}

.mt-15 {
    margin-top: 15px;
}

.mb-25 {
    margin-bottom: 25px;
}

.full-select {
    .fstElement {
        margin: 0;
        width: 100%;
    }

    .fstControls {
        width: auto;
    }

    .fstToggleBtn {
        min-width: 100%;
    }
}

input, textarea, button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
}

/*----------BLOCKS----------*/

.center-60 {
    width: 60%;
    margin: auto;
}

.center-400 {
    width: 400px;
    margin: auto;
}

/*----------ON/OFF-SWITCH---------*/

.cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

.cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
}

input.cmn-toggle-round-flat + label {
    padding: 2px;
    width: 52px;
    height: 30px;
    background-color: #fff;
    border-radius: 60px;
    transition: background 0.4s;
    .box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.1));
}

input.cmn-toggle-round-flat + label:before,
input.cmn-toggle-round-flat + label:after {
    display: block;
    position: absolute;
    content: "";
}

input.cmn-toggle-round-flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #fff;
    border-radius: 60px;
    transition: background 0.4s;
}

input.cmn-toggle-round-flat + label:after {
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: 26px;
    background-color: #b1b3b5;
    border-radius: 52px;
    transition: margin 0.4s, background 0.4s;
}

input.cmn-toggle-round-flat:checked + label:after {
    margin-left: 22px;
    background-color: @green;
}

/*----------YES/NO-SWITCH---------*/

.can-toggle {
    position: relative;
}

.can-toggle *, .can-toggle *:before, .can-toggle *:after {
    box-sizing: border-box;
}

.can-toggle input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.can-toggle input[type="checkbox"][disabled] ~ label {
    pointer-events: none;
}

.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
    opacity: 0.4;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
    content: attr(data-unchecked);
    left: 0;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    content: attr(data-checked);
}

.can-toggle label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.can-toggle label .can-toggle__label-text {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-left: 32px;
}

.can-toggle label .can-toggle__switch {
    position: relative;
    .box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.1));
    cursor: pointer;
}

.can-toggle label .can-toggle__switch:before {
    content: attr(data-checked);
    position: absolute;
    top: 0;
    text-align: center;
}

.can-toggle label .can-toggle__switch:after {
    content: attr(data-unchecked);
    position: absolute;
    z-index: 5;
    text-align: center;
    background: white;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.can-toggle input[type="checkbox"][disabled] ~ label {
    color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #b1b3b5;
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    color: #b1b3b5;
}

.can-toggle input[type="checkbox"]:hover ~ label {
    color: #6a6a6a;
}

.can-toggle input[type="checkbox"]:checked ~ label:hover {
    color: @green;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: @green;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    color: @green;
}

.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: @green;
}

.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    color: @green;
}

.can-toggle label .can-toggle__label-text {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.can-toggle label .can-toggle__switch {
    -webkit-transition: background-color 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: background-color 0.5s cubic-bezier(0, 1, 0.5, 1);
    background: #b1b3b5;
}

.can-toggle label .can-toggle__switch:before {
    color: #fff;
}

.can-toggle label .can-toggle__switch:after {
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: transform 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: transform 0.5s cubic-bezier(0, 1, 0.5, 1), -webkit-transform 0.5s cubic-bezier(0, 1, 0.5, 1);
    color: #b1b3b5;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    -webkit-transform: translate3d(49px, 0, 0);
    transform: translate3d(49px, 0, 0);
}

.can-toggle label {
    font-size: 14px;
    width: 99px;
}

.can-toggle label .can-toggle__switch {
    height: 38px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 98px;
    -ms-flex: 0 0 98px;
    flex: 0 0 98px;
    border-radius: 4px;
}

.can-toggle label .can-toggle__switch:before {
    left: 49px;
    font-size: 14px;
    line-height: 38px;
    width: 49px;
    padding: 0 12px;
}

.can-toggle label .can-toggle__switch:after {
    top: 0;
    left: 0;
    border-radius: 2px;
    width: 49px;
    line-height: 38px;
    font-size: 14px;
}

/*----------MULTIPLE-SELECT-(FASTSELECT)---------*/

.fstMultipleMode {
    .fstQueryInput {
        font-size: 14px;
        padding: 0;
        margin: 0;
        line-height: 33px;
        color: @primary;
    }

    .fstQueryInputExpanded {
        padding: 0;
    }

    .fstControls {
        padding: 5px;
    }
}

.fstChoiceItem {
    font-size: 14px;
    background-color: #f6f8f9;
    border: 1px solid #dbe1e5;
    color: @primary;
    margin: 3px 6px 3px 0;
    padding: 2px 1.6em 2px .33333em;
}

.fstChoiceRemove {
    left: auto;
    right: 0;
    color: #f42727;
    font-size: 20px;
}

.fstElement,
select.userList {
    .border-radius(3px);
    width: 280px;
    border: none;
    text-align: left;
    margin: 0;
    background-color: rgba(#E8E7E7, 0.5);
}

select.userList {
    -webkit-appearance: none;
    float: none !important;
    height: 43px;
    padding: 0 17px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1;
    color: #909090;
    background-position: 95% 50%;
    background-size: 11px 11px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 7'%3E%3Cpath d='M0 1.43C0 1.076.125.722.375.445A1.319 1.319 0 012.302.394l3.208 3.2L8.695.396a1.318 1.318 0 011.927.045 1.48 1.48 0 01-.043 2.022l-4.124 4.14a1.318 1.318 0 01-1.881.003l-4.15-4.14A1.464 1.464 0 010 1.43z' fill='%23404040' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;

    @media only screen and (min-width: 768px) {
        font-size: 16px;
    }
}

.fstResultItem {
    font-size: 14px;
    border: 0;
    border-bottom: 1px solid #dbe1e5;
    color: @primary;

    &.fstFocused {
        background-color: #f6f8f9;
        border-color: #dbe1e5;
        color: @primary;
    }

    &.fstSelected {
        background-color: #f6f8f9;
        border-color: #dbe1e5;
        color: @primary;
    }
}

.fstNoResults {
    font-size: 14px;
}

/*----------CLASSIC-SELECT---------*/

.fstToggleBtn {
    padding: 14px 17px 13px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1;
    color: #909090;

    @media only screen and (min-width: 768px) {
        font-size: 16px;
    }

    &::after {
        right: 13px;
        display: inline-block;
        border: none;
        width: 11px;
        height: 11px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 7'%3E%3Cpath d='M0 1.43C0 1.076.125.722.375.445A1.319 1.319 0 012.302.394l3.208 3.2L8.695.396a1.318 1.318 0 011.927.045 1.48 1.48 0 01-.043 2.022l-4.124 4.14a1.318 1.318 0 01-1.881.003l-4.15-4.14A1.464 1.464 0 010 1.43z' fill='%23404040' fill-rule='nonzero'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
    }

    .fstActive &::after {
        transform: rotate(180deg) translateY(5px);
    }
}

.fstSingleMode {
    .fstQueryInput {
        font-size: 14px;
    }
}

/*-----------PROGRESS-BAR----------*/

.progress {
    -webkit-box-shadow: none;
    box-shadow: none;
    .border-radius(2px);
    background-color: #e3e7eb;
}

.progress-bar {
    .transition(2000ms);
    background-color: @green;
    .medium-p;
    line-height: 24px;
    -webkit-box-shadow: none;
    box-shadow: none;
    .border-radius(2px);
}

/*--------CHECK-BOX--------*/

input[type='checkbox'] {
    opacity: 0;
}

input[type='checkbox'] + span {
    background-image: url('../../images/checkbox-radio.png');
    background-position: 0 0;
    background-size: 26px 104px;
    background-repeat: no-repeat;
    padding-left: 32px;
    margin-left: -18px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
}

input[type='checkbox']:checked + span {
    background-position: 0 -26px;
}

input[type='radio'] {
    opacity: 0;
}

input[type='radio'] + span {
    background-image: url('../../images/checkbox-radio.png');
    background-position: 0 -52px;
    background-size: 26px 104px;
    background-repeat: no-repeat;
    padding-left: 32px;
    margin-left: -18px;
    padding-top: 2px;
    padding-bottom: 1px;
}

input[type='radio']:checked + span {
    background-position: 0 -78px;
}

label {
    .regular;
    font-weight: normal;
}

/*----------ALERTS----------*/

.alert {
    padding: 25px 25px 22px 25px;
    border: 0;
    color: #fff;

    .icon {
        font-size: 35px;
        line-height: 0.5;
    }

    .icon-container {
        width: 55px;
        .inline-block;
    }

    .text-container {
        .inline-block;
        padding: 4px 0 0 0;
        width: ~"-moz-calc(100% - 80px)";
        width: ~"-webkit-calc(100% - 80px)";
        width: ~"-o-calc(100% - 80px)";
        width: ~"calc(100% - 80px)";

        a {
            color: #fff;
        }
    }
}

.alert-info {
    display: flex;
    align-items: center;
    margin: 0 0 20px !important;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09);
    border-radius: 20px;
    background-color: #FFC690;

    @media only screen and (min-width: 768px) {
        margin: 0 0 40px !important;
    }

    .icon-container {
        flex: 0 0 45px;
        width: 45px;
        max-width: 45px;
        margin-right: 10px !important;

        @media only screen and (min-width: 768px) {
            margin-right: 25px !important;
        }
    }

    .text-container {
        flex: 1;
        text-align: left !important;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.4;
        color: #AC5400;
    }
}

.close {
    opacity: 0.5;

    .icon {
        font-size: 16px;
        margin-right: 0;
    }

    &:hover {
        opacity: 1;
    }

    &:active, &:focus {
        outline: none;
    }
}

.alert-dismissible {
    .close {
        top: -18px;
        right: -13px;
        color: #fff;
    }
}

.alert-dismissible {
    .close {
        top: -18px;
        right: -13px;
        color: #fff;
    }
}

.alert-warning {
    background-color: #ffb300;
}

.alert-success,
.alert-long-success {
    background-color: @green;
}

.alert-danger {
    background-color: #f42727;
}

.flash-messages {
    position: fixed;
    top: 80px;
    right: 20px;
    width: 500px;
    z-index: 9999;
}

/*----------TABLES----------*/

.table {
    .border-radius(3px);
    margin: 4px;
    margin-bottom: 35px;
    width: ~"-moz-calc(100% - 8px)";
    width: ~"-webkit-calc(100% - 8px)";
    width: ~"-o-calc(100% - 8px)";
    width: ~"calc(100% - 8px)";
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

    > thead {
        > tr {
            > th, > td {
                background-color: #f6f8f9;
                padding: 24px 20px 20px 20px;
                text-transform: uppercase;
                .medium-p;
                color: #50535a;
                font-size: 13px;
                vertical-align: middle;

                &.active {
                    background-color: #f6f8f9;
                    .semibold;
                }
            }

            > th {
                border-bottom: 2px solid #e4e6e7;
            }

            &:first-of-type {
                > td, > th {
                    &:first-of-type {
                        .border-radius-extended(3px, 0, 0, 0);
                    }

                    &:last-of-type {
                        .border-radius-extended(0, 3px, 0, 0);
                    }
                }
            }

            &.active {
                > th, > td {
                    background-color: #f6f8f9;
                    .semibold;
                }
            }
        }
    }

    > tbody {
        > tr {
            background-color: #fff;

            > th, > td {
                padding: 20px;
                vertical-align: middle;

                &.active {
                    background-color: #f6f8f9;
                    .semibold;
                }
            }

            &:last-of-type {
                > th, > td {
                    &:first-of-type {
                        .border-radius-extended(0, 0, 0, 3px);
                    }

                    &:last-of-type {
                        .border-radius-extended(0, 0, 3px, 0);
                    }
                }
            }

            &.active {
                > th, > td {
                    background-color: #f6f8f9;
                    .semibold;
                }
            }
        }
    }

    > tfoot {
        > tr {
            > th, > td {
                padding: 20px;
                vertical-align: middle;

                &.active {
                    background-color: #f6f8f9;
                    .semibold;
                }
            }

            &.active {
                > th, > td {
                    background-color: #f6f8f9;
                    .semibold;
                }
            }
        }
    }
}

.table-bordered {
    border: 0;
}

/*-----------PANELS-----------*/

.panel {
    background-color: #FFFFFF;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.03);
    border: 0;
    border-radius: 0;

    &-lg-m-b {
        margin-bottom: 70px;

        @media only screen and (min-width: 768px) {
            margin-bottom: 130px;
        }
    }

    &.finances {
        .panel-heading {
            .item {
                .inline-block;
                .semibold;
                font-size: 15px;
                border-right: 2px solid #e4e6e7;
                padding-right: 20px;
                margin-right: 20px;

                .icon {
                    font-size: 30px;
                    color: #dadcde;
                    line-height: 1;
                    margin-right: 15px;
                    margin-top: 5px;
                    .inline-block;
                }

                .name {
                    .inline-block;
                    margin-top: 7px;
                }

                .value {
                    color: @orange;
                    .bold-p;
                    font-size: 22px;
                    margin: 9px 0 0 10px;
                    line-height: 1;
                    .inline-block;
                }

                &:last-child {
                    padding-right: 0;
                    margin-right: 0;
                    border: 0;
                }
            }
        }

        .panel-body {
            .table {
                margin: 0;
                width: 100%;

                .icon-checked {
                    font-size: 25px;
                    color: @green;
                    margin-right: 25px;
                    line-height: 0;
                }

                .icon-close {
                    font-size: 25px;
                    color: @red;
                    margin-right: 25px;
                    line-height: 0;
                }
            }
        }
    }
}

.panel-title {
    color: #50535a;
    .light-p;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 36px;
    margin-top: 2px;

    .icon {
        font-size: 30px;
        color: #e1e3e4;
        line-height: 0;
        margin-right: 10px;
        margin-top: -12px;
    }
}

.panel-heading {
    padding: 15px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
}

.panel-default {
    > .panel-heading {
        background-color: #fff;
        border-color: #e4e6e7;
    }
}

.panel-body {
    padding: 0;
    border-collapse: collapse;
}

.panel-footer {
    background-color: #f6f8f9;
    border-top: 0;
}

.panel-footer--trainer-shop {
    background-color: @green;

    .title {
        color: white;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        text-decoration: none;
    }
}

.panel-invisible {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.panel-container {
    height: 100%;
    padding-bottom: 20px;
}

.panel-library {
    height: 100%;
    padding: 12px 12px 15px 12px;

    img {
        width: 100%;
    }

    h5 {
        .bold;
        font-size: 14px;
        color: @primary;
        margin: 15px 0 0 0;
    }

    .image-wrapper {
        //max-height: 110px;
        overflow: hidden;
        margin: 0 0 15px 0;
        position: relative;

        img {
            width: 100%;
        }
    }

    .image-container {
        position: relative;

        .tag {
            width: 28px;
            height: 28px;
            .border-radius(2px);
            position: absolute;
            right: 24px;
            bottom: -10px;
            text-align: center;
            padding-top: 3px;
            background-color: @green;

            .icon {
                color: #fff;
            }
        }
    }

    p {
        margin-bottom: 10px;
    }

    .video {
        width: 80%;
        margin: auto;
        margin-bottom: 25px;
    }
}

.exercise-image {
    img {
        width: 100%;
    }

    + .exercise-image {
        margin-top: 20px;
    }
}

.exercise-image--featured {
    position: relative;
    height: 0;
    padding-bottom: 55%;
    overflow: hidden;
    object-fit: cover;

    img {
        object-fit: cover;
        position: absolute;
        height: 100%;
        width: 100%;
    }
}

.panel-eshop-big {
    padding: 0;
    box-shadow: none;
    background-color: transparent;

    #video-container {
        margin-bottom: 35px;
    }

    .article-detail-content {
        margin-bottom: 35px;
    }

    textarea {
        &.form-control {
            min-width: 100%;
            min-height: 400px;
        }
    }

    .video-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        margin-bottom: 60px;

        .btn {
            //opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .transition;
        }

        &:hover {
            .btn {
                //opacity: 1;
            }
        }
    }

    .exercise-image {
        max-width: 100%;
        margin: 0 auto 35px;
    }
}

.panel-eshop-big-body {
    padding: 0 15px;

    @media only screen and (min-width: 1200px) {
        display: flex;
    }

    .item-video-title {
        margin-top: -2.7rem;
        color: #909090;
        margin-bottom: 5px;
    }
}

.panel-shadow-hover {
    .transition;
    display: block;

    &:hover {
        box-shadow: none;
        .transition;
    }
}

.video-full {
    width: 100%;

    &.exercise-video {
        .embed-responsive {
            margin-bottom: 0;
        }
    }

    .embed-responsive {
        background-color: black;
    }
}

.sticky-box-fixed {
    position: fixed;
    top: 95px;
}

.sticky-box-end {
    position: absolute;
    top: auto !important;
    bottom: 0;
}

/*
.online-program {
  margin: 0 0 30px;

  @media only screen and (min-width: 768px) {
    margin: 0 0 60px;
  }

  .fc-event-container {
    text-align: center;
  }

  .fc {
    position: relative;
    box-shadow: none;
    border-radius: 0;
  }

  .program-cover {
    z-index: 10;
  }

  .fc-row table,
  thead,
  .fc-head-container,
  .fc td, .fc th,
  .fc-body {
    border: none;
    box-shadow: none;
  }
  .fc tr:not(:last-of-type) td {
    border-bottom: 2px solid #f5f5f5;
  }
  .fc-day-header {
    border: none;
    padding: 14px 0 10px !important;
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 13px;
    color: #404040;
    box-shadow: none;
    background-color: #E6E6E6;

    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
  .fc-widget-content .fc-wk {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    background-color: @blue;
  }
  .fc-day .fc-event-container:first-of-type {
    padding-top: 0;
  }

  .fc-event {
    padding: 0 !important;
    width: 28px;
    height: 28px !important;
    margin: 15px auto;
    display: block;
    border-radius: 50%;

    @media only screen and (min-width: 768px) {
      width: 38px;
      height: 38px !important;
    }
  }
  .fc-event::before {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    padding: 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;

    @media only screen and (min-width: 768px) {
      width: 38px;
      height: 38px;
      font-size: 14px;
    }
  }

  .fc-event.fc-event-future.type-activity::before,
  .fc-event.fc-event-future.type-training::before {
    // background-color: #909090;
  }
}
*/

.hidden-actions-container {
    .image-wrapper {
        position: relative;
    }

    .hidden-actions {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;
        .transition;
        pointer-events: none;

        .actions-btn-toolbar {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 50%;
            margin-top: -25px;

            .btn-circle {
                transform: rotateY(180deg);

                & + .btn-circle {
                    margin-left: 12px;
                }
            }
        }
    }

    &:hover {
        .hidden-actions {
            opacity: 1;
            pointer-events: auto;

            .actions-btn-toolbar {
                .btn-circle {
                    transform: rotateY(0);
                }
            }
        }
    }
}


/*----------LIST----------*/

.list-group {
    margin-bottom: 0;
}

.list-group-item {
    border: 1px solid #e4e6e7;
    border-left: 2px solid #fff;
    text-decoration: none;
    font-size: 14px;
    padding: 12px 15px;
    border-right: 0;

    &::before {
        display: none;
    }

    .icon {
        margin-right: 12px;
        line-height: 1;
        font-size: 16px;

        &.marker {
            float: right;
            font-size: 12px;
            margin-top: 6px;
        }
    }

    &:first-child {
        .border-radius(0);
    }

    &:last-child {
        .border-radius(0);
    }

    &.more {
        text-align: center;
        .semibold;
        color: @primary;

        .icon {
            margin-left: 10px;
            color: #c9c9c9;
        }

        &:hover, &:focus {
            border-left: 2px solid #fafbfb;

            .icon {
                color: @green;
            }
        }
    }
}

a {
    &.list-group-item {
        .transition;

        &:hover, &:focus {
            background-color: #fafbfb;
            border-left: 2px solid @green;
            color: @green;
            .transition;
        }
    }
}

.diary {
    border-left: 1px solid #e4e6e7;
    border-right: 1px solid #e4e6e7;
    border-top: 1px solid #e4e6e7;
    .border-radius(3px);

    .list-group-item {
        .transition;
        border: 0;
        margin: 0;
        border-bottom: 1px solid #e4e6e7;
        border-left: 2px solid #fff;

        .btn {
            &.icon {
                margin: 0 0 0 10px;
            }
        }

        .btn-toolbar {
            opacity: 0;
            .transition;
        }

        &:first-child {
            .border-radius-extended(3px, 3px, 0, 0);
        }

        &:last-child {
            .border-radius-extended(0, 0, 3px, 3px);
        }

        &:hover {
            background-color: #fafbfb;
            border-left: 2px solid @green;
            .transition;

            .btn-toolbar {
                opacity: 1;
                .transition;
            }
        }

        .timer-group {
            margin: 55px 0 0 0;
            text-align: right;

            .timer-item {
                .inline-block;
                width: 60px;
                .medium-p;
                font-size: 12px;
                text-align: center;
                margin: 0 6px;

                span {
                    display: block;
                    width: 54px;
                    height: 54px;
                    color: #fff;
                    margin: auto;
                    .regular-p;
                    font-size: 20px;
                    box-sizing: border-box;
                    padding: 13px 0 0 0;
                    .border-radius(100%);
                    margin-bottom: 8px;
                }

                &.green {
                    span {
                        background-color: @green;
                    }
                }

                &.red {
                    span {
                        background-color: @red;
                    }
                }

                &.orange {
                    span {
                        background-color: @orange;
                    }
                }

                &.grey {
                    span {
                        background-color: #d2d0d0;
                    }
                }

                &.grey-light {
                    span {
                        background-color: #eee;
                    }
                }
            }
        }

        h2 {
            font-size: 18px;
            .bold;
        }
    }
}

.trainings-list {
    .list-group-item {
        min-height: 65px;

        h2 {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .user {
            padding-left: 40px;
            position: relative;

            .image-wrapper {
                width: 30px;
                height: 30px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                .border-radius(100%);
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -15px;
            }
        }

        .training-name {
            .transition;
        }

        .text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #898b8c;
            margin-top: 11px;
            line-height: 1.4;
        }

        .btn-toolbar {
            position: absolute;
            top: 50%;
            margin-top: -20px;
            right: 20px;
            z-index: 2;
        }

        &::before {
            width: 250px;
            height: 100%;
            right: 0;
            top: 0;
            opacity: 0;
            .border-radius(0);
            left: auto;
            z-index: 1;
            .transition;
            .pseudo;
            background: -moz-linear-gradient(left, rgba(250, 251, 251, 0) 0%, rgba(250, 251, 251, 1) 16%, rgba(250, 251, 251, 1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(250, 251, 251, 0) 0%, rgba(250, 251, 251, 1) 16%, rgba(250, 251, 251, 1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(250, 251, 251, 0) 0%, rgba(250, 251, 251, 1) 16%, rgba(250, 251, 251, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00fafbfb', endColorstr='#fafbfb',GradientType=1); /* IE6-9 */
        }

        &:hover {
            &::before {
                opacity: 1;
            }

            .training-name {
                color: @blue;
            }
        }
    }
}

button {
    &.list-group-item {
        &:hover, &:focus {
            background-color: #fafbfb;
            border-left: 2px solid @green;
            color: @green;
            .transition;
        }
    }
}

.panel-library .image-wrapper.image-16-9 {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    img {
        position: absolute;
        height: 100%;
        width: 100%;
    }
}

.video-list {
    position: relative;
    margin-bottom: 20px;

    @media only screen and (min-width: 768px) {
        // margin-bottom: 60px;
    }

    .item {
        position: relative;
        counter-increment: item;
        margin: 0 0 1px;
        padding: 20px 55px 20px 65px;
        background-color: #fff;
        cursor: pointer;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.03);

        .transition;

        h3 {
            margin: 0;
            padding-right: 10px;
            font-weight: 600;
            font-size: 13px;
            line-height: 1.4;
            color: #404040;
        }

        &::before {
            content: attr(data-video-id)+".";
            position: absolute;
            left: 20px;
            top: 50%;
            display: block;
            .bold;
            font-family: 'Rubik', sans-serif;
            font-size: 24px;
            line-height: 1;
            transform: translateY(-50%);
            color: #4AC102;

            .blue-layout & {
                color: @blue;
            }
        }

        &.item-demo {

            &::before {
                content: "#";
            }
        }

        .icon {
            position: absolute;
            color: @green;
            right: 20px;
            font-size: 41px;
            height: 41px;
            top: 50%;
            line-height: 0;
            transform: translateY(-50%);
        }

        &.active {
            background-color: #f5f5f5;

            .icon {
                color: @green;
            }
        }

        &:hover {
            background-color: #f5f5f5;
        }

        &.demo {
            counter-increment: none;

            &::before {
                content: "-";
            }
        }
    }

    &.edit {
        margin-bottom: 100px;

        .item {
            .btn {
                position: absolute;
                right: 8px;
                top: 50%;
                font-size: 18px;
                margin-top: 0;
                line-height: 1;
                padding: 7px 10px;
                @media only screen and (min-width: 1280px) {
                    padding: 7px 14px;
                }

                .icon {
                    position: relative;
                    color: #fff;
                    right: auto;
                    font-size: 18px;
                    top: auto;
                    margin-top: 0;
                    line-height: 1;
                    transform: none;
                }
            }
        }

        .add-item {
            position: relative;
            border-top: 1px solid #e1e3e6;

            .btn {
                position: absolute;
                top: 7px;
                right: 8px;

                .icon {
                    margin-top: 3px;
                }
            }
        }
    }
}

.video-list-title {
    margin: 0 0 10px;
    text-transform: uppercase;
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #1D1D1D;

    @media only screen and (min-width: 768px) {
        font-size: 24px;
    }
}

.program-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#F5F5F5, .8);

    .btn-filled {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }
}

/*-----------TAB-SELECT-----------*/

.tab-select {
    .panel {
        background-color: #f6f8f9;

        .panel-heading {
            background-color: #f6f8f9;
            border: 0;
            padding: 0;
            .border-radius(3px);
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            h4 {
                margin: 0;
                .semibold;
                font-size: 14px;

                span {
                    .regular;
                }
            }

            a {
                text-decoration: none;
                color: #393c40;
                padding: 16px 15px 15px 15px;
                display: block;
            }
        }

        .panel-body {
            border: 0 !important;
            padding: 15px;

            .btn-tag {
                background-color: #fff;
                .regular;
                padding: 10px 20px;
                .border-radius (21px) !important;
                margin: 0 10px 10px 0;
                outline: 0 !important;
                -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

                &.active {
                    background-color: @green;
                    color: #fff;
                    outline: 0 !important;
                }
            }
        }
    }
}

/*-----------TABS-----------*/

.nav-tabs {
    border-bottom: 0;

    > li {
        margin: 0;
        padding: 0;

        &::before {
            display: none;
        }

        > a {
            background-color: #e5e8eb;
            color: @primary;
            text-decoration: none;
            border: 0;
            margin: 0;
            .semibold;
            .transition;
            padding: 13px 20px;

            &:hover {
                background-color: #e5e8eb;
                color: @green;
                .transition;
            }
        }

        &.active {
            > a {
                color: @green;
                border: 0;
                .transition;

                &:focus, &:hover {
                    border: 0;
                    color: @green;
                }
            }
        }

        & + li {
            margin-left: 2px;
        }
    }
}

.tab-content {
    background-color: #fff;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    .border-radius-extended(0, 3px, 3px, 3px);
}

/*-----------MENU-----------*/

.sidebar-offcanvas {
    width: 200px;
    position: fixed;
    min-height: ~"-moz-calc(100vh - 60px)";
    min-height: ~"-webkit-calc(100vh - 60px)";
    min-height: ~"-o-calc(100vh - 60px)";
    min-height: ~"calc(100vh - 60px)";
    height: ~"-moz-calc(100vh - 60px)";
    height: ~"-webkit-calc(100vh - 60px)";
    height: ~"-o-calc(100vh - 60px)";
    height: ~"calc(100vh - 60px)";
    overflow-y: auto;
    background-color: @primary;
    z-index: 999;

    .list-group {
        background-color: #36383a;
        margin-bottom: 0;
        padding-bottom: 15px;
        border-bottom: 1px solid #4f5153;

        .list-group-item {
            background-color: #36383a;
            border: 0;
            color: #b9bbbd;
            text-decoration: none;
            .bold;
            font-size: 13px;
            text-transform: uppercase;
            text-align: left;
            padding: 12px 15px 10px 15px;
            .border-radius(0);
            .transition;

            .icon {
                font-size: 16px;
                margin-right: 17px;
                margin-top: 1px;
            }

            &.active, &:hover {
                background-color: #202223;
                .transition;

                .badge {
                    color: #fff;
                }

                .icon {
                    color: @green;
                }
            }

            &:active {
                color: @green;
            }

            > .badge {
                margin-top: 2px;
                background-color: #ff7043;
                .regular;
            }

            &:focus {
                border-left: 0;
            }
        }
    }

    .bottom-group {
        background-color: transparent;
        padding-top: 15px;
        border-bottom: 1px solid #36383a;

        .list-group-item {
            background-color: transparent;
            .regular;
            text-transform: none;
        }
    }

    .add-links {
        padding: 20px 15px;
        border-top: 1px solid #4f5153;
        text-align: center;

        a {
            color: #a6a8ab;
            font-size: 13px;
            text-decoration: underline;
            display: block;
            margin-bottom: 10px;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.col-offset-200 {
    padding-left: 200px;
    padding-bottom: 50px;
    position: relative;
}

.container-fluid {
    padding-left: 35px;
    padding-right: 35px;
}

.navbar {
    min-height: 65px;
}

.navbar-header {
    p {
        margin: 0;
    }
}

.navbar-default {
    background-color: @green;
    border: 0;

    .navbar-brand {
        color: #fff;
        .medium-p;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 26px;
        line-height: 1;
        padding: 21px 0 0 15px;
        width: 145px;
        height: auto;

        img {
            width: 100%;
        }

        &:focus, &:hover {
            color: #fff;
        }
    }

    .navbar-toggle {
        border: 0;
        margin: 13px 0 0 7px;
        padding: 10px 10px;
        .transition;

        &:hover {
            background-color: darken(@green, 5%);
            .transition;
        }

        &:focus {
            background-color: transparent;

            &:hover {
                background-color: darken(@green, 5%);
            }
        }

        .icon-bar {
            background-color: #f8f8f8;
            width: 18px;
        }

        .icon-bar + .icon-bar {
            margin-top: 6px;
        }
    }

    .navbar-nav {
        li {
            padding: 0;
            margin: 0;

            &::before {
                display: none;
            }
        }

        .dropdown {
            > a {
                height: 65px;
                padding-top: 11px;
                padding-bottom: 9px;
                padding-left: 20px;
                color: #fff;
                font-size: 14px;
                line-height: 40px;
                text-decoration: none;
                width: auto;
                max-width: 500px;
                .transition;

                .avatar {
                    .inline-block;
                    overflow: hidden;
                    .border-radius(100%);
                    line-height: 1;
                    margin-right: 6px;
                    width: 42px;
                    height: 42px;

                    img {
                        width: 100%;
                    }
                }

                .name {
                    text-decoration: underline;
                    .semibold;
                }

                .caret {
                    position: relative;
                    top: -1px;
                    display: inline-block;
                    width: 11px;
                    height: 11px;
                    border: none;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 7'%3E%3Cpath d='M0 1.43C0 1.076.125.722.375.445A1.319 1.319 0 012.302.394l3.208 3.2L8.695.396a1.318 1.318 0 011.927.045 1.48 1.48 0 01-.043 2.022l-4.124 4.14a1.318 1.318 0 01-1.881.003l-4.15-4.14A1.464 1.464 0 010 1.43z' fill='%23ffffff' fill-rule='nonzero'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center center;
                }

                &:hover {
                    color: #fff;
                    background-color: @primary;
                    .transition;
                }

                &:focus {
                    color: #fff;
                }
            }

            > .dropdown-menu {
                width: 210px;
                background-color: @primary;
                .border-radius(0);
                border: 0;
                padding: 0 0 1px 0;

                > li {
                    padding: 0 12px;

                    > a {
                        color: #b9bbbd;
                        padding: 10px 0;
                        border-top: 1px solid #4f5153;
                        border-bottom: 0;
                        text-align: center;
                        text-decoration: none;

                        &:hover, &:focus {
                            color: #fff;
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        > .open {
            > a, a:hover, a:focus {
                background-color: @primary;
                color: #fff;
            }
        }

        .logout {
            margin-right: 15px;
            height: 65px;
            width: 65px;
            padding-top: 22px;
            padding-left: 23px;
            background-color: darken(@green, 2.5%);
            .transition;

            .icon {
                font-size: 21px;
                color: #fff;
            }

            &:hover {
                background-color: darken(@green, 5%);
                .transition;
            }
        }

        &.not-logged {
            a {
                width: 180px;
            }

            .dropdown-menu {
                width: 280px;
                box-sizing: border-box;
                padding: 20px;
                right: 15px;

                .form-control {
                    margin-bottom: 15px;
                }

                .btn-link {
                    width: auto;
                    padding: 9px 0;
                    color: #fff;
                    .regular;
                    border: 0;
                    font-size: 13px;
                    .transition(0);

                    &:focus, &:active {
                        outline: none;
                        border: 0;
                        .transition(0);
                    }
                }

                .btn-default {
                    .transition(0ms);

                    &:active {
                        .transition(0ms);
                    }
                }

                .social-login {
                    border-top: 1px solid #535456;
                    margin-top: 23px;
                    padding-top: 16px;

                    .item {
                        color: #fff;
                        padding: 6px 0 6px 30px;
                        position: relative;
                        font-size: 13px;
                        line-height: 1;
                        width: auto;
                        cursor: pointer;
                        .inline-block;

                        &::before {
                            .pseudo;
                            width: 25px;
                            height: 25px;
                            top: 0;
                            left: 0;
                        }

                        &.gplus {
                            margin-right: 25px;

                            &::before {
                                .icon(-109px 0);
                            }
                        }

                        &.fb {
                            &::before {
                                .icon(-136px 0);
                            }
                        }
                    }
                }
            }
        }
    }
}

/*----------DASHBOARD---------*/
.trainer-thumbs {
    background-color: #f6f8f9;
    height: auto;
    border-collapse: collapse;

    .trainer-thumb {
        width: 12.5%;
        border-collapse: collapse;
        float: left;
        position: relative;
        min-height: 1px;
        text-align: center;
        border-right: 1px solid #e4e6e7;
        border-bottom: 1px solid #e4e6e7;
        cursor: pointer;
        background-color: #fff;
        height: auto;
        text-decoration: none;
        .transition;

        .img-wrapper {
            width: 90px;
            height: 90px;
            .border-radius(100%);
            overflow: hidden;
            margin: auto;
            margin-top: 19px;
            margin-bottom: 10px;

            img {
                display: block;
                width: 100%;
                object-fit: cover;
            }

            &.no-image {
                border: 1px solid #e0e2e3;
            }
        }

        h4 {
            margin-bottom: 3px;
            font-size: 14px;
            .bold;
            color: @primary;
        }

        h5 {
            .italic;
            font-size: 13px;
            color: #898b8c;
            padding: 0 5px;
        }

        &:hover {
            background-color: #fafbfb;
            .transition;
        }

        &.add-trainer {
            background-color: #f6f8f9;
            min-height: 179px;

            .icon {
                font-size: 32px;
                margin-top: 50px;
                margin-bottom: 18px;
            }

            &:hover {
                background-color: @green;

                h4, .icon {
                    color: #fff;
                }
            }
        }

        &:nth-of-type(8n+8) {
            border-right: 0;
        }
    }

    &.four {
        .trainer-thumb {
            width: 25%;

            &:nth-of-type(4n+4) {
                border-right: 0;
            }
        }
    }
}

.activities-overview {
    .panel-body {
        text-align: center;

        .nav-tabs {
            margin: 28px 0;
            display: inline-block;

            > li {
                > a {
                    padding: 0 12px 12px 12px;
                    margin: 0 8px;
                    .semibold;
                    font-size: 15px;
                    color: @primary;
                    border-bottom: 2px solid #fff;
                    background-color: transparent;
                    .border-radius(0);
                }

                &.active {
                    > a {
                        color: @green;
                        border-bottom: 2px solid @green;
                    }
                }
            }
        }

        .bars {
            margin: auto;
            margin-bottom: 15px;

            .bar-container {
                width: 50px;
                height: 200px;
                background-color: #f6f8f9;
                .border-radius(2px);
                .inline-block;
                position: relative;
                margin-right: 8px;
                .transition;

                &:last-child {
                    margin-right: 0;
                }

                .bar {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 0;
                    background-color: @green;
                    .border-radius(2px);
                }

                span {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 5px;
                    text-align: center;
                    color: #fff;
                    .bold;
                    font-size: 14px;
                }
            }
        }
    }

    .tab-content {
        .box-shadow(0, 0, 0, transparent);
    }

    h4 {
        color: #8f9195;
        font-size: 14px;
        .italic;
    }

    .panel-footer {
        padding: 15px;

        .statistics {
            font-size: 0;
            text-align: center;

            .item {
                .inline-block;
                width: 33.33%;
                box-sizing: border-box;
                .semibold;
                font-size: 15px;
                line-height: 1;
                padding: 10px 0;
                border-right: 2px solid #e4e6e7;

                &:last-child {
                    border: 0;
                }

                span {
                    display: block;
                    color: @green;
                    font-size: 22px;
                    margin-top: 15px;
                    .bold-p;
                }
            }
        }

        h4 {
            margin: 10px 0 0 0;
            text-align: center;
        }
    }
}

/*----------TRAINER-PROFILE----------*/

.rating {
    .semibold-p;
    background-color: @orange;
    color: #fff;
    font-size: 12px;
    width: 41px;
    height: 41px;
    .border-radius(100%);
    box-sizing: border-box;
    text-align: center;
    border: 3px solid #fff;

    .icon {
        display: block;
        font-size: 10px;
        line-height: 0;
        margin-top: 5px;
    }
}

.trainer-profile {
    text-align: center;
    padding-top: 25px;

    .trainer-avatar {
        width: 200px;
        height: 200px;
        margin: auto;
        position: relative;

        .image-wrapper {
            width: 200px;
            height: 200px;
            .border-radius(100%);
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .rating {
            position: absolute;
            top: 6px;
            left: -4px;
        }
    }

    .register-date {
        color: #9fa1a4;
        margin: 20px 0;
    }

    .info-box {
        margin: 0 15px;
        border-top: 1px solid #e4e6e7;
        text-align: left;
        padding: 20px 0;
        .semibold;

        .icon {
            .inline-block;
            width: 45px;
            text-align: center;
            color: @green;
            font-size: 18px;
        }

        .info {
            .inline-block;

            a {
                color: @primary;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &.address {
            .regular;
        }
    }

    .panel-footer {
        text-align: center;
        padding: 20px 0;

        .statistics {
            .inline-block;
            width: 30%;
            box-sizing: border-box;
            padding: 5px 0;
            .bold;
            color: @green;
            font-size: 20px;
            line-height: 1;
            border-right: 2px solid #dadcdd;

            &:last-child {
                border: 0;
            }

            span {
                display: block;
                .semibold;
                font-size: 13px;
                color: #929596;
                margin: 0 0 20px 0;
            }
        }
    }
}

.trainer-programme {
    background-color: #fff;
    .border-radius(3px);
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

    .row {
        margin: 0;
    }

    .flex-container {
        display: flex;
        align-items: stretch;

        .item {
            padding: 0;
            width: 50%;
        }
    }

    h2 {
        .light-p;
        font-size: 18px;
        text-transform: uppercase;
        margin: 0;
        padding: 25px 20px;
        border-bottom: 1px solid #e4e6e7;
        -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }

    .programme-info {
        padding: 25px 20px;
        .bold;

        .explanations {
            margin-top: 30px;
            .italic;
            color: #9a9b9f;
        }
    }

    .programme-tariffs {
        position: relative;
        height: 100%;
        min-height: 410px;

        .tariff {
            background-color: @green;
            height: 100%;
            min-height: 100% !important;
            color: #fff;
            text-align: center;
            position: relative;

            .months-count {
                .bold-p;
                font-size: 17px;
                width: 46px;
                height: 46px;
                margin: auto;
                border: 3px solid #fff;
                text-align: center;
                box-sizing: border-box;
                margin-top: 42px;
                .border-radius(100%);
                padding-top: 9px;
                position: relative;
                margin-bottom: 12px;

                &::before {
                    width: 32px;
                    height: 1px;
                    position: absolute;
                    display: block;
                    content: "";
                    left: -35px;
                    top: 50%;
                    background: -moz-linear-gradient(left, rgba(157, 221, 118, 0) 0%, rgba(157, 221, 118, 1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left, rgba(157, 221, 118, 0) 0%, rgba(157, 221, 118, 1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to right, rgba(157, 221, 118, 0) 0%, rgba(157, 221, 118, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#009ddd76', endColorstr='#9ddd76',GradientType=1); /* IE6-9 */
                }

                &::after {
                    width: 32px;
                    height: 1px;
                    position: absolute;
                    display: block;
                    content: "";
                    right: -35px;
                    top: 50%;
                    background: -moz-linear-gradient(left, rgba(157, 221, 118, 1) 0%, rgba(157, 221, 118, 0) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left, rgba(157, 221, 118, 1) 0%, rgba(157, 221, 118, 0) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to right, rgba(157, 221, 118, 1) 0%, rgba(157, 221, 118, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ddd76', endColorstr='#009ddd76',GradientType=1); /* IE6-9 */
                }
            }

            .months-label {
                .medium-p;
                font-size: 13px;
                padding-bottom: 30px;
                margin-bottom: 20px;
                position: relative;

                &::after {
                    position: absolute;
                    content: "";
                    bottom: 0;
                    left: 50%;
                    margin-left: -15px;
                    width: 30px;
                    height: 3px;
                    background-color: #41aa00;
                }
            }

            .price {
                .bold;
                font-size: 28px;

                span {
                    .regular;
                    font-size: 20px;
                }
            }

            .btn-order {
                position: absolute;
                width: 110px;
                bottom: 30px;
                left: 50%;
                margin-left: -55px;
            }

            &.active {
                background-color: #40a800;

                .months-label {
                    &::after {
                        background-color: #3b9704;
                    }
                }
            }
        }

        .log-in-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            padding: 0 0 30px 0;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#43b100+0,43b100+100&0+0,0.8+28,1+100 */
            background: -moz-linear-gradient(top, rgba(67, 177, 0, 0) 0%, rgba(67, 177, 0, 0.8) 28%, rgba(67, 177, 0, 1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(67, 177, 0, 0) 0%, rgba(67, 177, 0, 0.8) 28%, rgba(67, 177, 0, 1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(67, 177, 0, 0) 0%, rgba(67, 177, 0, 0.8) 28%, rgba(67, 177, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0043b100', endColorstr='#43b100',GradientType=0); /* IE6-9 */

            div {
                color: #fff;
                font-size: 14px;
                width: 80%;
                margin: auto;
                margin-bottom: 20px;

                span {
                    .bold;
                }
            }

            .btn-default {
                .transition(0ms);
                font-size: 17px;
                padding: 10px 36px;
            }
        }
    }
}

/*-----------TRAINER-LANDING------------*/

.trainer-landing {
    .main-headline {
        padding: 10px 0 3px;

        .btn-toolbar {
            margin-top: 6px;
        }
    }

    .image-wrapper {
        flex: 0 0 50px;
        width: 50px;
        height: 50px;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        .border-radius(100%);
        .inline-block;
        position: relative;

        .rating {
            position: absolute;
            top: 5px;
            left: -30px;
        }
    }

    .name {
        .inline-block;
        margin-left: 10px;

        h1 {
            line-height: 1;
            margin-top: 8px;
        }

        span {
            display: block;
            line-height: 0.8;
            .lightitalic;
            color: #9fa1a4;
            font-size: 16px;
        }
    }

    .trainer-profile {
        padding-top: 0;

        .info-box {
            &.address {
                border: 0;
            }
        }

        .video-full {
            .embed-responsive {
                margin-bottom: 0;
                .border-radius-extended(3px, 3px, 0, 0);
            }
        }
    }

    .trainer-programme {
        margin-top: 160px;
        margin-bottom: 35px;

        .programme-tariffs {
            min-height: 270px;

            .tariff {
                position: relative;

                .months {
                    position: absolute;
                    top: -139px;
                    left: 0;
                    width: 100%;
                    background-color: #d2d6db;

                    .months-count {
                        margin-top: 21px;
                        color: #5e646a;
                        border-color: #5e646a;

                        &::before {
                            background: -moz-linear-gradient(left, rgba(139, 144, 149, 0) 0%, rgba(139, 144, 149, 1) 100%); /* FF3.6-15 */
                            background: -webkit-linear-gradient(left, rgba(139, 144, 149, 0) 0%, rgba(139, 144, 149, 1) 100%); /* Chrome10-25,Safari5.1-6 */
                            background: linear-gradient(to right, rgba(139, 144, 149, 0) 0%, rgba(139, 144, 149, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#008b9095', endColorstr='#8b9095',GradientType=1); /* IE6-9 */
                        }

                        &::after {
                            background: -moz-linear-gradient(left, rgba(139, 144, 149, 1) 0%, rgba(139, 144, 149, 0) 100%); /* FF3.6-15 */
                            background: -webkit-linear-gradient(left, rgba(139, 144, 149, 1) 0%, rgba(139, 144, 149, 0) 100%); /* Chrome10-25,Safari5.1-6 */
                            background: linear-gradient(to right, rgba(139, 144, 149, 1) 0%, rgba(139, 144, 149, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8b9095', endColorstr='#008b9095',GradientType=1); /* IE6-9 */
                        }
                    }

                    .months-label {
                        margin-bottom: 0;
                        padding-bottom: 15px;
                        color: #5e646a;

                        &::after {
                            display: none;
                        }
                    }
                }

                &.active {
                    .months {
                        background-color: #c3c8ce;
                    }

                    .price {
                        &::before {
                            background-color: #3b9704;
                        }
                    }
                }

                &:first-child {
                    .months {
                        .border-radius-extended(3px, 0, 0, 0);
                    }
                }

                &:last-child {
                    .months {
                        .border-radius-extended(0, 3px, 0, 0);
                    }
                }

                .price {
                    font-size: 25px;
                    margin-bottom: 10px;
                    padding-top: 40px;
                    margin-top: 30px;
                    position: relative;
                    line-height: 1;

                    span {
                        font-size: 14px;
                    }

                    &::before {
                        .pseudo;
                        background-color: #41aa00;
                        width: 30px;
                        height: 3px;
                        top: 0;
                        left: 50%;
                        margin-left: -15px;
                    }
                }

                .saving {
                    font-size: 13px;
                    color: rgba(255, 255, 255, 0.5);
                    margin-bottom: 10px;
                }

                .info {
                    font-size: 13px;
                    line-height: 1.2;
                    color: rgba(255, 255, 255, 0.4);
                }

                .btn-order {
                    top: 20px;
                    bottom: auto;
                    //width: 92px;
                    width: auto !important;
                    transform: translateX(-50%);
                    //margin-left: -46px;
                    margin-left: 0 !important;
                }

                .btn-remote {
                    width: 160px;
                    margin-left: -80px;
                    bottom: auto;
                    top: 50%;
                    margin-top: -19px;
                }
            }
        }

        h2 {
            span {
                .bold-p;
            }
        }

        .programme-info {
            .regular;
        }

        .plus {
            h2 {
                border-top: 1px solid #e5e5e5;
            }

            .programme-tariffs {
                .tariff {
                    background-color: #3ea200;

                    &.active {
                        background-color: #3b9800;
                    }

                    .price {
                        &::before {
                            background-color: #317f00;
                        }
                    }
                }
            }
        }

        &.v3 {
            margin-top: 40px;

            .programme-tariffs {
                min-height: 350px;

                .tariff {
                    .months {
                        position: relative;
                        top: auto;
                        left: auto;
                        background-color: transparent;

                        .months-count {
                            color: #fff;
                            border-color: #fff;
                            margin-bottom: 3px;
                            margin-top: 10px;

                            &::before {
                                background: -moz-linear-gradient(left, rgba(157, 221, 118, 0) 0%, rgba(157, 221, 118, 1) 100%); /* FF3.6-15 */
                                background: -webkit-linear-gradient(left, rgba(157, 221, 118, 0) 0%, rgba(157, 221, 118, 1) 100%); /* Chrome10-25,Safari5.1-6 */
                                background: linear-gradient(to right, rgba(157, 221, 118, 0) 0%, rgba(157, 221, 118, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#009ddd76', endColorstr='#9ddd76',GradientType=1); /* IE6-9 */
                            }

                            &::after {
                                background: -moz-linear-gradient(left, rgba(157, 221, 118, 1) 0%, rgba(157, 221, 118, 0) 100%); /* FF3.6-15 */
                                background: -webkit-linear-gradient(left, rgba(157, 221, 118, 1) 0%, rgba(157, 221, 118, 0) 100%); /* Chrome10-25,Safari5.1-6 */
                                background: linear-gradient(to right, rgba(157, 221, 118, 1) 0%, rgba(157, 221, 118, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ddd76', endColorstr='#009ddd76',GradientType=1); /* IE6-9 */
                            }
                        }

                        .months-label {
                            color: #fff;
                            padding-bottom: 0;
                        }
                    }

                    .price {
                        margin-top: 0;

                        &::before {
                            display: none;
                        }
                    }

                    &.overflow {
                        &::before {
                            .pseudo;
                            width: 100%;
                            height: 9px;
                            background-color: @green;
                            top: -9px;
                            left: 0;
                        }

                        &:nth-of-type(1) {
                            &::before {
                                .border-radius-extended(5px, 0, 0, 0);
                            }
                        }

                        &:nth-of-type(3) {
                            &::before {
                                .border-radius-extended(0, 5px, 0, 0);
                            }
                        }

                        &.active {
                            &::before {
                                background-color: #40a800;
                            }
                        }
                    }
                }

                &.plus-tariff {
                    min-height: 0;

                    .tariff {
                        .price {
                            padding-top: 20px;
                            font-size: 50px;
                        }
                    }
                }
            }
        }
    }
}

/*------------TRAINER-LIST-------------*/

.menu-items-paginator {
    margin-right: 10px;

    .row-menu {
        background: url('../../images/svg/menu-black.svg') center center no-repeat, #fff;
        background-size: 26px auto;
        width: 43px;
        height: 43px;

        &.active {
            background: url('../../images/svg/menu-white.svg') center center no-repeat;
            background-size: 26px auto;
        }
    }

    .grid-menu {
        background: url('../../images/svg/second-menu-black.svg') center center no-repeat, #fff;
        background-size: 26px auto;
        width: 43px;
        height: 43px;

        &.active {
            background: url('../../images/svg/second-menu-white.svg') center center no-repeat;
            background-size: 26px auto;
        }
    }
}

.trainer-list-item {
    margin: 0 0 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    color: @primary;

    @media only screen and (min-width: 768px) {
        margin: 0 0 10px;

        &:last-child {
            margin: 0 0 20px;
        }
    }

    &.row-list {
        .panel {
            @media only screen and (min-width: 992px) {
                flex-direction: row;
            }
        }

        @media only screen and (min-width: 768px) {
            .image {
                width: 62px;

                .image-wrapper {
                    margin-right: 10px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .btn {
                padding: 8px 16px;
            }
        }

        .panel-body {
            @media only screen and (min-width: 992px) {
                width: calc(100% - 610px);
            }

            @media only screen and (min-width: 768px) {
                display: flex;
                align-items: center;
                padding: 10px 13px;
            }
        }

        .info {
            @media only screen and (min-width: 768px) {
                h2 {
                    margin-bottom: 0px;
                }

                .category {
                    margin-bottom: 0;
                    font-style: italic;
                }
            }
        }

        .panel-footer {
            @media only screen and (min-width: 768px) {
                padding: 5px 15px;

                .action-buttons {
                    .left {
                        width: auto;
                        padding-top: 5px;
                    }

                    .right {
                        width: auto;
                        margin-left: 10px;
                        padding: 5px 0 0 10px;
                        border-left: 1px solid #DCE1E5;
                    }
                }
            }
        }

        .image {
            .image-wrapper {
                margin: 0 20px 0 0;
            }
        }
    }

    .image {
        width: 120px;
        .inline-block;
        text-align: center;

        .image-wrapper {
            overflow: hidden;
            .border-radius(50%);
            margin: 0 20px 0 0;

            img {
                .border-radius(50%);
                width: 88px;
                height: 88px;
            }
        }

        span {
            font-size: 13px;
            color: #9fa1a4;
            margin: 2px 0 0 0;
            display: block;
            text-align: left;
        }
    }

    .info {
        .inline-block;
        width: ~"-moz-calc(100% - 125px)";
        width: ~"-webkit-calc(100% - 125px)";
        width: ~"-o-calc(100% - 125px)";
        width: ~"calc(100% - 125px)";

        h2 {
            .bold;
            font-size: 16px;
            margin-bottom: 3px;
            text-decoration: none;
        }

        .category {
            display: block;
            margin-bottom: 10px;
            font-weight: 200;
            line-height: 1;
            color: #9fa1a4;
        }

        p {
            font-size: 13px;
            color: #545658;
            padding-right: 15px;
        }

        .note {
            padding-bottom: 20px;
        }
    }

    .note-btn {
        margin-bottom: 5px;
    }

    .panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin: 0;
        text-decoration: none;
    }

    .panel-body {
        padding: 20px;

        @media only screen and (min-width: 768px) {
            padding: 30px;
        }
    }

    .panel-footer {
        display: flex;
        align-items: center;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.03);
        background-color: #F3F3F3;
        border-top: 0;
        padding: 15px;

        @media only screen and (min-width: 768px) {
            padding: 25px;
        }

        .action-buttons {
            width: 100%;
            font-size: 0;

            .left, .right {
                .inline-block;

                .btn {
                    & + .btn {
                        margin-left: 10px;
                    }
                }
            }

            .left {
                width: 45%;
            }

            .right {
                width: 55%;
                text-align: right;
                position: relative;
                /*
        &::before {
          width: 1px;
          height: 22px;
          background-color: #dadcdd;
          .pseudo;
          left: 0;
          top: 50%;
          margin-top: -11px;
        }
         */
            }
        }

        .statistics {
            .semibold;
            .inline-block;
            margin: 0;
            padding: 0;
            color: #404040;
            font-size: 13px;

            @media only screen and (min-width: 768px) {
                font-size: 15px;
            }

            span {
                color: @green;
                .bold-p;
                font-size: inherit;
                .inline-block;
            }

            &:first-child {
                border-right: 1px solid #dadcdd;
                padding-right: 20px;
                margin-right: 20px;
            }

            &:last-child {
                border-right: 0;
                padding-right: 0;
                margin-right: 0;
            }
        }

        .rating-container {
            .inline-block;
            float: right;
            margin-bottom: 18px;
        }
    }

    &:hover, &:focus {
        color: @primary;
    }
}

.trainer-list-item-desc {
    margin: 13px 0 0;
    font-size: 14px;
    line-height: 1.7;
    color: #404040;
}

.trainer-list-item-head {
    display: flex;
    align-items: center;

    h2 {
        color: #404040;
    }
}

/*-----------TRAININGS----------*/

.trainings {
    .header {
        padding: 15px;
    }

    .tab-select {
        border-top: 1px solid #e1e3e4;
    }

    .panel-group {
        margin: 0;
    }

    .diary {
        .border-radius(0);
        border-right: 0;
        border-left: 0;

        .list-group-item {
            cursor: pointer;

            &:first-child {
                .border-radius(0);
            }
        }

        h2, p {
            margin: 11px 0 0 0;
            line-height: 1.4;
        }

        h2 {
            font-size: 14px;
        }

        p {
            color: #898b8c;
        }
    }

    .alert {
        .border-radius(0);
        margin-bottom: 0;
        padding: 18px;
        color: #50535a;
        .semibold;
        -webkit-box-shadow: inset 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: inset 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0px 2px 2px 0px rgba(0, 0, 0, 0.1);

        .text-container {
            width: 100%;
            padding: 0;
            text-align: center;
        }
    }

    .alert-dismissible {
        position: relative;

        .close {
            position: absolute;
            top: 50%;
            margin-top: -14px;
            right: 22px;
            color: #000;
        }
    }

    .alert-warning {
        background-color: #feff9f;
    }
}

.training {
    .panel-heading {
        background-color: #f6f8f9;
        color: @primary;

        p {
            margin-bottom: 10px;
        }
    }

    .panel-body {
        .section {
            padding: 25px 20px;
            border-bottom: 1px dashed #d6d8d9;

            .in {
                padding: 4px 0 8px 26px;
                border-left: 3px solid transparent;

                h2 {
                    margin-bottom: 8px;
                    .bold;
                    font-size: 17px;
                    text-transform: uppercase;
                    color: @primary;
                }

                h3 {
                    .bold;
                    font-size: 17px;
                    line-height: 1;
                    margin: 15px 0 10px 0;
                }

                > p {
                    margin-bottom: 0;
                    line-height: 1;
                }

                .play {
                    padding: 9px 10px 8px 13px;
                    margin-top: 5px;
                }

                &.yellow {
                    border-color: #ffb924;
                }

                &.red {
                    border-color: #f42727;
                }

                &.blue {
                    border-color: #57cbe4;
                }

                .exercise {
                    margin-top: 15px;

                    .number {
                        .semibold-p;
                        font-size: 14px;
                        .inline-block;
                        width: 38px;
                        height: 38px;
                        box-sizing: border-box;
                        text-align: center;
                        background-color: #e5e8eb;
                        padding-top: 10px;
                        .border-radius(2px);

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .number.is-completed {
                        background-color: @green;
                        color: white;
                    }

                    .info {
                        .inline-block;
                        margin: 0 0 0 20px;
                        width: ~"-moz-calc(100% - 110px)";
                        width: ~"-webkit-calc(100% - 110px)";
                        width: ~"-o-calc(100% - 110px)";
                        width: ~"calc(100% - 110px)";
                        line-break: anywhere;

                        h3 {
                            .bold;
                            font-size: 15px;
                            line-height: 1;
                            margin: 1px 0 2px 0;
                        }

                        p {
                            margin: 0;
                            font-size: 12px;
                        }
                    }

                    .play {
                        margin-top: 0;
                    }
                }

                .rest,
                .note {
                    background-color: #e5e8eb;
                    margin-top: 15px;
                    padding: 8px 0 6px 6px;

                    .icon {
                        color: #9c9ea0;
                        font-size: 26px;
                        line-height: 0;
                    }

                    p {
                        .inline-block;
                        .semibold;
                        font-size: 14px;
                        margin: 0;
                        padding: 1px 0 0 25px;
                    }
                }

                .linked-exercises {
                    margin-top: 15px;
                    font-size: 0;
                    line-height: 0;

                    .linked-container {
                        border: 2px dashed @green;
                        .inline-block;
                        padding: 3px;
                        margin-left: -3px;
                        box-sizing: border-box;
                        margin-right: 16px;
                        .border-radius(4px);

                        .number {
                            .semibold-p;
                            font-size: 14px;
                            width: 38px;
                            height: 38px;
                            box-sizing: border-box;
                            text-align: center;
                            background-color: #e5e8eb;
                            line-height: 1;
                            .border-radius(2px);

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .number.is-completed {
                            background-color: @green;
                            color: white;
                        }

                        .icon {
                            color: @green;
                            font-size: 20px;
                            width: 38px;
                            text-align: center;
                            line-height: 0;
                            margin: 9px 0 8px 0;
                            height: 21px;
                        }
                    }

                    .linked-info {
                        .inline-block;
                        width: ~"-moz-calc(100% - 70px)";
                        width: ~"-webkit-calc(100% - 70px)";
                        width: ~"-o-calc(100% - 70px)";
                        width: ~"calc(100% - 70px)";

                        .linked-exercise {
                            margin: 0 0 38px 0;

                            h3 {
                                .bold;
                                font-size: 17px;
                                line-height: 1;
                                margin: 6px 0 7px 0;
                            }

                            p {
                                margin: 0;
                                font-size: 14px;
                                line-height: 1;
                            }

                            .play {
                                margin-right: -9px;
                                margin-top: 0;
                            }

                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            &:last-child {
                border: 0;
            }
        }
    }
}

.small-select {
    .fstElement {
        width: 200px !important;
    }
}

/*---------CALENDAR-TRAINING---------*/

.calendar-training {
    .panel-heading {
        background-color: #fff;

        h1 {
            color: @primary;
            .light-p;
            margin: 6px 0;
            font-size: 18px;
        }
    }

    .panel-body {
        .section {
            .in {
                padding: 4px 0 8px 0;
            }

            &.description {
                background-color: #f6f8f9;

                p {
                    color: @primary;
                    line-height: 1.4;
                }
            }
        }
    }
}

.icon-input {
    position: relative;
    margin-bottom: 15px;

    .icon {
        position: absolute;
        top: 11px;
        right: 12px;
        font-size: 17px;
        color: #b9bbbd;
        pointer-events: none
    }

    .form-control {
        padding: 6px 30px 6px 12px;
    }
}

.input-label {
    margin: 6px 0 0 -15px;
    .inline-block;
}

/*-----------CREATE-TRAINING-----------*/

.create-training {
    .panel-heading {
        padding: 20px;

        h2 {
            padding-top: 16px;
            margin-bottom: 10px;
        }
    }

    .tab-select {
        margin-bottom: 0;

        .panel {
            .border-radius(0);
        }
    }

    h2 {
        .bold;
        font-size: 17px;
        text-transform: uppercase;
        color: @primary;
        margin: 0 0 15px 0;

        span {
            .regular;
            font-size: 15px;
            text-transform: none;
        }
    }

    .section {
        padding: 25px 20px;
        border-bottom: 1px dashed #d6d8d9;

        .form-control[readonly] {
            background-color: #fff;
            cursor: pointer;
        }

        .choose-input {
            position: relative;

            .icon {
                position: absolute;
                top: 9px;
                right: 12px;
                font-size: 17px;
                color: @green;
                pointer-events: none
            }
        }

        .exercise-input {
            width: ~"-moz-calc(100% - 58px)";
            width: ~"-webkit-calc(100% - 58px)";
            width: ~"-o-calc(100% - 58px)";
            width: ~"calc(100% - 58px)";
            .inline-block;
        }

        .choose-from-library {
            .inline-block;
            float: right;
        }

        &:last-child {
            border-bottom: 0;
        }

        &:nth-of-type(2n+1) {
            background-color: #f6f8f9;
        }

        .exercise {
            .number {
                .semibold-p;
                font-size: 14px;
                .inline-block;
                width: 38px;
                height: 38px;
                box-sizing: border-box;
                text-align: center;
                background-color: #e5e8eb;
                padding-top: 10px;
                .border-radius(2px);
                margin-right: 10px;
            }

            .inputs {
                .inline-block;
                width: ~"-moz-calc(100% - 125px)";
                width: ~"-webkit-calc(100% - 125px)";
                width: ~"-o-calc(100% - 125px)";
                width: ~"calc(100% - 125px)";
            }
        }

        .link {
            margin: 0 0 8px 8px;
            cursor: pointer;

            .icon {
                font-size: 20px;
                color: #646668;
                line-height: 1;
            }

            &.active {
                .icon {
                    color: @green;
                }
            }
        }

        .rest {
            background-color: #e5e8eb;
            color: @primary;
            .semibold;
            font-size: 14px;
            padding: 9px 12px 9px 15px;
            margin-top: 15px;
            margin-bottom: 15px;

            .icon-timer {
                font-size: 26px;
                color: #9c9ea0;
                line-height: 0;
                margin-top: 7px;
                .inline-block;
            }

            label {
                color: @primary;
                .semibold;
                font-size: 12px;
                line-height: 1;
                margin: 12px 10px 0 10px;
                .inline-block;

                &.normal-text {
                    .regular;
                }
            }

            .increase-input {
                .inline-block;
                font-size: 0;
                line-height: 0;

                .form-control {
                    width: 50px;
                    text-align: center;
                    .inline-block;
                    border: 1px solid #dbe1e5;
                    .border-radius-extended(2px, 0, 0, 2px);
                }

                .buttons {
                    .inline-block;

                    .btn {
                        border-radius: 0;
                        height: auto;
                        width: 19px;
                        display: block;
                        box-sizing: border-box;
                        padding: 0;
                        line-height: 21px !important;
                        font-size: 12px;
                        border: 0;
                        border-right: 1px solid #dbe1e5;
                        border-top: 1px solid #dbe1e5;
                        border-bottom: 1px solid #dbe1e5;
                        background-color: #fff;
                    }

                    .btn:first-of-type {
                        margin-bottom: -1px;
                        border-bottom: 0;
                    }

                    .btn:last-of-type {
                        line-height: 20px !important;
                    }

                    #minus {
                        border-top: 0;
                        .border-radius-extended(0, 0, 2px, 0);
                    }

                    #plus {
                        .border-radius-extended(0, 2px, 0, 0);
                    }
                }
            }
        }

        .note {
            background-color: #e5e8eb;
            margin: 15px 0;
            padding: 9px 12px 9px 15px;

            .icon-messenger {
                font-size: 22px;
                color: #9c9ea0;
                line-height: 0;
                margin-top: 7px;
                display: inline-block;
                vertical-align: top;
            }

            label {
                font-size: 14px;
                margin: 9px 10px 0 10px;
                vertical-align: top;
                font-weight: 700;
                cursor: move;
            }

            .inputs {
                display: inline-block;
                width: calc(100% - 185px);
            }
        }

        .btn-toolbar {
            margin-top: 25px;
        }

        &.warm-cool {
            .exercise {
                + .exercise {
                    margin-top: 15px;
                }
            }
        }
    }

    .training-type-select {
        text-align: right;

        .item {
            .inline-block;

            input[type="radio"] {
                & + label {
                    background: none;
                    background-color: #fff;
                    width: 170px;
                    height: 105px;
                    text-align: center;
                    border: 1px solid #dadcdd;
                    .box-shadow(0, 0, 3px, rgba(0, 0, 0, 0.08));
                    padding-top: 70px;
                    font-size: 14px;
                    .semibold;
                    cursor: pointer;
                    .border-radius(3px);
                    position: relative;
                    background: rgb(255, 255, 255); /* Old browsers */
                    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 248, 249, 1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 248, 249, 1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 248, 249, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f6f8f9',GradientType=0);

                    &:hover {
                        background: rgb(255, 255, 255); /* Old browsers */
                        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(250, 252, 249, 1) 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(250, 252, 249, 1) 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(250, 252, 249, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fafcf9',GradientType=0); /* IE6-9 */
                    }

                    &::before {
                        .pseudo;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                &:checked {
                    & + label {
                        background: #fff;
                        border-color: @green;
                        -webkit-box-shadow: 0px 0px 0px 1px rgba(218, 220, 221, 1);
                        -moz-box-shadow: 0px 0px 0px 1px rgba(218, 220, 221, 1);
                        box-shadow: 0px 0px 0px 1px rgba(218, 220, 221, 1);
                    }
                }
            }

            &.private {
                input[type="radio"] {
                    & + label {
                        &::before {
                            width: 31px;
                            height: 46px;
                            .image('../../images/svg/lock-grey.svg');
                            top: 17px;
                        }
                    }
                }
            }

            &.example {
                input[type="radio"] {
                    & + label {
                        &::before {
                            width: 47px;
                            height: 41px;
                            .image('../../images/svg/board-grey.svg');
                            top: 21px;
                        }
                    }
                }
            }

            &.individual {
                position: relative;

                .fstElement {
                    position: absolute;
                    top: 20px;
                    left: 13px;
                    z-index: 1;
                    width: 150px;
                    margin: 0;
                    opacity: 0;
                    pointer-events: none;

                    .fstToggleBtn {
                        min-width: 100%;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 12px;
                    }

                    .fstToggleBtn::after {
                        right: 5px;
                    }
                }

                &.active {
                    .fstElement {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }

                input[type="radio"] {
                    & + label {
                        &::before {
                            width: 30px;
                            height: 45px;
                            .image('../../images/svg/watch-grey.svg');
                            top: 18px;
                        }
                    }

                    &:checked {
                        & + label {
                            &::before {
                                opacity: 0;
                            }
                        }
                    }
                }
            }

            & + .item {
                margin-left: 10px;
            }
        }

        &.single-option {
            .item {
                .form-control {
                    position: absolute;
                    width: 160px;
                    left: 8px;
                    top: 5px;
                    z-index: 1;
                }
            }
        }
    }
}

.ui-state-default {
    padding: 0;
    margin: 0;

    &::before {
        display: none;
    }

    .exercise {
        margin-bottom: 9px;

        .number {
            cursor: move;
        }
    }

    .rest,
    .note {
        cursor: move;
    }
}

/*-----------ESHOP-----------*/

.panel-eshop {
    margin: 0 0 20px;

    .panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        height: 100%;
    }

    .panel-body {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        &::after {
            content: '';
            display: block;
            padding-bottom: 70%;
            background-color: rgba(black, 0.3);
            transition: 0.25s background-color;
            will-change: background-color;
        }

        h2 {
            position: absolute;
            bottom: 20px;
            left: 20px;
            margin: 0;
            padding-right: 90px;
            font-family: 'Rubik', sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            color: #fff;

            @media only screen and (min-width: 768px) {
                font-size: 24px;
            }
        }
    }

    &:hover .panel-body::after {
        background-color: rgba(black, 0.5);
    }

    h5 {
        .bold;
        font-size: 18px;
        color: @primary;
        margin: 13px 25px;
    }

    .trainer {
        display: flex;
        align-items: flex-start;

        .image-wrapper {
            flex: 0 0 58px;
            max-width: 58px;
            width: 58px;
            height: 58px;
            overflow: hidden;
            .border-radius(50%);
            .inline-block;
            margin-right: 12px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .info {
            .inline-block;
            width: ~"-moz-calc(100% - 60px)";
            width: ~"-webkit-calc(100% - 60px)";
            width: ~"-o-calc(100% - 60px)";
            width: ~"calc(100% - 60px)";

            h3 {
                font-weight: 600;
                font-size: 16px;
                margin: 0 0 5px;
                color: #404040;
            }

            h4 {
                font-weight: 200;
                font-size: 14px;
                color: #909090;
                margin: 0;
            }
        }
    }

    .panel-footer {
        padding: 20px;
        background-color: #fff;

        .price {
            .bold-p;
            font-size: 15px;
            color: #000;
            margin: 11px 0 0 0;
        }
    }

    .panel-footer-lg {
        .trainer {
            @media only screen and (min-width: 375px) {
                position: relative;
                top: 4px;
            }
        }

        .reviews {
            justify-content: flex-end;
        }
    }

    .tag {
        position: absolute;
        bottom: 0;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        transform: translateY(30%);
        border-radius: 50%;
        color: #fff;
        background-color: @green;
        font-size: 25px;
        line-height: 1;
        transition: 0.25s background-color;
        will-change: background-color;

        &:hover {
            background-color: darken(@green, 10%);
        }

        span {
            position: relative;
            top: 2px;
        }

        .blue-layout & {
            background-color: @blue;

            &:hover {
                background-color: darken(@blue, 10%);
            }
        }
    }

    .price-tag {
        position: absolute;
        right: 20px;
        top: 20px;
        color: white;
        display: inline-flex;
        padding: 4px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        background: #49C100;
        min-width: 75px;
        box-sizing: border-box;
        font-weight: 700;
    }
}

.panel-shop-admin {
    .panel-body {
        &::after {
            display: none;
        }
    }

    .image-wrapper {
        position: relative;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        &::after {
            content: '';
            display: block;
            padding-bottom: 70%;
            background-color: rgba(black, 0.3);
            transition: 0.25s background-color;
            will-change: background-color;
        }
    }

    .adjust-element,
    .trainer {
        margin: 0 0 15px;
    }
}

.choose-exercise {
    display: flex;
    flex-wrap: wrap;

    .col-xs-12 {
        margin: 0 0 20px;
    }

    .panel-shadow-hover,
    .panel-choose-exercise {
        height: 100%;
    }
}

.panel-choose-exercise {
    margin: 0;

    .image-16-9 {
        padding-bottom: 116px;
    }

    h5 {
        padding: 0 15px 13px 15px;
        margin: 0;
    }

    .tag {
        top: 60px;
        right: 8px;
        bottom: auto;
    }

    .panel-body {
        position: relative;
        height: 100%;

        .btn {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -39px;
            margin-top: -19px;
            z-index: 11;
            opacity: 0;
            .transition;
        }

        &::before {
            .pseudo;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            z-index: 10;
            opacity: 0;
            .transition;
            .border-radius(4px);
        }
    }

    .panel-body::after {
        display: none;
    }

    &:hover {
        .panel-body {
            .btn {
                opacity: 1;
            }

            &::before {
                opacity: 1;
            }
        }
    }
}

.eshop-header {
    //margin-bottom: 25px;
    .btn-custom-group {
        text-align: center;
        @media only screen and (min-width: 768px) {
            text-align: right;
        }
    }

    .buy {
        padding: 8px 30px;
    }

    .price {
        .bold-p;
        font-size: 20px;
        color: #000;
        margin: 6px 0 0 20px;
        .inline-block;
    }

    .rating {
        margin: -2px 0 0 10px;
    }
}

/*-----------MESSENGER------------*/

.contacts {
    .panel-heading {
        .search-input {
            width: 100%;

            .form-control {
                width: 100%;
            }
        }
    }

    .panel-body {
        .contact {
            padding: 15px;
            cursor: pointer;
            border-bottom: 1px solid #e4e6e7;
            display: block;
            color: @primary;
            text-decoration: none;
            .transition;

            .image-container {
                width: 50px;
                .inline-block;
                position: relative;

                .image-wrapper {
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    .border-radius(100%);

                    img {
                        width: 100%;
                    }

                    &.no-image {
                        border: 1px solid #e4e6e7;
                    }
                }

                .badge {
                    position: absolute;
                    background-color: @orange;
                    right: 6px;
                    bottom: -2px;
                }
            }

            &.active, &:hover {
                background-color: #f6f8f9;
                .transition;
            }

            a {
                text-decoration: none;

                h2 {
                    color: @primary;
                }
            }
        }

        h2 {
            .inline-block;
            .regular;
            font-size: 14px;
            margin: 11px 0 0 0;
        }
    }
}

.messages {
    .conversation {
        padding: 20px 13px;
        border-left: 2px solid #fff;
        position: relative;
        border-bottom: 1px solid #e4e6e7;
        .transition;

        .image-container {
            width: 60px;
            .inline-block;

            .image-wrapper {
                width: 40px;
                height: 40px;
                overflow: hidden;
                .border-radius(100%);

                img {
                    width: 100%;
                }

                &.no-image {
                    border: 1px solid #e4e6e7;
                }
            }
        }

        .message {
            .inline-block;
            width: ~"-moz-calc(100% - 70px)";
            width: ~"-webkit-calc(100% - 70px)";
            width: ~"-o-calc(100% - 70px)";
            width: ~"calc(100% - 70px)";

            h2 {
                .bold;
                font-size: 16px;
                margin-bottom: 0;
            }

            .date {
                color: #b9bbbd;
            }

            p {
                margin-bottom: 0;
            }
        }

        &:hover {
            border-left: 2px solid @green;
            background-color: #f6f8f9;
            .transition;

            .btn-toolbar {
                opacity: 1;
                .transition;
            }
        }

        .btn-toolbar {
            position: absolute;
            right: 20px;
            top: 20px;
            opacity: 0;
            .transition;
        }

        .textarea-toggle {
            display: none;
        }

        textarea {
            &.form-control {
                min-height: 66px;
                max-height: 240px;
                margin-bottom: 15px;
            }
        }

        input[type="submit"] {
            @media only screen and (max-width: 1199px) {
                width: 100%;
            }
        }
    }

    .conversation--current {
        background-color: #e4e6e7;
        border-left-color: #e4e6e7;
    }
}

.conv-user-info {
    display: flex;

    @media only screen and (min-width: 1200px) {
        margin-bottom: 5px;
    }
}

/*----------MODALS-----------*/

.modal {
    overflow-y: auto;
    z-index: 1000000;
}

.modal-content {
    .border-radius(3px);
    border: 0;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
}

.modal-title {
    .regular-p;
    font-size: 23px;
    color: @primary;
}

.modal-header {
    padding: 20px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);

    .close {
        margin-top: 5px;
        opacity: 1;

        .icon {
            font-size: 23px;
            color: @green;
        }

        &:focus, &:active {
            outline: none;
        }
    }
}

.modal-body {
    background-color: #f0f3f6;
    .border-radius-extended(0, 0, 3px, 3px);
    padding: 25px;

    b, strong {
        font-weight: 700;
    }

    textarea {
        &.form-control {
            margin-bottom: 20px;
        }
    }

    h2 {
        .bold;
        text-transform: uppercase;
        font-size: 17px;
    }

    label {
        margin-bottom: 10px;
        .semibold;
    }

    .fstElement {
        width: 100%;
        margin: 0 0 15px 0;
    }

    .fstControls {
        width: auto;
    }

    .tab-select {
        .panel {
            .panel-heading {
                background-color: #fff;
            }
        }
    }

    .image-wrapper {
        margin-bottom: 15px;

        img {
            width: 100%;
        }
    }

    .modal-slim-body & {
        padding: 0;

        .btn-group {
            padding: 25px;
        }
    }
}

//modal option modal (group calendar)
.modal-option {
    .user-events {
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .head-row {
        margin-bottom: 5px;
    }

    .user-event-row {
        padding: 5px 0;
    }

    .user-event-row:before {
        display: none;
    }

    .green {
        color: green;
    }

    .red {
        color: red;
    }

    li.row {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    li.row:last-of-type {
        border-bottom: none;
    }
}

//new-exercise modal
.modal-new-exercise {
    .modal-body {
        padding-left: 10px;
        padding-right: 10px;

        h2 {
            margin-bottom: 10px;
        }

        textarea {
            &.form-control {
                min-height: 380px;
            }
        }
    }

    .pad-container {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.modal-add {
    .modal-body {
        padding-left: 10px;
        padding-right: 10px;

        h2 {
            margin-bottom: 10px;
        }

        textarea {
            &.form-control {
                min-height: 120px;
            }
        }
    }

    .pad-container {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.modal-upload-exercise {
    .modal-body {
        min-height: 530px;
        max-height: 530px;
        padding: 0;

        .scroll-box {
            overflow: hidden;

            &.scroll-box-panels {
                height: 530px;
                padding: 20px;
            }

            &.scroll-box-package {
                height: 340px;
                border-top: 2px solid #d6dee3;
                border-bottom: 2px solid #d6dee3;
                margin-top: 15px;
                margin-bottom: 22px;
            }
        }

        .panel-eshop {
            .image-wrapper {
                margin-bottom: 10px;

                .tag {
                    bottom: -8px;
                    right: 15px;
                    transform: translateY(0%);

                    .icon {
                        margin-left: 0;
                    }
                }
            }

            h5 {
                margin: 10px 15px;
            }

            .trainer {
                margin: 0 15px 10px 15px;
            }

            .panel-footer {
                padding: 10px 15px;

                .btn {
                    float: right;
                }

                h6 {
                    .semibold;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: @primary;
                    margin: 11px 0 0 0;
                }
            }
        }

        .panel-shadow-hover {
            .border-radius(3px);
        }

        .package-detail {
            background-color: #e8ecef;
            padding: 20px;
            height: 530px;

            h3 {
                font-size: 24px;
                .regular;
                margin-bottom: 15px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .trainer {
                .italic;
                font-size: 14px;

                .icon {
                    color: @blue;
                    margin-right: 5px;
                }
            }

            .price {
                text-align: right;
                font-size: 14px;
            }

            .exercise-list {
                .item {
                    input {
                        display: none;

                        &:checked {
                            & + label {
                                &::after {
                                    transform: scale(1);
                                }
                            }
                        }
                    }

                    label {
                        display: block;
                        margin-bottom: 0;
                        height: 50px;
                        padding: 10px 15px 10px 45px;
                        position: relative;
                        cursor: pointer;

                        span {
                            font-size: 16px;
                            .light;
                            .inline-block;
                            margin-top: 3px;
                            max-width: 85%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        .play {
                            padding: 6px 9px 2px 9px;

                            .icon {
                                font-size: 10px;
                            }
                        }

                        &::before {
                            .pseudo;
                            width: 20px;
                            height: 20px;
                            background-color: #fff;
                            .border-radius(3px);
                            left: 10px;
                            top: 50%;
                            margin-top: -10px;
                            border-left: 1px solid #e3e3e3;
                            border-top: 1px solid #e3e3e3;
                        }

                        &::after {
                            .pseudo;
                            width: 13px;
                            height: 9px;
                            .image('../../images/svg/check-blue.svg');
                            left: 14px;
                            top: 50%;
                            margin-top: -4px;
                            .transition(200ms);
                            transform: scale(0);
                        }
                    }

                    &.all {
                        label {
                            height: 40px;
                            background-color: #d6dee3;

                            span {
                                display: block;
                                .bold;
                                margin-top: -1px;
                                font-size: 14px;

                                &.text-right {
                                    max-width: 100%;
                                }
                            }
                        }
                    }

                    & + .item {
                        border-top: 1px solid #d6dee3;
                    }

                    &.added {
                        label {
                            pointer-events: none;

                            span {
                                &:last-child {
                                    .semibolditalic;
                                    float: right;
                                    font-size: 14px;
                                }
                            }

                            &::before {
                                background-color: #d6dee3;
                                border-color: #bfc6ca;
                            }

                            &::after {
                                .image('../../images/svg/check-grey.svg');
                            }
                        }
                    }
                }
            }

            .if-empty {
                width: 62%;
                margin: auto;
                padding-top: 150px;
                position: relative;
                margin-top: 125px;
                font-size: 16px;
                text-align: center;
                .italic;

                b {
                    .bolditalic;
                }

                &::before {
                    .pseudo;
                    .image('../../images/svg/box-circle.svg');
                    width: 134px;
                    height: 134px;
                    top: 0;
                    left: 50%;
                    margin-left: -67px;
                }
            }
        }
    }
}

.modal-delete {
    .modal-content {
        background-color: #de1c0f;
    }

    .modal-header {
        border-bottom: 0;

        .modal-title {
            color: #fff;
        }

        .close {
            text-shadow: none;

            .icon {
                color: #860d05 !important;
            }
        }
    }

    .modal-body {
        background-color: #ef413f;
        color: #fff;
        position: relative;

        .bin-icon {
            width: 212px;
            height: 202px;
            position: absolute;
            .image('../../images/svg/bin.svg');
            top: -94px;
            left: 280px;
        }

        p {
            width: 200px;
            .semibolditalic;
            padding-top: 20px;
            line-height: 1.2;
            margin-bottom: 50px;
        }

        .btn {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c71509+0,c71509+100 */
            background: #c71509; /* Old browsers */
            background: -moz-linear-gradient(top, #c71509 0%, #c71509 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #c71509 0%, #c71509 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #c71509 0%, #c71509 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c71509', endColorstr='#c71509',GradientType=0); /* IE6-9 */

            &:hover {
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a50808+0,a50808+100 */
                background: #a50808; /* Old browsers */
                background: -moz-linear-gradient(top, #a50808 0%, #a50808 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, #a50808 0%, #a50808 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, #a50808 0%, #a50808 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a50808', endColorstr='#a50808',GradientType=0); /* IE6-9 */
            }
        }
    }
}

.modal-invite {
    .grey-box {
        background-color: #e8ecef;
        padding: 180px 35px 40px 35px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 25px;
        position: relative;

        p {
            .italic;
            font-size: 16px;

            b {
                .bolditalic;
            }
        }

        .person-icon {
            position: absolute;
            width: 134px;
            height: 134px;
            left: 50%;
            margin-left: -67px;
            top: 26px;
            .image('../../images/svg/person-circle.svg');
        }
    }
}

.modal-choose-activity {
    .btn {
        width: 100%;
        padding: 78px 0 15px 0;
        position: relative;
        font-size: 14px;

        &::before {
            .pseudo;
        }

        &.training {
            &::before {
                width: 44px;
                height: 42px;
                .image('../../images/svg/training-grey.svg');
                left: 50%;
                margin-left: -22px;
                top: 23px;
            }
        }

        &.activity {
            &::before {
                width: 45px;
                height: 41px;
                .image('../../images/svg/activity-grey.svg');
                left: 50%;
                margin-left: -22px;
                top: 25px;
            }
        }

        &.diary {
            &::before {
                width: 32px;
                height: 44px;
                .image('../../images/svg/diary-grey.svg');
                left: 50%;
                margin-left: -16px;
                top: 18px;
            }
        }
    }
}

/*-----------MODAL-RATING---------*/

.br-theme-bootstrap-stars .br-widget {
    height: 40px;
    white-space: nowrap;
    margin: 10px 0 40px 0;
}

.br-theme-bootstrap-stars .br-widget a {
    font: normal normal normal 43px/1 'Glyphicons Halflings';
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    margin-right: 7px;
}

.br-theme-bootstrap-stars .br-widget a:after {
    content: '\e006';
    color: #dbdde0;
}

.br-theme-bootstrap-stars .br-widget a.br-active:after {
    color: @green;
}

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
    color: @green;
}

.br-theme-bootstrap-stars .br-widget .br-current-rating {
    .inline-block;
    .light-p;
    color: #8d8f92;
    font-size: 20px;
    margin: 10px 0 0 15px;
}

.br-theme-bootstrap-stars .br-readonly a {
    cursor: default;
}

@media print {
    .br-theme-bootstrap-stars .br-widget a:after {
        content: '\e007';
        color: black;
    }

    .br-theme-bootstrap-stars .br-widget a.br-active:after,
    .br-theme-bootstrap-stars .br-widget a.br-selected:after {
        content: '\e006';
        color: black;
    }
}

/*----------ARTICLES---------*/

.panel-articles {
    margin: 0 0 20px;

    .panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin: 0;
    }

    .panel-head {
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        transition: 0.25s opacity;
        will-change: opacity;

        &:hover {
            opacity: 0.7;
        }

        &::after {
            content: '';
            display: block;
            padding-bottom: 48%;
        }
    }

    .panel-body {
        padding: 18px 18px 0;
        min-height: 210px;

        .image-wrapper {
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .article-preview {
            h2 {
                margin: 0;
                font-family: 'Rubik', sans-serif;
                font-weight: 700;
                font-size: 18px;
                line-height: 1.29;
                color: #1D1D1D;

                @media only screen and (min-width: 768px) {
                    font-size: 24px;
                }

                a {
                    color: #1D1D1D;
                    text-decoration: none;
                    transition: 0.25s color;
                    will-change: color;
                }

                a:hover {
                    color: @green;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 50px;
                    height: 2px;
                    margin: 17px 0;
                    background-color: @green;
                }
            }

            .blue-layout & {
                a:hover {
                    color: @blue;
                }

                &::after {
                    background-color: @blue;
                }
            }

            p {
                margin: 0;
                font-weight: 200;
                font-size: 14px;
                line-height: 1.625;
                color: #909090;

                @media only screen and (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }
    }

    .date {
        font-size: 14px;
        font-weight: 600;
        color: #909090;
        text-align: right;
    }

    .panel-footer {
        background-color: #fff;
        @media only screen and (min-width: 768px) {
            padding: 30px 20px;
        }

        .trainer {
            display: flex;
            align-items: center;

            .image-wrapper {
                flex: 0 0 58px;
                width: 58px;
                height: 58px;
                overflow: hidden;
                .border-radius(50%);
                .inline-block;
                margin-right: 12px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }

            .info {
                .inline-block;
                width: ~"-moz-calc(100% - 60px)";
                width: ~"-webkit-calc(100% - 60px)";
                width: ~"-o-calc(100% - 60px)";
                width: ~"calc(100% - 60px)";

                h3 {
                    font-weight: 600;
                    font-size: 16px;
                    margin: 0;
                    color: #404040;
                }

                h4 {
                    font-weight: 200;
                    font-size: 14px;
                    color: #909090;
                    margin: 0;
                }
            }
        }
    }
}

.article-date {
    width: 100px;
    height: 100px;
    .icon(0 0);
    box-sizing: border-box;
    padding: 30px 0 0 0;
    text-align: center;
    .bold;
    font-size: 22px;
    color: #1e1e1e;
    line-height: 1;
    .inline-block;
    margin-right: 20px;

    span {
        display: block;
        .regular;
        font-size: 16px;
        color: #9fa1a4;
        margin: 8px 0 0 0;
    }
}

.article-detail {
    .main-image-wrapper {
        margin-bottom: 24px;
        @media only screen and (min-width: 1280px) {
            margin-bottom: 48px;
        }

        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
}

.article-detail-content {
    overflow: auto;

    h1 {
        margin: 0 0 20px;
        font-family: 'Rubik', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.4;
        color: #1D1D1D;

        @media only screen and (min-width: 1280px) {
            font-size: 30px;
        }
    }

    h2 {
        margin: 0 0 20px;
        font-family: 'Rubik', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
        color: #1D1D1D;

        @media only screen and (min-width: 1280px) {
            font-size: 24px;
        }
    }

    h3 {
        margin: 0 0 16px;
        font-family: 'Rubik', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
        color: #1D1D1D;

        @media only screen and (min-width: 1280px) {
            font-size: 20px;
        }
    }

    p, ul, ol {

        &, li {
            font-size: 14px;
            line-height: 1.875;
            color: #707070;

            @media only screen and (min-width: 1280px) {
                font-size: 16px;
            }
        }


        + h1,
        + h2,
        + h3 {
            margin-top: 30px;
        }
    }

    p {
        margin: 0 0 20px;
    }

    ul li::before {
        width: 7px;
        height: 7px;

        @media only screen and (min-width: 768px) {
            top: 11px;
        }
        @media only screen and (min-width: 1280px) {
            top: 13px;
        }
    }

    ol li {
        @media only screen and (min-width: 768px) {
            padding-left: 25px;
        }
    }

    ol li::before {
        content: counter(item);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        padding: 3px 0 0;
        border-radius: 50%;

        font-weight: 600;
        font-size: 14px;
        color: #fff;
        background-color: @green;

        @media only screen and (min-width: 768px) {
            top: 3px;
            width: 34px;
            height: 34px;
            font-size: 16px;
        }
    }

    strong {
        font-weight: 700;
        color: #1D1D1D;
    }

    img {
        display: block;
        height: auto !important;
        max-width: 100% !important;
        margin: 0 auto 20px;
        padding: 0 20px;
    }

    table {
        margin: 0 auto 20px;
        width: 100%;

        @media only screen and (min-width: 768px) {
            margin: 0 auto 40px;
        }

        th,
        td {
            text-align: center;
            vertical-align: middle;
            padding: 20px 10px;
            font-weight: 700;
        }

        th {
            font-family: 'Rubik', sans-serif;
            font-size: 16px;
            color: #fff;
            background-color: @blue;
        }

        th:not(:last-of-type) {
            border-right: 1px solid #fff;
        }

        td {
            font-weight: 700;
            font-size: 14px;
            border-bottom: 1px solid #F5F5F5;
            color: #404040;
            background-color: #fff;
        }

        td:not(:last-of-type) {
            border-right: 1px solid #F5F5F5;
        }
    }
}

.blue-layout {
    ul li::before {
        background-color: @blue;
    }

    .signature .author-statistics .statistics {
        color: @blue;
    }
}

.share-article {
    h4 {
        .light-p;
        font-size: 14px;
        margin: 2px 20px 0 0;
        .inline-block;
    }

    .social-iframe {
        .inline-block;
        margin-right: 20px;
    }

    .panel-body {
        padding: 15px 20px 10px 20px;
    }
}

.signature {
    margin-bottom: 25px;

    @media only screen and (min-width: 768px) {
        margin-bottom: 50px;
    }

    .panel-body > .row {
        @media only screen and (min-width: 768px) {
            display: flex;
        }
    }

    h2 {
        text-align: center;
        line-height: 170px;
        position: relative;
        background-color: #f6f8f9;
        .medium-p;
        font-size: 16px;
        margin: 0;
    }

    .image-container {
        position: relative;
        margin: 0 0 20px;
        .inline-block;

        @media only screen and (min-width: 768px) {
            margin: 0 20px 0 0;
        }

        .image-wrapper {
            width: 115px;
            height: 115px;
            overflow: hidden;
            .border-radius(100%);

            img {
                width: 100%;
                margin: 0;
            }
        }

        .rating {
            position: absolute;
            z-index: 5;
            top: -6px;
            left: -6px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            border: none;
            background-color: @blue;

            font-weight: 600;
            font-size: 14px;
            line-height: 1;
            color: #fff;
        }
    }

    .author-info {
        h3 {
            margin: 0;
            font-weight: 700;
            font-size: 16px;
            color: #404040;
        }

        h4 {
            margin: 0;
            font-weight: 200;
            font-size: 14px;
            color: #909090;
        }

        p {
            margin: 0 0 15px;
            font-weight: 200;
            font-size: 13px;
            color: #909090;

            @media only screen and (min-width: 768px) {
                font-size: 16px;
            }
        }

        p:last-of-type {
            margin: 0;
        }

        span {
            font-size: 13px;
            display: block;
            color: #9fa1a4;
        }
    }

    .author-statistics {
        position: relative;
        display: flex;
        justify-content: center;
        min-height: 170px;
        height: 100%;
        text-align: center;
        background-color: #EBEBEB;

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 28px;
            bottom: 28px;
            width: 1px;
            background-color: #C8C8C8;
        }

        .statistics {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 0 0 50%;
            width: 50%;
            max-width: 50%;
            padding: 10px;
            font-family: 'Rubik', sans-serif;
            font-weight: 700;
            font-size: 24px;
            color: #4AC102;

            span {
                display: block;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                color: #404040;
            }
        }
    }
}

.signature-head {
    padding: 20px;

    @media only screen and (min-width: 768px) {
        display: flex;
        align-items: flex-start;
        padding: 20px 0 20px 20px;
    }
}

.author-info-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.author-info-add {
    text-align: right;
    font-weight: 200;
    font-size: 14px;
    color: #909090;
}

/*----------CALENDAR----------*/

#calendar {
    margin: auto;
    margin-bottom: 25px;
}

.fc-toolbar {
    &.fc-header-toolbar {
        padding: 20px 20px 20px 25px;
        background-color: #fff;
        margin: 0;
        border-top: 1px solid #e8eaed;
        border-left: 1px solid #e8eaed;
        border-right: 1px solid #e8eaed;
        .border-radius-extended(5px, 5px, 0, 0);

        h2 {
            text-transform: uppercase;
            .light-p;
            font-size: 18px;
            color: @primary;
            line-height: 1;
            margin-top: 11px;
        }
    }
}

.fc-button {
    &:focus {
        outline: none;
    }
}

.fc {
    background-color: #fff;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    .border-radius(5px);

    button {
        padding: 6px 18px;
        height: auto;
    }
}

.fc-day-header {
    background-color: #f6f8f9;
    padding: 16px 0 13px 0 !important;
    .medium-p;
    text-transform: uppercase;
    color: @primary;
    font-size: 13px;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.fc-today-button {
    background-color: #3e9f02;
    color: #fff;
    background-image: none;
    border: 1px solid #3e9f02;
}

.fc-state-default {
    &.fc-corner-right {
        .border-radius(3px);
    }

    &.fc-corner-left {
        .border-radius(3px);
    }
}

.fc-day-number {
    .medium-p;
    font-size: 16px;
    margin: 5px 5px 0 0;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
    box-sizing: border-box;
    padding: 2px 0 0 0;
    .border-radius(100%);
}

.fc-today {
    background: none !important;

    .fc-day-number {
        color: #fff;
        background-color: @green;
    }
}

.fc-more {
    padding: 3px 10px 3px 30px !important;
    position: relative;
    border: 0;
    height: 24px !important;
    font-size: 14px;
    line-height: 1.3;
    box-sizing: border-box;
    display: inline-block;
    .border-radius(12px);
    background-color: #dbdbdb;
    color: #3e3e3e !important;

    &::before {
        position: absolute;
        display: block;
        .regular;
        content: "+";
        color: #fff;
        font-size: 20px;
        width: 24px;
        height: 24px;
        left: 0;
        top: 0;
        text-align: center;
        .border-radius(100%);
        box-sizing: border-box;
        padding: 12px 0 0 0;
        background-color: #b9b9b9;
        line-height: 0;
    }
}

.fc-sat {
    background-color: #f6f8f9;
}

.fc-sun {
    background-color: #f6f8f9;
    color: #f42727;
}

.fc-event-container {
    overflow: hidden;
}

.fc-event {
    padding: 3px 10px 3px 30px !important;
    position: relative;
    border: 0;
    height: 24px !important;
    box-sizing: border-box;
    display: inline-block;
    color: #fff !important;
    .border-radius(12px);

    &::before {
        position: absolute;
        display: block;
        .regular;
        color: #fff;
        font-size: 12px;
        width: 24px;
        height: 24px;
        left: 0;
        top: 0;
        text-align: center;
        .border-radius(100%);
        box-sizing: border-box;
        padding: 4px 0 0 0;
    }

    &.event-completed {
        background-color: @green;

        &::before {
            background-color: #3da101;
        }
    }

    &.event-incomplete {
        background-color: @red;

        &::before {
            background-color: #c31515;
        }
    }

    &.type-training {
        //background-color: @green;
        &::before {
            content: "T";
            //background-color: #3da101;
        }

        &.type--group-calendar::before {
            content: "ST";
        }
    }

    &.type-activity {
        //background-color: @red;
        &::before {
            content: "A";
            //background-color: #c31515;
        }

        &.type--group-calendar::before {
            content: "SA";
        }
    }

    &.type-diary {
        background-color: @orange;
        width: 30px;
        height: 30px !important;
        .border-radius(100%);
        padding: 0 !important;
        position: absolute;
        top: 7px;

        &::before {
            content: "D";
            width: 30px;
            height: 30px;
            line-height: 30px;
            //color: @primary;
            .bold;
            padding: 0;
            font-size: 14px;
        }

        .fc-content {
            display: none;
        }

        &.event-completed, &.event-incomplete {
            background-color: @orange;

            &::before {
                background-color: transparent;
            }
        }
    }

    &.fc-event-future {
        &.type-activity, &.type-training {
            background-color: #eeeeee;
            color: @primary !important;

            &::before {
                background-color: #d2d0d0;
                //color: @primary;
            }
        }
    }

    &.fc-event-today {
        &.type-activity, &.type-training {
            &.event-incomplete {
                background-color: #eeeeee;
                color: @primary !important;

                &::before {
                    background-color: #d2d0d0;
                    //color: @primary;
                }
            }
        }
    }
}

.fc-more-popover {
    .fc-event {
        vertical-align: top;

        &.type-diary {
            position: relative;
            top: auto;
            width: 24px;
            height: 24px !important;

            &::before {
                width: 24px;
                height: 24px;
                line-height: 24px;
            }
        }
    }
}

.fc-list-item {
    &.event-incomplete {
        .fc-list-item-title {
            a {
                background-color: @red;

                &::before {
                    background-color: #c31515;
                }
            }
        }
    }

    &.event-completed {
        .fc-list-item-title {
            a {
                background-color: @green;

                &::before {
                    background-color: #3da101;
                }
            }
        }
    }

    .fc-list-item-title {
        a {
            position: relative;
            color: #fff;
            .inline-block;
            padding: 3px 10px 3px 35px;
            .border-radius(20px);

            &::before {
                display: block;
                position: absolute;
                .regular;
                color: #fff;
                font-size: 14px;
                width: 30px;
                height: 30px;
                left: 0;
                top: 0;
                text-align: center;
                .border-radius(100%);
                box-sizing: border-box;
                padding: 4px 0 0 0;
            }
        }
    }

    &.type-training {
        .fc-list-item-title {
            a {
                //background-color: @green;
                &::before {
                    content: "T";
                    //background-color: #3da101;
                }
            }
        }
    }

    &.type-activity {
        .fc-list-item-title {
            a {
                //background-color: @red;
                &::before {
                    content: "A";
                    //background-color: #c31515;
                }
            }
        }
    }

    &.type-diary {
        .fc-list-item-title {
            a {
                background-color: @orange;

                &::before {
                    content: "D";
                    background-color: #da5a32;
                }
            }
        }
    }

    &.fc-event-future {
        &.type-activity, &.type-training {
            .fc-list-item-title {
                a {
                    background-color: #eeeeee;
                    color: @primary !important;

                    &::before {
                        background-color: #d2d0d0;
                        //color: @primary;
                    }
                }
            }
        }
    }

    &.fc-event-today {
        &.type-activity, &.type-training {
            &.event-incomplete {
                .fc-list-item-title {
                    a {
                        background-color: #eeeeee;
                        color: @primary !important;

                        &::before {
                            background-color: #d2d0d0;
                            //color: @primary;
                        }
                    }
                }
            }
        }
    }
}

.fc-ltr {
    .fc-list-item-marker {
        display: none;
    }
}

.fc-list-table {
    td {
        padding-left: 8px;
    }
}

// Online program calendar
.online-program {
    &[v-cloak] {
        display: none;
    }

    .fc-event-container {
        padding-top: 10px;
    }

    td.fc-day {
        @media (max-width: 767px) {
            padding-top: 15px !important;
            height: auto !important;
            min-height: 55px;
        }
    }

    .fc-day .fc-event-container:first-of-type {
        padding-top: 34px;
    }

    .fc-event {
        pointer-events: auto;
        min-height: 24px !important;
        min-width: 24px !important;
        display: inline-block;

        &:before {
            display: inline-block;
        }
    }
}


.header-text {
    .inline-block;
    padding: 7px 0 0 30px;
    .semibold;
    font-size: 14px;

    .large {
        .bold;
        font-size: 22px;
        padding: 0 0 0 5px;
        line-height: 1;
    }

    .red {
        color: @red;
    }

    .green {
        color: @green;
    }

    .small-grey {
        color: #939699;
        .regular-p;
        font-size: 13px;
        padding: 0 0 0 5px;
    }
}

.header-text-container {
    text-align: right;
    .inline-block;
    float: right;
}

.calendar-legend {
    margin-bottom: 25px;

    h2 {
        margin-bottom: 5px;
    }

    .item {
        position: relative;
        padding: 10px 0 10px 40px;

        &::before {
            .pseudo;
            width: 24px;
            height: 24px;
            .border-radius(100%);
            left: 0;
            top: 50%;
            margin-top: -12px;
        }

        p {
            margin-bottom: 0;
        }

        &.green {
            &::before {
                background-color: @green;
            }
        }

        &.red {
            &::before {
                background-color: @red;
            }
        }

        &.grey {
            &::before {
                background-color: #d2d0d0;
            }
        }

        &.orange {
            &::before {
                background-color: @orange;
            }
        }
    }
}

.calendar-switch {
    display: none;

    .btn {
        width: 33.3333%;
    }
}

.calendar-agenda {
    display: none;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;

    ul {
        margin-bottom: 20px;

        li {
            padding: 8px;
            margin: 0;
            background-color: #fff;
            border-bottom: 1px solid #ddd;

            &::before {
                display: none;
            }

            &.calendar-agenda__day {
                background-color: #eee;
                font-weight: bold;
            }
        }
    }

    .calendar-agenda__item {
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        color: #000;
        padding: 2px 15px 3px 35px;
        border-radius: 15px;
        position: relative;
        text-decoration: none;
        background-color: #eeeeee;
        .transition(200ms);

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            text-align: center;
            padding-top: 3px;
            background-color: #d2d0d0;
            color: #fff;
            .transition(200ms);
            .regular;
        }

        &:hover {
            color: #fff;
        }

        & + .activity-toolbar__item {
            margin-left: 5px;
        }
    }

    .calendar-agenda__item--training {
        &::before {
            content: "T";
        }
    }

    .calendar-agenda__item--green {
        background-color: @green;
        color: #fff;

        &::before {
            background-color: #3da101;
        }

        &:hover {
            background-color: darken(@green, 10%);

            &::before {
                background-color: darken(#3da101, 10%);
            }
        }
    }

    .calendar-agenda__item--activity {
        &::before {
            content: "A";
        }
    }

    .calendar-agenda__item--red {
        background-color: @red;
        color: #fff;

        &::before {
            background-color: #c31515;
        }

        &:hover {
            background-color: darken(@red, 10%);

            &::before {
                background-color: darken(#c31515, 10%);
            }
        }
    }

    .calendar-agenda__item--diary {
        background-color: @orange;
        color: #fff;

        &::before {
            background-color: #da5a32;
            content: "D";
        }

        &:hover {
            background-color: darken(@orange, 10%);

            &::before {
                background-color: darken(#da5a32, 10%);
            }
        }
    }

}

.calendar-statistics {
    margin-bottom: 20px;

    .box {
        height: 130px;
        background-color: #e8ebf0;
        padding: 25px 0 25px 35px;
        .border-radius(3px);
        position: relative;

        &.user {
            background-color: #e0e4ea;
            padding-left: 95px;
            margin-left: 35px;

            .main-text {
                margin-top: 9px;
                max-width: 160px;

                h2 {
                    margin-bottom: 4px;
                }
            }
        }

        .image-wrapper {
            width: 100px;
            height: 100px;
            position: absolute;
            left: -35px;
            top: 15px;
            .border-radius(100%);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .main-text {
            .inline-block;
            margin-top: 21px;

            h2 {
                font-size: 20px;
                .bold;
                margin-bottom: 0;
                line-height: 1;
            }

            h3 {
                font-size: 14px;
                color: #898b8c;
                .italic;
                margin-bottom: 0;
            }
        }

        .user-info {
            .inline-block;
            margin-left: 30px;
            padding-left: 30px;
            border-left: 1px solid #cad3db;
            margin-top: 7px;

            span {
                display: block;
                font-size: 14px;
                color: #898b8c;
            }
        }

        .bars {
            margin-left: 80px;
            .inline-block;

            .bar-container {
                width: 40px;
                height: 80px;
                background-color: #fff;
                .border-radius(2px);
                .inline-block;
                position: relative;
                margin-right: 8px;
                .transition;

                &:last-child {
                    margin-right: 0;
                }

                .bar {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 0;
                    background-color: @green;
                    .border-radius(2px);
                }

                span {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 5px;
                    text-align: center;
                    color: #fff;
                    .bold;
                    font-size: 14px;
                }

                &.empty {
                    span {
                        color: #3f4143;
                    }
                }
            }
        }
    }
}

.if-empty-calendar, .if-empty-e-learning {
    width: 780px;
    margin: auto;
    padding: 260px 150px 100px 150px;
    position: relative;
    margin-top: 100px;
    font-size: 16px;
    text-align: center;
    background-color: #e0e4ea;
    .italic;
    .border-radius(3px);

    b {
        .bolditalic;
    }

    &::before {
        .pseudo;
        .image('../../images/svg/bicycle-circle.svg');
        width: 134px;
        height: 134px;
        top: 100px;
        left: 50%;
        margin-left: -67px;
    }
}

.if-empty-e-learning {
    padding: 100px 140px 100px 140px;

    &::before {
        display: none;
    }

    img {
        width: 134px;
        height: 134px;
        margin: 0 0 30px 0;
    }
}

.if-empty-calendar,
.choose-activity-content,
#frm-calendarActivityForm,
#frm-onlineProgramForm-eventTrainingForm,
.fstElement-currency,
.modal {
    .fstElement {
        background-color: #fff;
    }
}

.choose-activity-content {
    .fstElement {
        width: 100%;
    }
}

.fstElement-currency {
    padding-left: 10px;

    .fstElement {
        width: 100px;
    }
}

.css-video-image-16-9 {
    padding-bottom: 55%;
}

/*---------DATETIMEPICKER---------*/

.bootstrap-datetimepicker-widget {
    ul {
        li {
            margin: 0;
            padding: 0;

            &::before {
                display: none;
            }
        }
    }

    table {
        td {
            &.active {
                background-color: @green;

                &:hover {
                    background-color: @green;
                }
            }

            &.today {
                &::before {
                    border-bottom-color: @green;
                }
            }
        }
    }
}

/*-------------TRAINERS-MAP--------------*/

#map {
    width: 100%;
    min-height: 100%;
    padding-bottom: 40%;
}

.map-trainer-info {
    width: 380px;
    margin: 0;
    display: block;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
}

/*--------INPUTFILE-------*/

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

/*-----------BOOTSTRAP-AUTOCOLUMNS------------*/

.col-xs-auto, .col-xs1-auto, .col-sm-auto, .col-md-auto, .col-lg-auto,
.col-xs-auto-right, .col-xs1-auto-right, .col-sm-auto-right, .col-md-auto-right, .col-lg-auto-right,
.col-middle {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.col-middle {
    display: table;
}

.col-xs-auto {
    float: left;
}

.col-xs-auto-right {
    float: right;
}

@media (min-width: 480px) {
    .col-xs1-auto {
        float: left;
    }

    .col-xs1-auto-right {
        float: right;
    }
}

@media (min-width: 768px) {
    .col-sm-auto {
        float: left;
    }

    .col-sm-auto-right {
        float: right;
    }
}

@media (min-width: 992px) {
    .col-md-auto {
        float: left;
    }

    .col-md-auto-right {
        float: right;
    }
}

@media (min-width: 1200px) {
    .col-lg-auto {
        float: left;
    }

    .col-lg-auto-right {
        float: right;
    }
}

/*------------SUPERADMIN------------*/

.superadmin-statistics {
    line-height: 0;
    font-size: 0;

    .item {
        .inline-block;
        width: 50%;
        box-sizing: border-box;
        margin: 20px 0 15px 0;
        text-align: center;

        &:first-child {
            border-right: 2px solid #e4e6e7;
        }

        .name {
            font-size: 15px;
            .semibold;
            color: @primary;
            line-height: 1.4;
            margin-bottom: 10px;
            display: block;
        }

        .value {
            color: @orange;
            font-size: 22px;
            .bold-p;
            line-height: 1.4;
            display: block;

            span {
                .regular-p;
                font-size: 15px;
            }
        }
    }
}

/*-------------NESTED-SORTABLE---------------*/

.sortable {
    margin: 0;
    background-color: #f6f8f9;

    li {
        margin: 0;
        padding: 0;

        &::before {
            display: none;
        }
    }

    .item {
        line-height: 38px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 10px 20px;
        border-bottom: 1px solid #e4e6e7;
        border-left: 2px solid #fff;
        .bold;
        font-size: 14px;
        text-transform: uppercase;
        cursor: move;
        .transition;

        &:hover {
            background-color: #f6f8f9;
            border-left: 2px solid @orange;
            .transition;

            .btn-toolbar {
                opacity: 1;
                .transition;
            }
        }

        .btn-toolbar {
            opacity: 0;
            .transition;
        }
    }
}

.mjs-nestedSortable-branch {
    ol {
        li {
            .item {
                padding: 10px 20px 10px 50px;
                text-transform: none;

                &::before {
                    position: absolute;
                    content: "\74";
                    display: block;
                    left: 18px;
                    top: 20px;
                    font-size: 18px;
                    font-family: "treneoicons" !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
        }
    }
}

/*----------ACCOUNT--------*/

.account {
    .section {
        border-bottom: 1px solid #e4e6e7;
        padding: 20px;

        h4, h4 label {
            margin: 0;
            line-height: 38px;
            .bold;
            font-size: 14px;
            color: @primary;
        }

        &:last-child {
            border: 0;
        }

        .password {
            span {
                font-size: 30px;
                line-height: 1;
            }
        }

        p {
            margin: 0;
            margin-top: 6px;
        }

        &.image {
            .image-wrapper {
                width: 110px;
                height: 110px;
                overflow: hidden;
                border: 1px solid #e0e2e3;
                .border-radius(100%);

                img {
                    width: 100%;
                }
            }

            h4, .upload {
                margin-top: 35px;
            }
        }
    }
}

/*-------LOADING------*/

.loading {
    width: 30px;
    height: 30px;
    background-image: url('../../images/loading.svg');
    background-size: 30px;
    margin: auto;
}

.modal-loading {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000000000000000;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;

    .modal-dialog {
        width: 130px;
        height: 70px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -50px;
        margin-left: -50px;
        background-color: #fff;
        .border-radius(3px);

        .loading {
            margin-top: 20px;
            margin-left: 13px;
            .inline-block;
        }

        span {
            .inline-block;
            margin: 24px 0 0 13px;
        }
    }
}

/*-------ORANGE-LAYOUT-------*/

.orange-layout {
    .navbar-default .navbar-nav .logout {
        background-color: darken(@orange, 5%);
    }

    a {
        color: @orange;

        &:hover, &:focus {
            color: @orange;
        }

        &.list-group-item {
            color: #555;

            &:hover, &:focus {
                color: @orange;
                border-left: 2px solid @orange;
            }

            &.more {
                &:hover, &:focus {
                    border-left: 2px solid #fafbfb;

                    .icon {
                        color: @orange;
                    }
                }
            }
        }
    }

    .navbar-default {
        background-color: @orange;

        .navbar-nav {
            .logout {
                &:hover {
                    background-color: darken(@orange, 20%);
                }
            }
        }

        .navbar-toggle {
            &:hover {
                background-color: darken(@orange, 15%);
            }

            &:focus {
                background-color: transparent;

                &:hover {
                    background-color: darken(@orange, 15%);
                }
            }
        }
    }

    .sidebar-offcanvas {
        .list-group {
            .list-group-item {
                &.active, &:hover {
                    border-left: 0;

                    .icon {
                        color: @orange;
                    }
                }

                &:active {
                    color: @orange;
                }
            }
        }
    }

    .btn-tag {
        &.active {
            background-color: @orange !important;
        }
    }

    .modal-header {
        .close {
            .icon {
                color: @orange;
            }
        }
    }

    .bootstrap-datetimepicker-widget {
        table {
            td {
                &.active {
                    background-color: @orange;

                    &:hover {
                        background-color: @orange;
                    }
                }

                &.today {
                    &::before {
                        border-bottom-color: @orange;
                    }
                }
            }
        }
    }

    .nav-tabs {
        > li {
            &.active {
                > a {
                    color: @orange;
                }
            }

            > a {
                &:hover {
                    color: @orange;
                }
            }
        }
    }

    .trainer-list-item {
        .panel-footer {
            .statistics {
                span {
                    color: @orange;
                }
            }
        }
    }

    .paginator {
        .btn {
            &.active {
                background-color: @orange;
            }
        }
    }

    .helper-box {
        .bulb {
            background-color: @orange;
        }
    }

    .diary {
        .list-group-item {
            &:hover {
                border-left: 2px solid @orange;
            }
        }
    }

    .settings-menu {
        li {
            &.active {
                background-color: @orange;
            }
        }
    }

    .panel-eshop {
        .image-wrapper {
            .tag {
                background-color: @orange;
            }
        }
    }

    .messages {
        .conversation {
            &:hover {
                border-left: 2px solid @orange;
            }
        }
    }

    .switcher {
        .btn {
            &.active {
                background-color: @orange;
            }
        }
    }

    .create-training {
        .training-type-select {
            .item {
                input[type="radio"] {
                    &:checked {
                        & + label {
                            border-color: @orange;
                        }
                    }
                }
            }
        }
    }

    .btn-blue,
    .btn-filled {
        color: #fff;
        background-color: @orange;
        border-color: @orange;

        &:hover {
            color: #fff;
            background-color: darken(@orange, 5%);
            border-color: darken(@orange, 5%);
        }
    }

    .activities-overview .panel-footer .statistics .item span {
        color: @orange;
    }

    .menu-notifications.open .dropdown-toggle,
    .menu-notifications .dropdown-toggle:focus,
    .menu-notifications .dropdown-toggle:hover,
    .menu-notifications .dropdown-menu::before {
        background-color: darken(@orange, 5%) !important;
    }

    .panel-eshop .tag {
        background-color: @orange;
    }

    .program-header__content h2::after {
        background-color: @orange;
    }

    .main-headline .headline-icon {
        background-color: @orange;
    }

    .btn-solid-green {
        border-color: @orange;
    }

    .btn-solid-green:focus,
    .btn-solid-green:hover {
        color: #fff;
        background-color: @orange;
    }

    .video-list .item::before {
        color: @orange;
    }

    .video-list .item .icon {
        color: @orange;
    }

    .summary-detail__progress span {
        background-color: @orange;
    }

    .panel-articles .panel-body .article-preview h2::after {
        background-color: @orange;
    }

    .panel-articles .panel-body .article-preview h2 a:hover {
        color: @orange;
    }

    .panel-library .image-container .tag {
        background-color: @orange;
    }
}

/*-------BLUE-LAYOUT-------*/

.blue-layout {
    a {
        color: @blue;

        &:hover, &:focus {
            color: @blue;
        }

        &.list-group-item {
            color: #555;

            &:hover, &:focus {
                color: @blue;
                border-left: 2px solid @blue;
            }

            &.more {
                &:hover, &:focus {
                    border-left: 2px solid #fafbfb;

                    .icon {
                        color: @blue;
                    }
                }
            }
        }
    }

    .navbar-default {
        background-color: @blue;

        .navbar-nav {
            .logout {
                background-color: darken(@blue, 10%);

                &:hover {
                    background-color: darken(@blue, 20%);
                }
            }
        }

        .navbar-toggle {
            &:hover {
                background-color: darken(@blue, 15%);
            }

            &:focus {
                background-color: transparent;

                &:hover {
                    background-color: darken(@blue, 15%);
                }
            }
        }
    }

    .sidebar-offcanvas {
        .list-group {
            .list-group-item {
                &.active, &:hover {
                    border-left: 0;

                    .icon {
                        color: @blue;
                    }
                }

                &:active {
                    color: @blue;

                    .icon {
                        color: @blue;
                    }
                }
            }
        }
    }

    .btn-tag {
        &.active {
            background-color: @blue !important;
        }
    }

    .modal-header {
        .close {
            .icon {
                color: @blue;
            }
        }
    }

    .bootstrap-datetimepicker-widget {
        table {
            td {
                &.active {
                    background-color: @blue;

                    &:hover {
                        background-color: @blue;
                    }
                }

                &.today {
                    &::before {
                        border-bottom-color: @blue;
                    }
                }
            }
        }
    }

    .nav-tabs {
        > li {
            &.active {
                > a {
                    color: @blue;
                }
            }

            > a {
                &:hover {
                    color: @blue;
                }
            }
        }
    }

    .trainer-list-item {
        .panel-footer {
            .statistics {
                span {
                    color: @blue;
                }
            }
        }
    }

    .paginator {
        .btn {
            &.active {
                background-color: @blue;
            }
        }
    }

    .helper-box {
        .bulb {
            background-color: @blue;
        }
    }

    .diary {
        .list-group-item {
            &:hover {
                border-left: 2px solid @blue;
            }
        }
    }

    .settings-menu {
        li {
            &.active {
                background-color: @blue;
            }
        }
    }

    .messages {
        .conversation {
            &:hover {
                border-left: 2px solid @blue;
            }
        }
    }

    .switcher {
        .btn {
            &.active {
                background-color: @blue;
            }
        }
    }

    .trainer-thumbs {
        .trainer-thumb {
            &.add-trainer {
                &:hover {
                    background-color: @blue;
                }
            }
        }
    }

    .activities-overview {
        .panel-body {
            .nav-tabs {
                > li {
                    &.active {
                        > a {
                            color: @blue;
                            border-color: @blue;
                        }
                    }
                }
            }

            .bars {
                .bar-container {
                    .bar {
                        background-color: @blue;
                    }
                }
            }
        }

        .panel-footer {
            .statistics {
                .item {
                    span {
                        color: @blue;
                    }
                }
            }
        }
    }

    .panel-library {
        .image-container {
            .tag {
                background-color: @blue;
            }
        }
    }

    .fc-today {
        .fc-day-number {
            background-color: @blue;
        }
    }

    .fc-today-button {
        background-color: @blue;
        border-color: @blue;
    }

    .calendar-statistics {
        .box {
            .bars {
                .bar-container {
                    .bar {
                        background-color: @blue;
                    }
                }
            }
        }
    }

    .create-training {
        .training-type-select {
            .item {
                input[type="radio"] {
                    &:checked {
                        & + label {
                            border-color: @blue;
                        }
                    }
                }
            }
        }
    }

    .panel-library .image-container .tag {
        background-color: @blue;
    }
}

/*----------HELPER----------*/

.helper-box {
    background-color: #e0e3e7;
    padding: 55px 0 45px 0;
    text-align: center;
    position: relative;
    margin: 50px 0 25px 0;
    .border-radius(5px);

    .close {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .bulb {
        position: absolute;
        width: 70px;
        height: 70px;
        background-color: @green;
        .border-radius(100%);
        color: #fff;
        .medium-p;
        font-size: 12px;
        text-transform: uppercase;
        top: -35px;
        left: 50%;
        margin-left: -35px;

        .icon {
            display: block;
            font-size: 36px;
            line-height: 0;
            margin-top: 11px;
        }

        .helper-text {
            display: block;
            vertical-align: top;
            //margin-top: 9px;
        }
    }

    p {
        width: 660px;
        margin: auto;
    }
}

/*--------------SHOW-MORE-FEATURE---------------*/

.show-more-container {
    display: inline;

    .show-more-text {
        display: none;
        margin: 16px 9px 10px 0;
        text-align: left;
        float: right;
        text-transform: uppercase;

        span {
            float: right;
            margin-top: 6px;
            margin-left: 10px;
            line-height: 1;
            .transition(200ms);

            &.opened {
                -ms-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                margin-top: 0;
            }
        }

        &.filter-standalone {
            float: none;
            text-align: right;
        }
    }

    .hidden-actions {

    }
}

/*--------REGISTRATION--------*/

.registration-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    position: relative;

    @media only screen and (min-width: 1024px) {
        padding-left: 0;
    }

    .left-bg {
        background: url("/images/registration-bg.jpg") center center no-repeat;
        background-size: cover;
        padding-bottom: 100%;
        display: none;

        @media only screen and (min-width: 1024px) {
            max-width: 39%;
            flex: 1 1 39%;
            padding-bottom: 85%;
            display: block;
        }

        @media only screen and (min-width: 1441px) {
            max-width: 41%;
            flex: 1 1 41%;
            padding-bottom: 65%;
        }
    }

    .registration-form {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        flex: 1 1 100%;

        @media only screen and (min-width: 1024px) {
            max-width: 741px;
            flex: 1 1 61%;
        }
    }

    .form-wrapper {
        margin-top: 35px;
        max-width: 100%;
        flex: 1 1 100%;

        @media only screen and (min-width: 1024px) {
            margin-top: 60px;
        }

        @media only screen and (min-width: 768px) {
            max-width: 75%;
            flex: 1 1 75%;
        }
    }

    .side-advantages {
        max-width: 100%;
        flex: 1 1 100%;

        @media only screen and (min-width: 768px) {
            max-width: 25%;
            flex: 1 1 25%;
        }
    }

    h1 {
        font-family: 'Rubik', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.25;
        color: #1D1D1D;
        text-align: center;
        display: block;
        max-width: 350px;
        margin: 0 auto;

        @media only screen and (min-width: 768px) {
            font-size: 36px;
        }
    }

    .two-tabs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        max-width: 400px;
        margin: 20px auto 0 auto;

        .tab {
            flex: 1 1 50%;
            max-width: 50%;
            color: #404040;
            background: #FFFFFF;
            border: 1px solid #E1E1E1;
            border-radius: 3px;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            text-decoration: none;
            padding: 10px 5px;
            display: inline-block;

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.active {
                background: #4AC102;
                border-color: #4AC102;
                color: #fff;
            }

            @media only screen and (max-width: 390px) {
                padding: 10px;
            }
        }
    }

    .change-registration-page {
        position: absolute;
        top: 20px;
        right: 17px;
        color: #000;
        font-size: 13px;
        display: inline-block;

        strong {
            font-weight: 700;
        }

        @media only screen and (max-width: 767px) {
            position: static;
            flex: 1 1 100%;
            max-width: 100%;
            margin-top: 25px;
            text-align: center;
        }
    }

    .side-advantages {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
        }
    }

    .advantage {
        width: 100%;
        max-width: 200px;
        margin-top: 20px;
        text-align: center;

        &:first-child {
            margin-top: 0;
        }

        img {
            margin: 0 auto;
            display: block;
            width: 100%;
            max-width: 80px;
            height: auto;
        }

        .text {
            margin-top: 15px;
            display: inline-block;
            color: #404040;
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.registration {
    text-align: center;

    .text-under-title {
        color: #4AC102;
        font-weight: bold;
        font-size: 14px;
        margin: 20px 0 25px 0;
    }

    h2 {
        font-size: 26px;
        text-align: center;
        line-height: 1.7;
    }

    .information-text {
        font-size: 12px;
        text-align: left;
        line-height: 1.7;
        margin-bottom: 35px;
    }

    .social-register {
        margin-bottom: 38px;

        .item {
            color: #000;
            padding: 6px 0 6px 30px;
            position: relative;
            font-size: 13px;
            line-height: 1;
            width: auto;
            cursor: pointer;
            .inline-block;

            &::before {
                .pseudo;
                width: 25px;
                height: 25px;
                top: 0;
                left: 0;
            }

            &.gplus {
                margin-right: 25px;

                &::before {
                    .icon(-109px -26px);
                }
            }

            &.fb {
                &::before {
                    .icon(-136px -26px);
                }
            }
        }

        .social-register-native-fb {
            margin-bottom: 20px;
        }

        .social-register-native-google {
            > a {
                text-decoration: none;
            }

            > a > div {
                width: 100% !important;
                max-width: 272px;
                margin: auto;
            }
        }
    }

    .separator {
        width: 100%;
        height: 1px;
        background-color: #d3dade;
        margin-bottom: 37px;

        span {
            .inline-block;
            background-color: #f0f3f6;
            margin-top: -9px;
            font-size: 13px;
            color: #404040;
            text-transform: uppercase;
            padding: 0 9px;
        }
    }

    .form-control {
        margin-bottom: 12px;
    }

    .checkbox-container {
        margin-bottom: 30px;

        input[type='checkbox'] + span {
            padding-left: 40px;
        }
    }

    .checkbox {
        text-align: left;
        padding: 17px 10px;
        margin-top: 5px;
        margin-bottom: 5px;

        @media only screen and (max-width: 430px) {
            text-align: center;
        }

        &.checkbox--v2 {
            label {
                padding-left: 17px;
            }

            span {
                min-height: 26px;
            }

            label {
                display: flex;
                align-items: center;
            }

            .checkbox-text {
                text-align: left;

                &.checkbox-text-green {
                    color: #32B3F0;
                }
            }

            .checkbox-text,
            a {
                display: inline-block;
                margin-bottom: 0;
                font-size: 13px;
            }
        }

        &.check-green {
            background: #32B3F0;
            border-radius: 15px;

            .checkbox-text,
            a {
                color: #fff;
            }
        }

    }

    .btn {
        margin-top: 30px;
        margin-bottom: 40px;
        padding: 18px 45px;
        background: #4AC102;
        font-size: 16px;
        color: #fff;
        border-radius: 2.8rem;
        text-transform: uppercase;

        &:hover
        {
            background: #378f01;
        }

        &.btn-blue {
            background: #32B3F0;

            &:hover {
                background: #109cdf;
            }
        }
    }

    .registration-terms {
        font-size: 13px;
        text-align: left;
        margin-bottom: 0;

        @media only screen and (max-width: 430px) {
            text-align: center;
        }
    }
}

/*-----------USER-STATISTICS-----------*/

.user-statistics {
    .section {
        padding: 17px 0 17px 20px;

        h4 {
            margin-bottom: 0;
            margin-top: 9px;
            font-size: 15px;
            .semibold;
        }

        .number {
            color: @green;
            margin-top: 4px;
            .bold-p;
            font-size: 22px;

            .icon {
                color: #e5e8eb;
                font-size: 32px;
                margin-top: -13px;
                line-height: 0;
                margin-right: 5px;
            }

            .number-unit {
                font-size: 11px;
                color: #93a2ad;
                .regular-p;
            }
        }

        & + .section {
            border-top: 1px solid #e4e6e7;
        }
    }

    &.choose-activity {
        text-align: center;
        background-color: #e5e8eb;
        border-radius: 3px;
        height: calc(~"100% - 20px");
        position: relative;

        .select-group {
            margin-right: 0;

            label {
                display: block;
                margin-bottom: 10px;
            }
        }

        .choose-activity-content {
            position: absolute;
            width: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }

        .choose-activity-circle {
            width: 110px;
            height: 110px;
            border-radius: 100%;
            background-color: #fff;
            margin: auto;
            margin-bottom: 25px;
            position: relative;
        }

        .choose-activity-icon {
            color: @green;
            font-size: 45px;
            position: absolute;
            top: 25px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

#statistics-content {
    padding: 60px 25px 0 25px;
}

.chart {
    position: relative;
    height: 360px;
    margin-top: 50px;

    .rows {
        position: absolute;
        width: 100%;

        .chrow {
            position: relative;
            height: 90px;
            width: 100%;
            border-bottom: 1px solid #e4e6e7;

            &:first-child {
                height: 0;
            }

            span {
                position: absolute;
                left: 8px;
                bottom: 10px;
            }
        }
    }

    .cols {
        .col {
            position: relative;
            .inline-block;
            width: 6.67%;
            margin-left: 6.67%;
            background-color: #f6f8f9;
            height: 269px;
            margin-top: 1px;

            .activity {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0;
                background-color: @green;
                .border-radius-extended(3px, 3px, 0, 0);

                .number {
                    bottom: 3px;
                    color: #fff;
                    .bold;
                }
            }

            span {
                position: absolute;
                width: 100%;
                text-align: center;
                bottom: -40px;
                left: 0;
            }

            &.zero {
                .activity {
                    height: 3px;
                    background-color: #e4e6e7;
                    .border-radius(0);
                }
            }
        }
    }
}

.chart-controls {
    text-align: center;

    .chart-view-text {
        padding: 8px 20px 0 20px;
        .inline-block;
    }

    .icon-arrowright {
        margin-left: 3px !important;
        line-height: 0;
        margin-top: -9px !important;
    }

    .icon-arrowleft {
        margin-right: 3px !important;
        line-height: 0;
        margin-top: -9px !important;
    }
}

.panel-chart {
    padding-bottom: 50px;
    margin-bottom: 80px;
}

/*-----------OTHER-----------*/

.format-select-container {
    background-color: #e2e8ee;
    padding: 12px;
    margin-bottom: 25px;

    .form-group {
        margin-bottom: 0;
    }

    .border-radius(4px);

    .btn-switch {
        width: 100%;
        padding: 75px 0 15px 0;
        border: 2px solid transparent;
        position: relative;

        &::before {
            .pseudo;
            left: 50%;
        }

        &.camera {
            &::before {
                .image('../../images/svg/camera.svg');
                width: 40px;
                height: 44px;
                margin-left: -20px;
                top: 20px;
            }
        }

        &.image {
            &::before {
                .image('../../images/svg/image.svg');
                width: 43px;
                height: 36px;
                margin-left: -22px;
                top: 24px;
            }
        }

        &.pdf {
            &::before {
                .image('../../images/svg/pdf-grey.svg');
                width: 38px;
                height: 40px;
                margin-left: -24px;
                top: 24px;
            }
        }

        &.two-images {
            &::before {
                .image('../../images/svg/two-images.svg');
                width: 93px;
                height: 36px;
                margin-left: -46px;
                top: 24px;
            }
        }

        &.active {
            background: #fff;
            border: 2px solid #d3d9de;
            .box-shadow(0, 0, 0, transparent);

            &:hover {
                border-color: #d3d9de;
            }
        }

        &:hover {
            border-color: transparent;
        }

        &:active {
            background: #fff;
            border-color: #d3d9de;
        }
    }

    #switch-actions {
        margin-bottom: 0;
    }
}

#switch-actions {
    margin-bottom: 15px;
}

.hidden-switch {
    display: none;
    border-top: 2px solid #d6dce2;
    padding-top: 10px;
    margin-top: 10px;

    .form-group, .btn {
        margin-bottom: 0;
    }
}

#video-switch {
    .btn {
        width: 100%;
    }
}

.video-upload-wrapper {
    padding: 10px 10px 0 10px;
    border: 1px solid #dbe1e5;
    .border-radius(3px);
    margin-bottom: 25px;

    table {
        margin-left: 0;
        margin-right: 0;
    }
}

.table-full {
    margin-left: 0;
    margin-right: 0;
}

.error {
    padding-top: 100px;

    h2 {
        font-size: 40px;
        text-align: center;
    }
}

.videos-upload {
    margin-top: 20px;

    .item {
        font-size: 0;
        padding: 12px 0;
        border-bottom: 1px solid #d6dce2;

        &.head {
            border-top: 2px solid #d6dce2;
            border-bottom: 2px solid #d6dce2;
            .semibold;
        }

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        .form-control {
            width: ~"-moz-calc(100% - 12px)";
            width: ~"-webkit-calc(100% - 12px)";
            width: ~"-o-calc(100% - 12px)";
            width: ~"calc(100% - 12px)";
        }

        .name, .url, .time {
            .inline-block;
            font-size: 14px;
        }

        .name {
            width: 50%;
        }

        .url {
            width: 30%;
        }

        .time {
            width: 20%;

            .btn {
                width: 40px !important;
                padding: 9px 10px 6px 10px;
                float: right;
            }

            .form-control {
                width: ~"-moz-calc(100% - 52px)";
                width: ~"-webkit-calc(100% - 52px)";
                width: ~"-o-calc(100% - 52px)";
                width: ~"calc(100% - 52px)";
                .inline-block;
            }
        }
    }
}

.default-page {
    img {
        max-width: 100%;
        height: auto !important;
    }
}

.set-price {
    .price-label {
        color: #393c40;
        .semibold;
        .inline-block;
        margin: 7px 10px 0 0;
    }

    .price {
        margin-left: 10px;
    }

    .form-control {
        width: 100px;
        .inline-block;
    }

    .fstToggleBtn {
        min-width: 100px;
    }

    .fstSingleMode .fstQueryInput {
        height: 0;
        font-size: 0;
    }

    .fstSingleMode .fstControls {
        padding: 0;
    }
}


/*-------------SETTINGS-MENU------------*/

.settings-menu {
    background-color: #fff;
    .border-radius(4px);
    .box-shadow(0, 1px, 3px, rgba(0, 0, 0, 0.1));
    overflow: hidden;

    li {
        margin: 0;
        padding: 18px 18px;
        .bold;
        text-transform: uppercase;
        border-bottom: 1px solid #e4e6e7;
        cursor: pointer;
        .transition;

        &:hover {
            background-color: #f1f1f1;
        }

        &::before {
            display: none;
        }

        &.active {
            background-color: @green;
            color: #fff;
        }
    }
}

.choose-exercise {
    .panel-eshop {
        h5 {
            font-size: 15px;
            margin: 13px 15px 9px 15px;
        }

        .image-wrapper {
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }
}

#activityComplete {
    .modal-body {
        padding: 20px;

        label {
            font-size: 14px;
        }
    }
}

/*------------------PRINT-------------------*/

.video-print {
    display: none;
}

.training-print {
    display: none;
}

/*-------------Action hint icon -----------*/
.action-hint-icon {
    border-radius: 50%;
    display: inline-block;
    z-index: 100;
    background-color: @color-green;
    width: 56px;
    height: 56px;
    position: fixed;
    left: 20px;
    bottom: 20px;

    @media (min-width: 768px) {
        width: 70px;
        height: 70px;
        left: 60px;
        bottom: 60px;
    }

    @media (min-width: 992px) {
        position: static;
        margin-left: 5px;
        width: 35px;
        height: 35px;
    }

    > span {
        display: inline-block;
    }

    svg {
        width: 100%;
        fill: white;

        .question-icon-circle {
            fill: @color-green;
        }
    }

    //trainer
    &--trainer {
        background-color: @color-blue;

        svg {

            .question-icon-circle {
                fill: @color-blue;
            }
        }
    }

}


/*-------------QUICK-ADD------------*/

.quick-add {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;

    &::before {
        .pseudo;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: -moz-linear-gradient(left, rgba(74, 74, 74, 0) 0%, rgba(0, 0, 0, 0.9) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(74, 74, 74, 0) 0%, rgba(0, 0, 0, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(74, 74, 74, 0) 0%, rgba(0, 0, 0, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#004a4a4a', endColorstr='#e6000000',GradientType=1);
        .transition(300ms);
        opacity: 0;
    }

    &.quick-add--opened {
        pointer-events: auto;

        &::before {
            opacity: 1;
        }

        .quick-add__button {
            background-color: #fff;
        }

        .quick-add__button-icon {
            fill: @green;
            transform: rotateZ(-45deg);
        }

        .quick-add__option {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

.quick-add__button {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    position: absolute;
    right: 60px;
    bottom: 50px;
    background-color: @green;
    pointer-events: auto;
    cursor: pointer;
    .transition(200ms);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);

    &:hover {
        background-color: darken(@green, 5%);
    }
}

.quick-add__button-icon {
    position: absolute;
    width: 18px;
    height: 18px;
    fill: #fff;
    left: 50%;
    top: 50%;
    margin-top: -9px;
    margin-left: -9px;
    .transition(200ms);
}

.quick-add__options {
    position: absolute;
    right: 92px;
    bottom: 160px;
    text-align: right;
    width: 160px;
}

.quick-add__option {
    .transition(200ms);
    opacity: 0;
    transform: translateX(-20px);
}

.quick-add__headline {
    font-size: 14px;
    .bold;
    color: #fff;
    position: relative;
    padding-bottom: 5px;
    margin-top: 20px;

    &::before {
        .pseudo;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background: -moz-linear-gradient(left, rgba(74, 74, 74, 0) 0%, rgba(74, 74, 74, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(74, 74, 74, 0) 0%, rgba(74, 74, 74, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(74, 74, 74, 0) 0%, rgba(74, 74, 74, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#004a4a4a', endColorstr='#4a4a4a',GradientType=1);
    }
}

.quick-add__link {
    color: #fff;
    font-size: 12px;
    .semibold;
    display: inline-block;
    padding: 4px 26px 4px 20px;
    background-color: #7f7f7f;
    border-radius: 15px;
    margin-bottom: 4px;
    .transition(200ms);
    position: relative;

    &::before {
        .pseudo;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: #fff;
        right: -8px;
        top: 2px;
        box-shadow: 1px 0 3px rgba(0, 0, 0, 0.17);
        .transition(200ms);
    }

    &::after {
        .pseudo;
        .transition(200ms);
    }

    &:hover {
        color: #fff;
        text-decoration: underline;
        background-color: darken(#7f7f7f, 10%);

        &::before, &::after {
            transform: scale(1.2);
        }
    }

    &:focus {
        color: #fff;
        text-decoration: underline;
    }
}

.quick-add__link--arrow {
    &::after {
        width: 6px;
        height: 9px;
        .image('../../images/svg/arrow-left-green.svg');
        top: 10px;
        right: 1px;
    }
}

.quick-add__link--plus {
    &::after {
        width: 9px;
        height: 9px;
        .image('../../images/svg/plus-green.svg');
        top: 10px;
        right: -1px;
    }
}

//trainer quick add
.quick-add--trainer {

    &.quick-add {
        &--opened .quick-add__button-icon {
            fill: @color-blue;
        }
    }

    .quick-add {
        &__button {
            background-color: @color-blue;
        }
    }

    a.quick-add__link {
        color: white;

        &::after {
            background-image: url(../../images/svg/plus-blue.svg);
        }
    }
}

/*-------------ACTIVITY TOOLBAR------------*/

.activity-toolbar {
    float: right;
    padding-top: 6px;
}

.activity-toolbar__label {
    font-size: 14px;
    .regular-p;
    color: #9da4ae;
    padding-right: 21px;
    margin-right: 20px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;

    &::before {
        width: 1px;
        height: 30px;
        .pseudo;
        right: 0;
        top: -5px;
        background-color: #e0e3e7;
    }
}

.activity-toolbar__activities {
    display: inline-block;
    vertical-align: top;
}

.activity-toolbar__item {
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
    color: #fff;
    padding: 2px 15px 3px 30px;
    border-radius: 15px;
    position: relative;
    text-decoration: none;
    background-color: #eeeeee;
    .transition(200ms);

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        text-align: center;
        padding-top: 2px;
        background-color: #d2d0d0;
        .transition(200ms);
        .regular;
    }

    &:hover {
        color: #fff;
    }

    & + .activity-toolbar__item {
        margin-left: 5px;
    }
}

.activity-toolbar__item--training {
    background-color: @green;

    &::before {
        background-color: #3da101;
        content: "T";
    }

    &:hover {
        background-color: darken(@green, 10%);

        &::before {
            background-color: darken(#3da101, 10%);
        }
    }
}

.activity-toolbar__item--activity {
    &::before {
        content: "A";
    }
}

.no-min-height {
    min-height: auto !important;
}

.activity-toolbar__item--red {
    background-color: @red;

    &::before {
        background-color: #c31515;
    }

    &:hover {
        background-color: darken(@red, 10%);

        &::before {
            background-color: darken(#c31515, 10%);
        }
    }
}

.activity-toolbar__item--green {
    background-color: @green;

    &::before {
        background-color: #3da101;
    }

    &:hover {
        background-color: darken(@green, 10%);

        &::before {
            background-color: darken(#3da101, 10%);
        }
    }
}

.activity-toolbar__item--diary {
    background-color: @orange;

    &::before {
        background-color: #da5a32;
        content: "D";
    }

    &:hover {
        background-color: darken(@orange, 10%);

        &::before {
            background-color: darken(#da5a32, 10%);
        }
    }
}

.activity-toolbar__no-activities {
    font-size: 12px;
    color: #e1e1e1;
}

/*-------------SPORT STATISTICS------------*/

.bottom-line {
    border-bottom: 2px solid #ebeef1;
    margin-bottom: 48px;
    padding-bottom: 8px;
}

.flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.flex-item {
    margin-bottom: 40px;
    flex-grow: 1;
}

.sport-statistics {
    display: flex;
    align-items: stretch;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sport-statistics__sport {
    background-color: @green;
    display: flex;
    width: 30%;
    align-items: center;
}

.sport-statistics__sport-headline {
    color: #fff;
    font-size: 15px;
    .bold;
    padding-top: 45px;
    position: relative;
    text-align: center;
    width: 100%;
    margin: 0;
}

.sport-statistics__sport-icon {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    position: absolute;
    font-size: 40px;
}

.sport-statistics__boxes {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    width: 70%;
}

.sport-statistics__box {
    width: 50%;
    padding: 17px 17px 6px 17px;
    border-right: 1px solid #f0f3f6;
    border-bottom: 1px solid #f0f3f6;

    &:nth-of-type(2n) {
        border-right: 0;
    }

    &:nth-of-type(3), &:nth-of-type(4) {
        border-bottom: 0;
    }
}

.sport-statistics__headline {
    font-size: 12px;
    text-transform: uppercase;
    color: #e0e3e7;
    .semibold-p;
    padding-left: 26px;
    position: relative;
    margin-bottom: 5px;
}

.sport-statistics__headline-icon {
    color: #e0e3e7;
    left: 0;
    top: -4px;
    font-size: 18px;
    position: absolute;
}

.sport-statistics__headline-icon--bigger {
    width: 22px;
    height: 22px;
    top: -8px;
}

.sport-statistics__value {
    font-size: 24px;
    .bold-p;
    color: @green;
    margin: 0;
}

.sport-statistics__unit {
    color: #93a2ad;
    font-size: 11px;
    .regular-p;
}

.sport-statistics__empty {
    height: 100%;
    display: flex;
    background-color: #e0e3e7;
    border-radius: 3px;
    padding: 20px 60px;
    align-items: center;
    justify-content: center;
    min-height: 155px;
}

.sport-statistics__empty-text {
    font-size: 16px;
    color: #3f4143;
    .italic;
    padding-left: 100px;
    position: relative;

    &::before {
        .pseudo;
        width: 84px;
        height: 84px;
        .image('../../images/svg/bicycle-circle.svg');
        left: 0;
        top: 50%;
        margin-top: -42px;
    }
}

.today-statistics {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    border-radius: 3px;
    padding: 20px;
    text-align: center;
    margin-bottom: 40px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .activity-toolbar__activities {
        width: 100%;

        .activity-toolbar__item {
            width: 100%;
            margin: 5px 0;
            text-align: left;
            padding: 10px 17px 11px 40px;
            border-radius: 30px;
            font-size: 14px;

            &::before {
                top: 8px;
                left: 9px;
                padding-top: 1px;
            }
        }

        .activity-toolbar__item--activity, .activity-toolbar__item--training {
            color: #5d5d5d;
            background: #eeeeee;

            &::before {
                background: #d2d0d0;
            }

            &:hover {
                background: #e2e2e2;
            }
        }

        .activity-toolbar__item + .activity-toolbar__item {
            margin: 5px 0;
        }

        .activity-toolbar__item--green {
            background-color: @green;
            color: #fff;

            &::before {
                background-color: #3da101;
            }

            &:hover {
                background-color: darken(@green, 10%);

                &::before {
                    background-color: darken(#3da101, 10%);
                }
            }
        }
    }

    .day {
        width: 100%;
        font-size: 20px;

        b {
            font-size: 30px;
        }
    }
}

.yearly-statistics {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.yearly-statistics__box {
    width: 50%;
    padding: 37px 30px 30px 30px;
    border-right: 1px solid #f0f3f6;
    border-bottom: 1px solid #f0f3f6;
    text-align: center;

    &:nth-of-type(2n) {
        border-right: 0;
    }

    &:nth-of-type(3), &:nth-of-type(4) {
        border-bottom: 0;
    }
}

.yearly-statistics__headline {
    font-size: 12px;
    text-transform: uppercase;
    color: #e0e3e7;
    .semibold-p;
    padding-top: 24px;
    position: relative;
    margin-bottom: 10px;
}

.yearly-statistics__headline--bigger {
    padding-top: 26px;

    .yearly-statistics__headline-icon {
        font-size: 22px;
    }
}

.yearly-statistics__headline-icon {
    font-size: 20px;
    color: #e0e3e7;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    position: absolute;
}

.yearly-statistics__value {
    font-size: 40px;
    .bold-p;
    color: @green;
    margin: 0;
}

.yearly-statistics__unit {
    color: #93a2ad;
    font-size: 13px;
    .regular-p;
}

/*kalendář - úprava pro zobrazení programů*/

.fc-wk {
    width: 50px;
}

.fc-widget-content &.fc-wk {
    content: 'Týden';
    background-color: #f6f8f9;
    text-align: center;
    vertical-align: middle !important;
    font-weight: bold;
    font-size: 40px;
    color: #dadada;
}

.fc-programs {

    .fc-content-skeleton td:first-child {
        width: 50px;
    }

    tr:first-child > td > .fc-day-grid-event, tr:first-child td .fc-day-grid-event {
        margin-top: 5px;
    }
}

/*microsite navigation for app*/
.micrositeNav {
    display: flex;
    height: 65px;
    border-bottom: 1px solid #dadada;
    background: #eeeeee;
    margin-top: -65px;

    .menu-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-left: 35px;

        @media (min-width: 992px) {
            justify-content: space-between;
        }
    }

    .nav-registration {
        margin: 0 0 0 0;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .logo {
        width: 127px;
        height: 22px;
        .image('../../images/logo.svg');
        .inline-block;
        margin: 21px 0 0 142px;
    }

    .right {
        float: right;
        margin-right: 148px;
        position: relative;

        .menu {
            margin-left: 35px;
            margin-bottom: 0;
            .inline-block;

            li {
                list-style-type: none;
                .inline-block;
                padding: 0;
                margin: 0;

                &::before {
                    display: none;
                }

                a {
                    .inline-block;
                    .regular-p;
                    color: @grey;
                    font-size: 14px;
                    padding: 22px 20px 19px 20px;
                    .transition;
                    border-bottom: 3px solid transparent;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                        border-bottom: 3px solid @grey;
                    }
                }

                & + li {
                    margin-left: 40px;
                }
            }
        }

        .dropdown {
            .inline-block;
            margin-left: 160px;
            position: relative;
        }

        .login {
            .semibold-p;
            .inline-block;
            font-size: 15px;
            color: @primary;
            padding: 23px 20px 17px 45px;
            text-decoration: none;
            border-bottom: 3px solid transparent;
            .transition;

            &:hover {
                border-color: @green;
                text-decoration: none;
            }

            &::before {
                .pseudo;
                width: 16px;
                height: 17px;
                .icon(0 0);
                left: 19px;
                top: 22px;
            }
        }

        .dropdown-menu {
            top: 65px;
            position: absolute;
            left: -70px;
            width: 290px;
            box-sizing: border-box;
            padding: 20px;
            margin-top: 0;
            background-color: @primary;
            .border-radius(0);
            border: 0;

            > li {
                padding: 0 12px;

                > a {
                    color: #b9bbbd;
                    padding: 10px 0;
                    border-top: 1px solid #4f5153;
                    border-bottom: 0;
                    text-align: center;
                    text-decoration: none;

                    &:hover, &:focus {
                        color: #fff;
                        background-color: transparent;
                    }
                }
            }

            .form-control {
                margin-bottom: 15px;
                height: 38px;
                .border-radius(3px);
                border: 1px solid #dbe1e5;
                .box-shadow (0px, 1px, 2px, rgba(155, 155, 155, 0.1));

                &:focus {
                    border-color: #dbe1e5;
                    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
                    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
                    .box-shadow (0px, 1px, 2px, rgba(155, 155, 155, 0.1));
                }
            }

            .btn-link {
                width: auto;
                padding: 9px 0;
                color: #fff;
                .regular;
                border: 0;
                font-size: 13px;
                .transition(0);

                &:focus, &:active {
                    outline: none;
                    border: 0;
                    .transition(0);
                }
            }

            .social-login {
                border-top: 1px solid #535456;
                margin-top: 23px;
                padding-top: 16px;

                .item {
                    color: #fff;
                    padding: 6px 0 6px 30px;
                    position: relative;
                    font-size: 13px;
                    line-height: 1;
                    width: auto;
                    cursor: pointer;
                    .inline-block;

                    &::before {
                        .pseudo;
                        width: 25px;
                        height: 25px;
                        top: 0;
                        left: 0;
                    }

                    &.gplus {
                        margin-right: 25px;

                        &::before {
                            .icon(-109px 0);
                        }

                        &:hover {
                            &::before {
                                .icon(-109px -26px);
                            }
                        }
                    }

                    &.fb {
                        &::before {
                            .icon(-136px 0);
                        }

                        &:hover {
                            &::before {
                                .icon(-136px -26px);
                            }
                        }
                    }
                }
            }
        }

        #hamburger {
            display: none;

            span {
                .transition;
            }
        }

        .btn-default {
            border-radius: 30px;
            border: 1px solid #fffeff;
            background-color: #fffeff; /* Old browsers */
            // background: -moz-linear-gradient(top,  #fffeff 0%, #f6f8f9 100%); /* FF3.6-15 */
            // background: -webkit-linear-gradient(top,  #fffeff 0%,#f6f8f9 100%); /* Chrome10-25,Safari5.1-6 */
            // background: linear-gradient(to bottom,  #fffeff 0%,#f6f8f9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fffeff', endColorstr='#f6f8f9',GradientType=0 ); /* IE6-9 */
            .box-shadow (0px, 1px, 2px, rgba(155, 155, 155, 0.1));
            color: #333 !important;

            &:hover {
                background-color: #f6f8f9;
                border-color: #f6f8f9;
            }

            &:active:focus, &:focus {
                border-color: #dbe1e5;
            }

            line-height: 1.42857143 !important;
            .semibold;
            font-size: 14px;
            padding: 8px 18px;
            text-decoration: none;
            .border-radius(2px);

            &:focus, &:active {
                outline: none;
            }

            &:active:focus {
                outline: none;
            }

            &:active {
                border-color: #dbe1e5;
            }

            &.icon {
                padding: 7px 14px;

                .icon {
                    margin: 0;
                    font-size: 18px;
                }
            }

            .icon {
                margin: 0 18px 0 0;
                line-height: 1;
            }

            &.dropdown-toggle {
                padding: 8px 18px 8px 22px;

                .caret {
                    margin-left: 10px;
                }
            }

            &.active {
                &:focus {
                    outline: none;
                }
            }
        }

    }

    /*
  ** header
  */

    .right .menu li:first-of-type a {
        @media only screen and (max-width: 1002px) {
            font-size: 13px;
        }
    }

    .right .menu li:first-of-type a {

        &:hover {
            border-bottom-color: @color-gray;
        }
    }

    .right .menu li:nth-of-type(2) a {
        color: @color-green;

        &:hover {
            border-bottom-color: @color-green;
        }
    }

    .right .menu li:nth-of-type(3) a {
        color: @color-blue;

        &:hover {
            border-bottom-color: @color-blue;
        }
    }

    .right .menu li + li {
        @media only screen and (max-width: 1002px) {
            margin-left: 0;
        }
    }

    .right .login {
        border: 3px solid #ccc;
        border-radius: 40px;
        padding: 8px 25px 4px 40px;
        color: #fff;
        background-color: @color-green;
        transition: .25s all;

        &:hover {
            border-color: @color-green !important;
            background-color: #ccc;
        }

        @media only screen and (max-width: 1149px) {
            font-size: 13px;
            padding: 8px 10px 4px 25px;
        }
    }

    .right .login::before {
        top: 11px !important;
        left: 19px !important;
        width: 15px !important;
        height: 16px !important;
        background-image: url(../../images/icon-login.png) !important;
        background-size: 15px 16px !important;
        background-position: center center !important;

        @media only screen and (max-width: 767px) {
            left: 10px !important;
        }
    }

    .right .dropdown {
        margin-top: 12px;
        margin-left: 20px;

        @media only screen and (max-width: 1002px) {
            margin-left: 5px;
        }
    }

    .nav-registration-only-mobile {
        display: none !important;

        @media only screen and (max-width: 767px) {
            display: block !important;
        }
    }

    .btn-toolbar > .btn:nth-of-type(2),
    .register .btn-toolbar > .btn:nth-of-type(2),
    .e-learning .btn-toolbar > .btn:nth-of-type(2) {
        margin-left: 25px;

        @media only screen and (max-width: 991px) {
            margin-left: 5px;
            margin-top: 15px;
        }
        @media only screen and (max-width: 767px) {
            margin-left: 15px;
            margin-top: 0;
        }
        @media only screen and (max-width: 414px) {
            margin-left: 5px;
            margin-top: 0;
        }
    }

    /*
  ** header END
  */

    .e-learning .btn-toolbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        margin: 0 0 70px;

        > .btn {
            margin-bottom: 20px;
        }
    }

    .e-learning-trainer {
        padding-bottom: 0;
    }

    .e-learning-trainer img {
        margin-bottom: 0;
    }

    .phone-left .phone-bg.phone-1,
    .phone-right .phone-bg.phone-2,
    .phone-left .phone-bg.phone-3,
    .phone-right .phone-bg.phone-4,
    .phone-left .phone-bg.phone-5 {
        width: 380px;
        height: 570px;
        background-position: center bottom;

        @media only screen and (max-width: 1199px) {
            width: 280px;
            height: 420px;
        }
        @media only screen and (max-width: 767px) {
            width: 220px;
            height: 330px;
        }
    }

    .btn-want-registration {
        text-align: center;
        margin: 0 0 50px;
    }

    .btn-for-trainers {
        margin: 40px 0 70px;

        > .btn {
            @media only screen and (max-width: 767px) {
                white-space: normal;
            }
        }
    }

    .container-trainer-customer {
        margin-top: 70px;

        img {
            max-width: 100%;
        }
    }

    .services .item {
        margin-bottom: 0;
    }

    .services .item.bg-14 {
        position: relative;
        background-image: url(../../images/backgrounds/bg-14.jpg);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            display: block;
            width: 100px;
            background-color: #fff;

            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
    }

    .services .check-green,
    .services .check-blue {
        background-size: 29px 23px;
        background-position: center center;
    }

    .services .check-green {
        background-image: url(../../images/check-green.png);
    }

    .services .check-blue {
        background-image: url(../../images/check-blue.png);
    }

    .services-trainer {
        margin-top: 50px;
    }

    .services-trainer .item h3 {
        font-size: 50px;
    }

    .services-trainer .item.bg-14 {
        &::before {
            display: none;
        }
    }

    .item-footer {
        min-height: 150px;
        padding: 50px 40px;
        background-color: #f1f1f1;

        h3 {
            position: relative;
            margin: 0;
            //padding: 0 0 50px;
            text-transform: none;
            font-size: 20px;
            color: #3a3a3a;

            /*&::after {
          content: '';
          position: absolute;
          top: calc(100% - 25px);
          left: 50%;
          width: 36px;
          height: 3px;
          transform: translateX(-50%);
          background-color: #dbdbdb;
      }*/
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            color: #3a3a3a;
        }
    }

    .text-black {
        color: #010101;
    }

    .privat-program-container {
        margin-bottom: 100px;
        background-color: #f1f1f1;
    }

    .privat-program-content {
        padding: 15% 15px 0;

        @media only screen and (max-width: 991px) {
            padding: 30px 0 0;
        }
    }

    .privat-program-content h3 {
        margin: 0 0 25px;
        text-align: left;
        text-transform: none;
        font-size: 20px;
        line-height: 32px;
        color: #010101;

        span {
            color: #3a3a3a;
        }
    }

    .privat-program-content p {
        margin: 0 0 25px;
        text-align: left;
        font-size: 16px;
        color: #3a3a3a;
    }

}

.nav-registration {
    display: inline-block;
    margin: 0 0 0 18vw;

    @media only screen and (max-width: 1200px) {
        margin: 0;
    }

    @media only screen and (max-width: 767px) {
        display: none;
    }

    > a {
        display: inline-block;
        padding: 22px 0 17px;
        text-decoration: none;
        font-weight: 600;
        font-size: 15px;
        color: #3a3a3a;
        border-bottom: 3px solid transparent;
        transition: .25s all;

        &:hover {
            border-bottom-color: #3a3a3a;
        }

        span {
            color: #49c100;
        }
    }
}

@media only screen and (max-width: 1700px) {
    .micrositeNav {
        .right {
            margin-right: 70px;

            .menu {
                li {
                    & + li {
                        margin-left: 5px;
                    }
                }
            }

            .dropdown {
                margin-left: 20px;
            }
        }

        .logo {
            margin-left: 50px;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .micrositeNav {
        .right {
            .menu {
                li {
                    & + li {
                        margin-left: 0px;

                        a {
                            padding: 22px 5px 19px 5px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .micrositeNav {
        .right {
            margin-right: 20px;

            .menu {
                li {
                    a {
                        padding: 22px 7px 19px 7px;
                    }
                }
            }

            .dropdown {
                margin-left: 10px;
            }

            .login {
                padding: 23px 0 17px 30px;

                &::before {
                    left: 0;
                    top: 23px;
                }
            }

            .dropdown-menu {
                left: auto;
                right: -20px;
            }
        }

        .logo {
            width: 100px;
            margin-left: 20px;
            float: left;
        }
    }
}

@media only screen and (max-width: 767px) {
    .micrositeNav {
        position: fixed;
        width: 100%;
        margin-top: 0;
        background-color: #fff;
        z-index: 999;
        top: 0;

        .right {
            .login {
                font-size: 13px;
                padding: 8px 10px 4px 25px;

                &::before {
                    left: 12px;
                }
            }

            #hamburger {
                width: 26px;
                .inline-block;
                margin: 23px 0 0 5px;

                span {
                    display: block;
                    background-color: @primary;
                    height: 3px;

                    & + span {
                        margin-top: 4px;
                    }
                }
            }

            .menu {
                position: fixed;
                width: 300px;
                right: -300px;
                top: 65px;
                height: 100%;
                background-color: @primary;
                z-index: 1;
                .transition;

                li {
                    display: block;

                    a {
                        display: block;
                        text-align: left;
                        padding: 18px 15px 15px 15px;
                        border-bottom: 1px solid #555555;
                        color: #fff;
                    }

                    & + li {
                        margin-left: 0;
                    }
                }
            }

            &.opened {
                .menu {
                    right: 0;
                }

                #hamburger {
                    span {
                        &:nth-of-type(1) {
                            .rotate(45deg);
                            margin-top: 8px;
                        }

                        &:nth-of-type(2) {
                            .rotate(-45deg);
                            margin-top: -3px;
                        }

                        &:nth-of-type(3) {
                            display: none;
                        }
                    }
                }
            }

            .dropdown-menu {
                right: -56px;
            }
        }
    }
}

.btn-search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    background-color: #4AC102;
    transition: 0.25s background-color;
    will-change: background-color;

    &:hover {
        background-color: darken(#4AC102, 10%);
    }
}

.d-none {
    display: none !important;
}

.md-d-inline-block {
    @media only screen and (min-width: 768px) {
        display: inline-block !important;
    }
}

.md-d-block {
    @media only screen and (min-width: 768px) {
        display: block !important;
    }
}

.md-d-none {
    @media only screen and (min-width: 768px) {
        display: none !important;
    }
}

.md-d-flex {
    @media only screen and (min-width: 768px) {
        display: flex !important;
    }
}

.md-flex-ai-c {
    @media only screen and (min-width: 768px) {
        align-items: center;
    }
}

.md-no-pseudo {
    @media only screen and (min-width: 768px) {
        &::before,
        &::after {
            display: none;
        }
    }
}

.md-flex-jc-sb {
    @media only screen and (min-width: 768px) {
        justify-content: space-between;
    }
}

.to-md-w-100p {
    @media only screen and (max-width: 767px) {
        width: calc(100% - 20px);
    }
}

.pull-md-left {
    @media only screen and (min-width: 768px) {
        float: left;
    }
}

.md-min-height-58 {
    @media only screen and (min-width: 768px) {
        min-height: 58px;
    }
}

.m-b-35 {
    margin-bottom: 35px !important;
}

.md-m-b-20 {
    @media only screen and (min-width: 768px) {
        margin-bottom: 20px !important;
    }
}

.md-min-w-300 {
    @media only screen and (min-width: 768px) {
        min-width: 300px;
    }
}

.fc-icon-left-single-arrow::after,
.fc-icon-right-single-arrow::after,
.icon-arrowdown::before,
.icon-arrowup::before,
.icon-arrowright::before,
.icon-arrowleft::before {
    content: "";
    top: 1px;
    display: block;
    width: 11px;
    height: 11px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 7'%3E%3Cpath d='M0 1.43C0 1.076.125.722.375.445A1.319 1.319 0 012.302.394l3.208 3.2L8.695.396a1.318 1.318 0 011.927.045 1.48 1.48 0 01-.043 2.022l-4.124 4.14a1.318 1.318 0 01-1.881.003l-4.15-4.14A1.464 1.464 0 010 1.43z' fill='%23404040' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
}

.fc-icon-left-single-arrow::after,
.icon-arrowleft::before {
    transform: rotate(90deg);
}

.fc-icon-right-single-arrow::after,
.icon-arrowright::before {
    transform: rotate(-90deg);
}

.icon-arrowdown::before {
    position: relative;
    top: 5px;

    #frm-trainingForm & {
        top: 1px;
    }
}

.icon-arrowdown.opened::before {
    #frm-trainingForm & {
        top: -6px;
    }
}

.icon-arrowup::before {
    transform: rotate(180deg);
}

.h1-question {
    position: relative;
    top: -5px;
}

.chart-controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-arrowleft::before,
    .icon-arrowright::before {
        position: relative;
        top: 3px;
    }

    .chart-view-text {
        padding-top: 0 !important;
    }
}

@media (min-width: 768px) {
    .modal-sm {
        width: 400px;
    }
}

.modal-backdrop.in {
    opacity: .7;
}

.fc-current-day {
    .fc-list-heading,
    .fc-right,
    .fc-center {
        display: none !important;
    }

    .fc-list-table td {
        border: none;
    }

    .fc-list-view {
        border-top: none;
        border-color: #e8eaed !important;
    }
}


.panel-body.adjust-element-grid-2 .image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
}

.paginator--trainer-detail {
    display: flex;

    @media (max-width: 767px) {

        .btn-group {
            display: flex;
            width: 100%;

            a {
                width: 100%;
                padding: 12px 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.btn-group {
    label {
        margin-bottom: 0;
    }
}