.reviews {
  display: flex;
  align-items: center;
  margin: -3px;
}

.reviews-title {
  font-weight: 600;
  font-size: 16px;
  color: #404040;
}

:root {
  --star-size: 18px;
  --star-color: #ddd;
  --star-background: #32B3F0;
}

.reviews-item {
  --percent: calc(var(--rating) / 10 * 100%);

  margin-top: 4px;
  display: inline-block;
  font-size: var(--star-size);
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}


/*.reviews-item {
  flex: 0 0 16px;
  width: 16px;
  max-width: 16px;
  margin: 3px;

  svg {
    width: 100%;
  }
}

.reviews-item--filled {

  svg path {
    fill: #32B3F0;
  }
}*/
