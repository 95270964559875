.try-block {
  h2 {
    margin: 0 0 20px;
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    color: #1D1D1D;

    @media only screen and (min-width: 768px) {
      margin: 0 0 30px;
      font-size: 24px;
    }
  }
}
.try-block__items {

  @media only screen and (min-width: 768px) {
    display: flex;
    margin: -10px;
  }
}
.try-block__item {
  display: block;
  position: relative;
  margin: 0 0 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media only screen and (min-width: 768px) {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin: 10px;
  }

  &::before {
    content: '';
    display: block;
    padding-bottom: 70%;
    background-color: rgba(black,.3);
    transition: 0.25s background-color;
    will-change: background-color;
  }

  &:hover::before {
    background-color: rgba(black,.2);
  }

  .btn-filled {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    white-space: nowrap;
    min-width: 252px;
  }
  &:hover .btn-filled {
    background-color: darken(@green, 10%);
  }

  .blue-layout &:hover .btn-filled {
    background-color: darken(@blue, 10%);
  }
}

.try-block__title {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;

  @media only screen and (min-width: 768px) {
    font-size: 21px;
  }
}