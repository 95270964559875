.content-box-reviews {
  margin-bottom: 20px;
}
.content-box-reviews__title {
  text-align: center;
  margin: 0 0 30px;
}

.content-box-reviews__summary {
  margin-bottom: 40px;
  @media only screen and (min-width: 1200px) {
    display: flex;
  }
}

.summary-all {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #909090;

  @media only screen and (min-width: 1200px) {
    flex: 0 0 calc(50% - 40px);
    padding-top: 20px;
    padding-bottom: 0;
    padding-right: 40px;
    margin-bottom: 0;
    margin-right: 40px;
    border-bottom: none;
    border-right: 1px solid #eee;
  }

  strong {
    font-family: "Rubik", sans-serif;
    font-size: 36px;
    font-weight: 700;
    color: #1D1D1D;
  }

  .reviews {
    margin-bottom: 5px;
    justify-content: center;
  }
}

.summary-details {
  @media only screen and (min-width: 1200px) {
    flex: 0 0 50%;
  }
}

.summary-detail {
  margin-bottom: 10px;

  @media only screen and (min-width: 1200px) {
    display: flex;
    align-items: center;
  }
}

.summary-detail__content {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #404040;

  @media only screen and (min-width: 1200px) {
    flex: 1;
    margin-left: 18px;
  }
}

.summary-detail__progress {
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 4px;
  border-radius: 3px;
  margin-right: 10px;
  background-color: #E1E1E1;

  @media only screen and (min-width: 1200px) {
    top: 2px;
    max-width: 147px;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: inline-block;
    border-radius: 3px;
    background-color: @green;
  }
}

.content-box-comment {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #eee;

  @media only screen and (min-width: 1200px) {
    display: flex;
    align-items: center;
  }

  &--first {
    border-top: 1px solid #eee;
    padding-top: 20px;
  }
  .reviews {
    justify-content: center;
  }
}

.content-box-comment__avatar {
  flex: 0 0 88px;
  width: 88px;
  height: 88px;
  margin: 0 auto 5px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media only screen and (min-width: 1200px) {
    margin: 0 25px 0 0;
  }
}

.content-box-comment__name {
  font-size: 16px;
  font-weight: 600;
  color: #404040;

  @media only screen and (min-width: 1200px) {
    margin-right: 20px;
  }
}

.content-box-comment__content p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #404040;

  @media only screen and (min-width: 1200px) {
    text-align: left;
  }
}

.content-box-comment__title {
  margin-bottom: 10px;

  @media only screen and (min-width: 1200px) {
    display: flex;
    align-items: center;
  }

  .reviews {
    @media only screen and (min-width: 1200px) {
      top: -3px;
      position: relative;
    }
  }
}

.content-box-comments-more {
  min-width: 100% !important;

  @media only screen and (min-width: 1200px) {
    margin-top: 10px;
    min-width: 1px !important;
  }
}