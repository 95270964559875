.login-page {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
  //backdrop-filter: blur(10px) brightness(60%);

  .social-button {
    margin: 0 1rem;
  }

  #snippet--flashes {
    position: absolute;
    top: 5px;
    right: 0;
    width: 100%;
    padding: 0 1rem;
    max-width: 60rem;
  }

  .flash-messages {
    flex-wrap: wrap;
  }
}
.login-wrapper {
  background-image: url("/web/images/backgrounds/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
}
.socials-login-wrapper {
  background-color: #E5E5E5;
  margin: 0 -20px;
  padding: 25px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.login-page-wrapper {
  max-width: 450px;
  width: 100%;
  margin: auto;

  @media (min-width: 576px) {
    max-width: 414px;
    padding: 40px 30px 40px;
  }
}
.login-form-wrapper {
  padding: 20px;
  padding-bottom: 0;
  background-color: #ffffff;

  @media (min-width: 576px) {
    border-radius: 10px;
  }
}
.lost-password,
.new-password {
  //
  .login-form-wrapper {
    padding-bottom: 20px;
  }
}
.login-page-logo {
  margin: 42px 0;
  text-align: center;

  img {
    width: 169px;
  }

  @media (min-width: 576px) {
    margin: 0 0 42px;
  }
}
.login-page-title {
  margin: 0 0 16px;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;
  line-height: 30px;
  text-transform: uppercase;
}
.login-page-container {
  margin: 0 0 20px;
}
.login-page-control {
  width: 100%;
  padding: 0 17px;
  border-radius: 3px;
  border: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 43px;
  background-color: #E9F0FD;

  &,
  &::placeholder {
    color: #909090;
  }
}

.login-page-container-password {
  position: relative;
  .login-page-control {
    padding-right: 40px;
  }
}
.login-page-pass {
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  cursor: pointer;
}
.login-page-submit {
  width: 100%;
  margin: 0 0 20px;
  margin-bottom: 0;
  border-radius: 28.5px;
  border: none;
  box-shadow: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 48px;
  background-color: @color-6;
}
.login-form-lost-password {
  padding: 10px 0 15px 0;

  a {
    color: @color-2;
    font-size: 14px;
  }
}
.login-page-divider {
  overflow: hidden;
  margin: 0 0 20px;
  text-align: center;
  font-size: 14px;
  color: #fff;

  span {
    position: relative;
    display: inline-block;
  }
  span::before,
  span::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    width: 70vw;
    transform: translateY(-50%);
    background-color: rgba(#fff, .5);
  }
  span::before {
    right: 100%;
    margin-right: 5px;
  }
  span::after {
    left: 100%;
    margin-left: 5px;
  }
}
.login-page-footer {
  padding: 15px 30px;
  text-align: center;
  background-color: #fff;
  margin-top: auto;

  p {
    margin: 0 0 5px;
    font-weight: 200;
    font-size: 14px;
    color: #1D1D1D;
    letter-spacing: 0;
    text-align: center;
    line-height: 26px;
  }

  p a {
    font-weight: 600;
    color: #1D1D1D;
  }
}

.login-page-social {
  margin: 0 0 20px;
  text-align: center;

  &:last-of-type {
    margin: 0 0 87px;
  }

  span[id*=not_signed] {
    float: left;
    margin-left: 10px;
  }

  #my-signin2 > div {
    width: 100% !important;
    max-width: 272px;
    margin: auto;
  }

  #appleid-signin {
    height: 48px;
    max-width: 272px;
    margin: 0 auto;
  }
}