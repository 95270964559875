.bg-01 {
  background-color: @color-6;
}

.text-color-blue {
  color: @color-7;
}

.text-color-green {
  color: @color-6;
}
