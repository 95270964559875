.container-sm {
  width: 100%;
  max-width: 800px;
}

.container-md {
  width: 100%;
  max-width: 1200px;
}

.container-lg {
  width: 100%;
  max-width: 1370px;
}

.container-xlg {
  width: 100%;
  max-width: 1550px;
}

.row-sm-gutter {
  margin-left: -10px;
  margin-right: -10px;

  > div[class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.col--lg-2 {
  @media only screen and (min-width: 1024px) {
    width: calc(100% / 6) !important;
    max-width: calc(100% / 6) !important;
    flex: 0 0 calc(100% / 6) !important;
  }
}

.col--lg-3 {
  @media only screen and (min-width: 1024px) {
    width: 25% !important;
    max-width: 25% !important;
    flex: 0 0 25% !important;
  }
}

.col--lg-4 {
  @media only screen and (min-width: 1024px) {
    width: 33.3333% !important;
    max-width: 33.3333% !important;
    flex: 0 0 33.3333% !important;
  }
}

.col--lg-6 {
  @media only screen and (min-width: 1024px) {
    width: 50% !important;
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }
}

.col--lg-8 {
  @media only screen and (min-width: 1024px) {
    width: 67.77777% !important;
    max-width: 67.77777% !important;
    flex: 0 0 67.77777% !important;
  }
}