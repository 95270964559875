
.nav-toggle {
  display: none;
}

.nav-toggle-button {
  position: relative;
  z-index: 1010;
  display: block;
  height: 30px;
  width: 30px;
  margin-left: auto;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  color: transparent;
  cursor: pointer;

  @media only screen and (min-width: 1280px) {
    display: none;
  }

  .nav-toggle:checked + & {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg id='Outlined' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cg id='Fill'%3E%3Cpolygon fill='%23fff' points='28.71 4.71 27.29 3.29 16 14.59 4.71 3.29 3.29 4.71 14.59 16 3.29 27.29 4.71 28.71 16 17.41 27.29 28.71 28.71 27.29 17.41 16 28.71 4.71'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.nav:not(.navbar-right) {
  transition: 0.25s transform;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  transform: translateX(-100%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 0 100px;
  overflow: hidden;
  background-color: @green;

  @media only screen and (min-width: 1280px) {
    position: relative;
    transform: translateX(0);
    flex-direction: row;
    align-items: center;
    height: auto;
    padding: 0;
    overflow: visible;
    background-color: transparent;
    transition: none;
    will-change: auto;
  }

  .nav-toggle:checked ~ & {
    transform: translateX(0);
  }
}

.nav__list {
  display: block;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  margin: 80px 0 0;
  padding: 0;
  list-style: none;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 0 40px;
  }

  @media only screen and (min-width: 1280px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    max-width: unset;
    width: auto;
    margin: auto;
    padding: 0;
    overflow: visible;
  }
}

.nav__item {
  padding: 0 20px;
  margin: 0;

  @media only screen and (min-width: 1280px) {
    margin: 0 30px 0 0;
    padding: 0;
  }

  &:last-of-type {
    margin: 0;
  }
}

.nav__link {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  line-height: 55px;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(#494949, .2);
  color: #fff;

  &:hover,
  &:focus {
    color: #1D1D1D;
  }

  @media only screen and (min-width: 1280px) {
    width: auto;
    text-align: center;
    font-size: 15px;
    line-height: 1.1;
    border-bottom: none;
    color: #fff;

    &:hover {
      color: #1D1D1D;
    }
  }

  .menu-container--is-scrolled &,
  .menu-container--is-open & {
    color: #fff;

    &:hover {
      color: #1D1D1D;
    }
  }

  .nav__item:first-of-type & {
    border-top: 1px solid rgba(#494949, .2);

    @media only screen and (min-width: 1280px) {
      border-top: none;
    }
  }
}

.nav__link--active {
  position: relative;

  &::after {
    @media only screen and (min-width: 1280px) {
      content: '';
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #3C8D0A;
    }
  }

  .menu-container--is-scrolled &::after,
  .menu-container--is-open &::after {
    background-color: #fff;
  }
}
