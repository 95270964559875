.menu-container {
  transition: 0.25s background-color;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: @green;
  box-shadow: 12px 12px 24px rgba(#000, 0.1);

  .container {
    @media only screen and (min-width: 1280px) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .static {
    position: static;
  }

  .none {
    display: none;
  }

  .m-b-30 {
    margin-bottom: 30px !important;
  }

  .md-inline-flex {
    @media only screen and (min-width: 768px) {
      display: inline-flex;
    }
  }
  .md-inline {
    @media only screen and (min-width: 768px) {
      display: inline;
    }
  }
}

.menu-container--is-open {

  @media only screen and (min-width: 768px) {
    padding-top: 10px;
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 15px 0;
}

.menu__logo-link {
  position: relative;
  z-index: 1001;
  display: block;
  width: 141px;

  svg {
    width: 100%;
    float: left;
  }

  .menu-container--is-scrolled & svg g,
  .menu-container--is-open & svg g {
    fill: #fff;
  }
}

.menu-locale-switcher--mobile {
  @media(min-width: 1280px) {
    display: none !important;
  }
}

.menu-locale-switcher--desktop {
  display: none !important;

  @media(min-width: 1280px) {
    display: block !important;
  }
}