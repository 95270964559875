.user-menu-form {
  position: fixed;
  display: none;
  z-index: 1000;
  top: 50px;
  left: 50%;
  width: 306px;
  transform: translateX(-50%);
  border-top: 3px solid @green;
  background-color: #fff;
  overflow: auto;

  @media only screen and (min-width: 375px) {
    top: 100px;
    width: 356px;
  }

  @media only screen and (min-width: 1280px) {
    position: absolute;
    right: -25px;
    left: auto;
    top: calc(100% + 12px);
    transform: none;
  }

  input:checked ~ & {
    display: block;
  }
}

.user-menu-form,
.c-login-user-menu-form {
  .m-b-20 {
    margin-bottom: 20px;
  }

  .content {
    h4 {
      margin: 0 0 10px;
      text-transform: uppercase;
      font-family: 'Rubik', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.3;
      color: #1D1D1D;
    }
  }

  .content--text-sm {
    p {
      margin: 0;
      font-size: 13px;
      color: #909090;

      @media only screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .form-container {
    position: relative;
    margin-bottom: 10px;
  }

  .js-toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-40%);
    cursor: pointer;
  }

  .form-control {
    transition: 0.25s border-color;
    display: block;
    width: 100%;
    padding: 0 15px;
    border: none;
    border-radius: 3px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 43px;
    color: #1d1d1d;
    background-color: rgba(#E8E7E7, 0.5);

    &::placeholder {
      font-family: 'Poppins', sans-serif;
      color: #909090;
    }
  }

  .form-submit {
    transition: 0.25s background-color;
    display: block;
    width: 100%;
    border-radius: 28px;
    border: none;
    box-shadow: none;
    line-height: 48px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    background-color: @green;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: darken(@green, 10%);
    }
  }
}

.c-login-user-menu-form {

  .form-control {
    background-color: #fff;
  }

  .social-register {
    margin: 20px 0 0;
    text-align: center;
  }

  .social-register-native-fb {
    margin-bottom: 20px;
    overflow: hidden;

    a {
      width: 100%;
      max-width: 272px;
    }
  }

  .social-register-native-google {
    > a {
      text-decoration: none;
    }
    > a > div {
      width: 100% !important;
      max-width: 272px;
      margin: auto;
    }
  }

  .js-toggle-password {
    transform: translateY(-50%);
  }
}

.user-menu-form__body {
  padding: 25px 20px;
}

.user-menu-form__footer {
  padding: 20px 20px 30px;
  text-align: center;
  background-color: #F3F3F3;

  span[id*=not_signed] {
    float: left;
    margin-left: 10px;
  }

  #my-signin2 > div {
    width: 100% !important;
    max-width: 272px;
    margin: auto;
  }
}
