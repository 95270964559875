.social-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 0.3rem;
    position: relative;
    margin: 0 6rem;
    padding: 0.15rem 1.2rem 0.2rem 0.15rem;

    @media only screen and (max-width: 430px) {
        margin: 0 1.5rem;
    }
}

.user-menu .social-button {
    margin: 0 1.7rem;
}

.registration .social-button {
    margin: 0 6rem;

    @media only screen and (max-width: 430px) {
        margin: 0 1.5rem;
    }
}

.social-button__icon {
    display: inline-block;
    padding: 0.85rem;

    svg,
    img {
        width: 2rem;
        height: auto;
        vertical-align: middle;
    }
}

.social-button__text {
    font-size: 14px;
    line-height: 40px;
    font-family: Roboto, arial, sans-serif;
    font-weight: 500;
    letter-spacing: .21px;
    display: block;
    width: 100%;
    text-align: center;
}

// Facebook social button
.social-button--fb {
    background-color: #1877f2;
    color: white !important;
}

.social-button--fb .social-button__icon {

    svg,
    img {
        width: 2.8rem;
    }
}

// Modal
.modal-dialog {

    .social-button {
        margin: 0 1.7rem !important;
    }
}

// Google social button
.social-button--google {
    background-color: #4285F4;
    color: white !important;

    &:before {
        display: none;
    }
}

.social-button-apple {
    padding: 0;
}

#appleid-signin {
    width: 100%;
    height: 5.5rem;
    cursor: pointer;
    outline: none;
    margin: 0 auto;
    background-color: #000000;

    div {
        &:focus {
            outline: none;
        }
    }
}

.social-button--google .social-button__icon {
    background-color: white;
}
