.quick-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 15px 0 30px 0;
  z-index: 1000000000;

  @media (min-width: 768px) {
    display: none;
  }
}

.quick-menu__list {
  display: flex;
  margin: 0;
}

.quick-menu__list-item {
  width: 20%;
  margin: 0;
  padding: 0;
  text-align: center;
  //
  &:before {
    display: none;
  }

  &:hover,
  &:focus {
    opacity: 0.55;
  }
}

.quick-menu__icon {
  width: 25px;
  height: 25px;
}