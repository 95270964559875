.program-header {
  position: relative;
  margin: 0 0 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.10);
    background-image: linear-gradient(180deg, rgba(0,0,0,0.39) 23%, rgba(0,0,0,0.80) 100%);
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .breadcrumbs {
    padding: 10px 0;
  }

  .breadcrumbs__item:last-of-type .breadcrumbs__link {
    color: #fff;
  }
  .breadcrumbs__item:not(:last-of-type)::after {
    background-image: url("/images/arrow-right-white.svg");
  }
}

.program-header__content {
  max-width: 665px;
  margin: 0 auto;
  padding: 10px 0 30px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 20px 0 60px;
  }

  h2 {
    margin: 0;
    text-transform: uppercase;
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    color: #fff;

    @media only screen and (min-width: 768px) {
      font-size: 24px;
    }

    &::after {
      content: '';
      display: block;
      width: 50px;
      height: 2px;
      margin: 15px auto;
      background-color: @green;
    }
  }

  h3 {
    margin: 0 0 24px;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }

  p {
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 1.625;
    color: #fff;

    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
}

.blue-layout .panel-articles .panel-body .article-preview h2::after {
  background-color: @blue;
}

.program-header__tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -5px -5px 15px;

  .btn-filled {
    margin: 5px;
  }
  .none {
    display: none;
  }
}

.program-header__more {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 700;
  transition: 0.25s opacity;
  will-change: opacity;

  &:hover {
    opacity: .7;
  }
}