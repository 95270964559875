.boxes {
  @media only screen and (min-width: 1280px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .info-box {
    margin: 0 20px;
    @media only screen and (min-width: 1280px) {
      border-top: none;
    }
  }
  .info {
    font-weight: 300;
  }
  .info a {
    text-decoration: underline !important;
    font-weight: 600;
  }
}

.boxes-trainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: unset;

  .info-box {
    width: 100%;
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;

    .info {
      width: 100%;

      a {
        font-size: 14px;
      }
    }
  }

  @media (min-width: 992px) {
    .info-box {
      width: 33.33%;

      .info {

        a {
          font-size: 16px;
        }
      }
    }
  }
}