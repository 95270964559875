/**********************
Flex
***********************/
.u-d-flex {
  display: flex !important;
}

.u-flex-dir-column {
  flex-direction: column !important;
}

/**********************
Visibility
***********************/
.visible-ib-lg {
  display: none !important;

  @media (min-width: 1200px) {
    display: inline-block !important;
  }
}

/**********************
Height, width
***********************/
.u-height-100 {
  height: 100% !important;
}

/**********************
texts
***********************/
.u-text-uppercase {
  text-transform: uppercase !important;
}

/**********************
Margins
***********************/
.u-m-x-10 {
  margin: 0 10px !important;
}

.u-m-y-10 {
  margin: 10px 0 !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.u-m-sides-6 {
  margin: 0 6rem !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .sm-desktop-m-t-15  {
    margin-top: 15px !important;
  }
  .sm-desktop-pull-none {
    float: none !important;
  }
}