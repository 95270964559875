.menu-notifications {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .dropdown-menu {
    padding: 10px 20px !important;
    box-shadow: 0 12px 24px 0 rgba(0,0,0,0.04);
    background-color: #fff !important;

    @media (min-width: 768px) {
      width: 309px !important;
    }
  }

  .dropdown-menu::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #119cdc;
  }

  .dropdown-toggle {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  &.open .dropdown-toggle,
  .dropdown-toggle:focus,
  .dropdown-toggle:hover {
    background-color: #119cdc !important;
  }

  li {

  }
}
.menu-notifications__num {
  position: absolute;
  top: 14px;
  right: 4px;
  padding: 0 8px;
  border-radius: 8.5px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 17px;
  background-color: #E0301C;

  @media (min-width: 375px) {
    right: 12px;
  }
  @media (min-width: 768px) {
    right: 18px;
  }
}
.menu-notifications__title {
  margin: 10px 0 0 !important;
  padding: 0 !important;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 30px;
  color: #1D1D1D;
}
.menu-notifications__item {
  margin: 0 0 10px !important;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}
.menu-notifications__content {
  padding-right: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  color: #404040;
  letter-spacing: 0;
  line-height: 21px;

  strong {
    font-weight: 700 !important;
  }
}
.menu-notifications__footer {
  display: flex;
  align-items: center;
  margin: -5px;
}
.menu-notifications__action {
  margin: 5px;
  transition: 0.25s transform;

  &:hover {
    transform: scale(1.1,1.1);
    background-color: transparent !important;
  }
}