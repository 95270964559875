.user-menu {
  position: relative;
  z-index: 1001;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;

  @media only screen and (min-width: 1280px) {
    margin-left: 0;
    order: 3;
  }

  .trigger-forgotten-pass-modal {
    color: #1d1d1d;
    font-weight: 300;
  }

  > a:last-of-type {
    &:after {
      display: none !important;
    }
  }
}

.user-menu__toggle {
  display: none;
}

.user-menu__label {
  transition: 0.25s color;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 600;
  font-size: 13px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: rgba(#fff, 0.7);
  }

  img {
    transition: 0.25s opacity;
  }

  &:hover img {
    @media only screen and (min-width: 1280px) {
      opacity: .7;
    }
  }

  @media only screen and (min-width: 768px) {
    min-height: 18px;
    font-size: 15px;
  }

  @media only screen and (min-width: 1280px) {
    margin-right: 40px;
  }

  &::after {
    content: '';
    position: absolute;
    right: -10px;
    top: -1px;
    width: 1px;
    height: 23px;
    background-color: rgba(#fff, 0.5);

    @media only screen and (min-width: 1280px) {
      right: -20px;

      .menu-container--user-menu-open & {
        display: none;
      }
    }
  }

  &--last {
    //
    &::after {
      @media (min-width: 768px) {
        display: none !important;
      }
    }
  }
}

label.user-menu__label {
  @media only screen and (min-width: 1280px) {
    position: relative;
    margin-right: 0;
  }

  span,
  img {
    @media only screen and (min-width: 1280px) {
      position: relative;
      z-index: 10;
    }
  }

  &::after {
    @media only screen and (min-width: 768px) {
      display: none;
    }
    @media only screen and (min-width: 1280px) {
      .menu-container--user-menu-open & {
        display: block;
        width: auto;
        height: auto;
        top: -15px;
        left: -25px;
        bottom: -15px;
        right: -25px;
        background-color: @green;
      }
    }
  }

  input:checked ~ &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#1D1D1D, 0.7);
    cursor: default;
  }
}

.user-menu__icon {
  width: 16px;
  margin-left: 5px;

  @media only screen and (min-width: 768px) {
    margin-left: 10px;
  }
}
