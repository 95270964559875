.footer-socials {
  display: flex;
  justify-content: center;
  margin: 0 0 20px;

  @media only screen and (min-width: 1024px) {
    margin: 0;
  }
}

.footer-socials__item {
  display: block;
  width: 41px;
  margin: 4px;
}

.footer-socials__icon {
  width: 100%;
  float: left;

  circle,
  path {
    transition: 0.25s fill;
    will-change: fill;
  }

  .footer-socials__item:hover & {
    circle {
      fill: @green;
    }

    path {
      fill: #fff;
    }
  }
}
