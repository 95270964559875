.content-box-start {

  h2 {
    margin: 0 0 10px;
    font-family: Rubik, sans-serif;
    font-size: 24px;
    color: #1D1D1D;

    strong {
      font-weight: 700;
    }
  }

  .btn-filled {
    min-width: 100%;
    width: 100%;
  }
}

.box-start-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  color: #707070;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  strong {
    font-weight: 700;
  }
}

.box-start-row__label {
  flex: 0 0 65%;
}

.box-start-row__value {
  text-align: right;
  flex: 0 0 35%;
  &--lg {
    font-size: 18px;
    color: #1D1D1D;

    @media only screen and (min-width: 768px) {
      font-size: 24px;
    }
  }
}

.box-start-row__question {
  display: inline-block;
  width: 28px;
  margin-left: 5px;

  img {
    width: 100%;
  }
}