.cta-block {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 400px;
  padding: 60px 0;

  @media only screen and (min-width: 768px) {
    padding: 120px 0;
  }

  h2 {
    max-width: 360px;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-family: 'Rubik', sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.333;
    color: #fff;

    @media only screen and (min-width: 768px) {
      font-size: 36px;
    }
  }
}

.cta-block__bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    background-color: rgba(black, .57);
  }
}

.cta-block__badges {
  display: flex;
  max-width: 340px;
}

.cta-block__badge {
  display: block;
  flex: 0 0 calc(50% - 10px);
  width: calc(50% - 10px);
  max-width: calc(50% - 10px);
  transition: 0.25s opacity;
  will-change: opacity;

  &:hover {
    opacity: 0.7;
  }

  &:first-of-type {
    margin-right: 10px;
  }
  &:last-of-type {
    margin-left: 10px;
  }

  img {
    width: 100%;
  }
}